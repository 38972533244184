import { Alert, Box, Button, Card, CircularProgress, Container, Divider, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import { homePageActions } from "../../store/reducer/home";
const defLogin: any = { app_key: "@34Y@#8deepmatka", gpay: '', phonepe: '', paytm: '' }

const UPIDetails = ({ isLoginLoading, bankData, isGetBankFailed, pageMessage, isGetBankSuccess, isUpdateUPIFailed, isUpdateUPISuccess }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [loginData, setLoginData] = useState<any>(defLogin)
    const [bankDataList, setBankDataList] = useState<any>({
        "bank_detail_id": "",
        "user_id": "",
        "bank_name": "",
        "branch_address": "",
        "ac_holder_name": "",
        "ac_number": "",
        "ifsc_code": "",
        "paytm_number": "",
        "google_pay_number": "",
        "phone_pay_number": "",
        "insert_date": ""
    })
    useEffect(() => {
        if (bankData.length) {
            setBankDataList(bankData[0])
        }
    }, [bankData])

    useEffect(() => {
        const data: any = {
            user_id: finalData.user_id,
            app_key: "@34Y@#8deepmatka",
        }
        dispatch(authActions.getBankDetailsReq(data))
        const pay: any = {
            menuname: 'upisetting'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    console.log(bankData)
    const handleOnChange = (event: any) => {
        setBankDataList((prev: any) => {
            return { ...prev, [event.target.name]: event.target.value }
        })
    }
    const updateUPIDetails = (event: any, reqType: number) => {
        event.stopPropagation();
        const payload: any = {
            app_key: "@34Y@#8deepmatka",
            user_id: finalData.user_id,
            upi_type: reqType,
            paytm_no: bankDataList.paytm_number,
            google_pay_no: bankDataList.google_pay_number,
            phon_pay_no: bankDataList.phone_pay_number
        }
        if (reqType === 1) {
            if (bankDataList.paytm_number.trim().length === 10) {
                setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
                dispatch(authActions.updateUPIReq(payload))
            } else {
                setErrorMessage({ status: true, message: "Please enter valid upi number", severity: 'warning', inputName: '' })
            }
        } else if (reqType === 2) {
            if (bankDataList.google_pay_number.trim().length === 10) {
                setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
                dispatch(authActions.updateUPIReq(payload))
            } else {
                setErrorMessage({ status: true, message: "Please enter valid upi number", severity: 'warning', inputName: '' })
            }
        } else if (reqType === 3) {
            if (bankDataList.phone_pay_number.trim().length === 10) {
                setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
                dispatch(authActions.updateUPIReq(payload))
            } else {
                setErrorMessage({ status: true, message: "Please enter valid upi number", severity: 'warning', inputName: '' })
            }
        }


    }


    useEffect(() => {

        if (isUpdateUPISuccess) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'success', inputName: '' })
            const data: any = {
                user_id: finalData.user_id,
                app_key: "@34Y@#8deepmatka",
            }
            dispatch(authActions.getBankDetailsReq(data))
        }

        if (isGetBankFailed) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }

        if (isUpdateUPIFailed) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
            const data: any = {
                user_id: finalData.user_id,
                app_key: "@34Y@#8deepmatka",
            }
            dispatch(authActions.getBankDetailsReq(data))
        }
        return (() => {
            dispatch(authActions.resetLoginState())
        })
    }, [isUpdateUPISuccess, isUpdateUPIFailed, isGetBankFailed])



    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    return (
        <Container>
            {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '0px' }}>
                    <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                    <Card sx={{border:'none', boxShadow: '0px 0px 2px #adadad27', display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', gap: 2, padding: '30px' }}>
                        <TextField sx={{ width: "100%",'.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' },'.MuiInputLabel-root':{color:'#1c274c'} }} label="PhonePe Number" name="phone_pay_number" variant="outlined" value={bankDataList.phone_pay_number} type="text" onChange={handleOnChange} />
                        <Button variant="contained"  sx={{width:"100%",boxShadow: '0px 0px 2px #adadad27', backgroundColor:'#1c274c', ':hover':{backgroundColor:'#1c274c'}}}  onClick={(event: any) => updateUPIDetails(event, 3)}>Update PhonePe Details</Button>
                        <Divider />
                        <TextField sx={{ width: "100%" ,'.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' },'.MuiInputLabel-root':{color:'#1c274c'}}} label="Gpay Number" name="google_pay_number" variant="outlined" value={bankDataList.google_pay_number} type="text" onChange={handleOnChange} />
                        <Button variant="contained"  sx={{width:"100%",boxShadow: '0px 0px 2px #adadad27', backgroundColor:'#1c274c', ':hover':{backgroundColor:'#1c274c'}}} onClick={(event: any) => updateUPIDetails(event, 2)}>Update Gpay Details</Button>
                        <Divider />
                        {/* <TextField sx={{ width: "100%" }} label="Paytm Number" name="paytm_number" variant="outlined" value={bankDataList.paytm_number} type="text" onChange={handleOnChange} /> */}
                        {/* <Button variant="contained" sx={{ width: "100%" }} color="primary" onClick={(event: any) => updateUPIDetails(event, 1)}>Update Paytm Details</Button> */}

                    </Card>
                </Grid>}
        </Container>
    )
}



const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    isGetBankSuccess: state.authentication.isGetBankSuccess,
    isGetBankFailed: state.authentication.isGetBankFailed,
    pageMessage: state.authentication.pageMessage,
    bankData: state.authentication.bankData,
    isUpdateUPISuccess: state.authentication.isUpdateUPISuccess,
    isUpdateUPIFailed: state.authentication.isUpdateUPIFailed,

})


export default connect(mapStateToProps)(UPIDetails);
