import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { homePageActions } from "../../store/reducer/home";
import { Card, CircularProgress, Container, Grid, Typography } from "@mui/material";


const ContactDetails = ({ contactData, isGameLoading }: any) => {
    const dispatch = useDispatch()
    useEffect(() => {
        const data: any = { "app_key": "@34Y@#8deepmatka" }
        dispatch(homePageActions.contactDataRequest(data))
        const pay: any = {
            menuname: 'contact'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    return (
        <Container>
        {isGameLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
        <Card sx={{border:'none', boxShadow: '0px 0px 2px #adadad27', padding: '30px', gap: '2' }}>
            <Grid container gap={2}>
                <Grid lg={3} sm={12} xs={12} md={3}>
                    <Typography sx={{ fontWeight: '400' }}>Mobile</Typography>
                    <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{contactData.mobile_1}</Typography>
                </Grid>
                <Grid lg={3} sm={12} xs={12} md={3}>
                    <Typography sx={{ fontWeight: '400' }}>Whatsapp no</Typography>
                    <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>
                    <a href={`https://api.whatsapp.com/send?phone='${contactData.whatsapp_no}'&text=Contact us`} title="Share on whatsapp">{contactData.whatsapp_no}</a>
                    </Typography>
                    
                </Grid>
                <Grid lg={3} sm={12} xs={12} md={3}>
                    <Typography sx={{ fontWeight: '400' }}>Email</Typography>
                    <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{contactData.email_1}</Typography>
                </Grid>
            </Grid>
        </Card>}
        </Container>)
}

const mapStateToProps = (state: any) => ({
    isGameLoading: state.homepage.isGameLoading,
    contactData: state.homepage.contactData,
})
export default connect(mapStateToProps)(ContactDetails);
