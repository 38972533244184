
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { homePageActions } from "../../store/reducer/home";
import { Autocomplete, Box, Button, Card, CircularProgress, Container, Divider, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import { gameNameList, gameNumberInputList } from "../gameplay/gameConstants";
import AlertBar from "../../components/AlertBar";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Close, Delete } from "@mui/icons-material";
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import CustomModal from "../../components/CustomModal";
import { authActions } from "../../store/reducer/auth-reducer";
import { starlineActions } from "../../store/reducer/starline";

const formFields: any = {
    date: { label: "Choose Date", field: 'date', value: '' },
    // session: { label: "Choose Session", field: 'radio', value: ['Open', 'Close'] },
    open: { label: "Digits", field: 'autocomplete', value: '' },
    points: { label: "Enter Points", field: 'text', value: '' },
}

const StarLinePlaceBid = ({
    isStarLineLoading,
    starLineData,
    starLineGameRateData,
    isStarLineGameRateFailed,
    starLineGameStatusActiveInactiveData,
    isStarLineGameStatusActiveInactiveFailed,
    isStarLineGameStatusActiveInactiveSuccess,
    isSubmitStarLineBidSuccess,
    isSubmitStarLineBidFailed,
    starLineMsg }: any) => {
    const outletdata: any = useOutletContext();
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession);
    const navigate = useNavigate();
    const newDate = dayjs();
    const pageParams = useParams<any>();
    const [open, setOpen] = useState(false);
    const { gamename } = useParams<any>();
    const [date_from, setDateFrom] = useState<Dayjs | null>(dayjs(new Date()));
    const [statusData, setStatusData] = useState<any>({ open_time: '', close_time: '' })
    const [routeData, setRouteData] = useState<any>({})
    const [payloadData, setPayload] = useState<any>({})
    const [gameRates, setGameRates] = useState<any>({
        "single_digit_val_1": "",
        "single_digit_val_2": "",
        "jodi_digit_val_1": "",
        "jodi_digit_val_2": "",
        "single_pana_val_1": "",
        "single_pana_val_2": "",
        "double_pana_val_1": "",
        "double_pana_val_2": "",
        "tripple_pana_val_1": "",
        "tripple_pana_val_2": "",
        "half_sangam_val_1": "",
        "half_sangam_val_2": "",
        "full_sangam_val_1": "",
        "full_sangam_val_2": "",
    })
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [formData, setFormData] = useState<any>({
        // session: '',
        open: '',
        points: ''
    })

    const [resultData, setResultData] = useState<any>([])
    const dispatch = useDispatch();

    useEffect(() => {
        const _gamename: any = gamename;
        const name: any = gameNameList[_gamename]
        dispatch(starlineActions.setMenuHeaderName(name));
        const pay: any = {
            menuname: 'starlineplacebid'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [gamename])


    useEffect(() => {
        const gamePayload: any = {
            app_key: "@34Y@#8deepmatka"
        };
        dispatch(starlineActions.starLineDataReq(gamePayload))

        const data: any = {
            app_key: "@34Y@#8deepmatka",
            game_id: pageParams.game_id
        };
        dispatch(starlineActions.starLineGameRatesReq(data))
        dispatch(starlineActions.starLineGameStatusActiveInactiveReq(data))
        setRouteData(pageParams)
    }, [pageParams])

    useEffect(() => {
        if (starLineGameStatusActiveInactiveData) {
            setStatusData(starLineGameStatusActiveInactiveData);
        }
    }, [starLineGameStatusActiveInactiveData])


    useEffect(() => {
        if (starLineGameRateData) {
            if (Object.keys(starLineGameRateData).length > 0) {
                setGameRates(starLineGameRateData?.game_rates[0])
            }

        }
    }, [starLineGameRateData])

    const handleChange = (event: any, name: any) => {
        setFormData((prev: any) => {
            return { ...prev, [name]: event.target.value }
        })
    }

    useEffect(() => {

        const keys = Object.keys(gameRates);
        if (keys.length > 0) {
            console.log(keys, gameNameList[routeData.gamename])
            let temp: any = [];
            console.log(gameNameList[routeData.gamename])
            if (gameNameList[routeData.gamename]) {
                keys.forEach((item: any) => {
                    if (item.search(gameNameList[routeData.gamename].replace(' ', '_').toLowerCase()) != -1) {
                        temp.push(gameRates[item])
                    }
                })
            }
        }
    }, [starLineGameRateData, gameRates])

    const submitBid = () => {
        // if (formData.session != '') {
        if (inputValue != '') {
            if (formData.points >= outletdata['walletBalance']['min_bid_amount']) {
                if (parseInt(formData.points) > parseInt(outletdata['walletBalance']['wallet_amt'])) {
                    setErrorMessage({ status: true, message: `You do not have sufficient balance.`, severity: "error", inputName: '' })
                } else {
                    const _new_res: any = {
                        points: formData.points,
                        session: 'Open',
                        digits: inputValue,
                        closedigits: '',
                    }

                    if (resultData.length) {
                        const _res: any = [...resultData, _new_res]
                        setResultData(_res)
                    } else {
                        setResultData([{
                            points: formData.points,
                            session: 'Open',
                            digits: inputValue,
                            closedigits: '',
                        }])
                    }
                }
            } else {
                setErrorMessage({ status: true, message: `Minimum bid point is ${outletdata['walletBalance']['min_bid_amount']}`, severity: "error", inputName: '' })
            }
        } else {
            setErrorMessage({ status: true, message: `Please enter valid digits.`, severity: "error", inputName: '' })
        }
        // } else {
        //     setErrorMessage({ status: true, message: `Please select session.`, severity: "error", inputName: '' })
        // }
    }

    console.log('inputValue', resultData)

    useEffect(() => {
        setFormData({
            open: '',
            points: ''
        })

    }, [resultData])

    useEffect(() => {
        if (formData.points === '') {
            setInputValue('')
        }
    }, [formData])

    const submitGame = () => {
        const _homepagedata = starLineData.result.filter((item: any) => item.game_id == routeData.game_id)[0];
        const payload: any = {
            app_key: "@34Y@#8deepmatka",
            new_result: {
                gameid: routeData.game_id,
                user_id: finalData.user_id,
                Gamename: _homepagedata.game_name,
                pana: gameNameList[routeData.gamename],
                totalbit: resultData.reduce((n: any, { points }: any) => parseInt(n) + parseInt(points), 0),
                session: 'Open',
                result: resultData,
                bid_date: date_from
            }
        }
        setPayload({
            gameid: routeData.game_id,
            user_id: finalData.user_id,
            Gamename: _homepagedata.game_name,
            pana: gameNameList[routeData.gamename],
            totalbit: resultData.reduce((n: any, { points }: any) => parseInt(n) + parseInt(points), 0),
            session: 'Open',
            bid_date: date_from
        })
        handleModalOpen();
    }

    const submitGameFinal = () => {
        const _homepagedata = starLineData.result.filter((item: any) => item.game_id == routeData.game_id)[0];
        const payload: any = {
            app_key: "@34Y@#8deepmatka",
            new_result: {
                gameid: routeData.game_id,
                user_id: finalData.user_id,
                Gamename: _homepagedata.game_name,
                pana: gameNameList[routeData.gamename],
                totalbit: resultData.reduce((n: any, { points }: any) => parseInt(n) + parseInt(points), 0),
                session: 'Open',
                result: resultData,
                bid_date: date_from
            }
        }

        dispatch(starlineActions.submitStarLineBidReq(payload))
        setResultData([]);
        handleModalClose();
    }

    useEffect(() => {
        if (isSubmitStarLineBidSuccess) {
            setPayload({})
            setErrorMessage({ status: true, message: `${starLineMsg} redirecting to homepage.`, severity: "success", inputName: '' })
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": finalData.user_id }
            dispatch(authActions.getWalletBalanceReq(data))
            setTimeout(() => {
                navigate('/starline')
            }, 2000);
        }

        if (isSubmitStarLineBidFailed) {
            setPayload({})
            setErrorMessage({ status: true, message: starLineMsg, severity: "error", inputName: '' })
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": finalData.user_id }
            dispatch(authActions.getWalletBalanceReq(data))
        }

        return (() => {
            dispatch(starlineActions.resetStarLIne())
        })
    }, [isSubmitStarLineBidSuccess, isSubmitStarLineBidFailed])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    const removeBid = (i: any) => {
        setResultData(resultData.filter((item: any, index: number) => {
            return i != index
        }))
    }

    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);

    function checkTime(opentime: any, closetime: any, req: any) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todayDate = mm + '/' + dd + '/' + yyyy;
        const startTimeHours = new Date(todayDate + ' ' + opentime).getHours();
        const startTimeMin = new Date(todayDate + ' ' + opentime).getMinutes()
        const endTimeHours = new Date(todayDate + ' ' + closetime).getHours();
        const endTimeMin = new Date(todayDate + ' ' + closetime).getMinutes()
        var currentD = today;
        var startHappyHourD = new Date();
        startHappyHourD.setHours(startTimeHours, startTimeMin, 0); // 10:00 pm
        var endHappyHourD = new Date();
        endHappyHourD.setHours(endTimeHours, endTimeMin, 0); // 10.00 pm

        if (req === 'open') {
            if (currentD >= startHappyHourD) {
                return true
            } else {
                return false
            }
        } else if (req === 'close') {
            if (currentD >= endHappyHourD) {
                return true
            } else {
                return false
            }
        }
    }
    const gameidname: any = gamename;
    return (
        <Container >
            {isStarLineLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                <Grid container gap={2}>
                    <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                    <Grid lg={6} md={6} sm={12} xs={12} sx={{ marginBottom: '50px' }} >
                        <Card sx={{
                            padding: '32px', gap: '2', boxShadow: '0px 0px 2px #adadad27',
                            border: '0px solid transparent'
                        }}>
                            {Object.keys(formFields).map((item: any, index: number) => (
                                <FormControl sx={{ display: 'flex', color: '#1c274c' }}>
                                    <FormLabel sx={{ color: '#1c274c', marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>{formFields[item]['label']}</FormLabel>
                                    {(formFields[item]['field'] === 'text') ? <TextField sx={{
                                        '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }, width: "100%"
                                    }}
                                        name={item} variant="outlined" value={formData[item]} onChange={(event: any) => handleChange(event, item)} /> :
                                        (formFields[item]['field'] === 'date') ? <MobileDatePicker sx={{ '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }, width: '100%' }} name="date_from" disabled={true} value={date_from} /> : (formFields[item]['field'] === 'autocomplete') ? <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={gameNumberInputList[gameidname]}
                                            sx={{ width: '100%', '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }, }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}

                                            renderInput={(params) => <TextField {...params} InputProps={{
                                                ...params.InputProps,
                                                // type: 'search',
                                            }} />}
                                            // disableClearable
                                            inputValue={inputValue}
                                        // onClose={()=>{setInputValue('');}}

                                        /> :
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                {formFields[item]['value'].map((keys: any) => (
                                                    <FormControlLabel sx={{color: '#1c274c',}} disabled={(checkTime(statusData.open_time, statusData.close_time, 'open') && keys === 'Open')} value={keys} control={<Radio checked={formData[item] === keys} onChange={(event: any) => handleChange(event, item)} />} label={keys} />))
                                                }

                                            </RadioGroup>
                                    }
                                </FormControl>
                            ))}
                            <Button variant="contained" sx={{ marginTop: '15px', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c', } }} onClick={submitBid}>Add Bid</Button>
                            {resultData.length ?
                                <>
                                    <List sx={{ width: '100%' }}>
                                        {resultData.map((item: any, index: number) => (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                                        <ListItemText sx={{ alignItems: 'center' }}>
                                                            <Grid container>
                                                                <Grid lg={10} md={10} xs={10} sm={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', color: '#1c274c', justifyContent: 'flex-start', alignItems: 'center' }}>

                                                                        <Typography>Points : {item.points}</Typography>
                                                                        <div style={{ height: '18px', width: '1px', backgroundColor: '#f1f5ff', marginLeft: '10px', marginRight: '10px' }}></div>
                                                                        <Typography>Digit : {(item.session === 'Open') ? item.digits : item.closedigits}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid lg={2} md={2} xs={2} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><IconButton>
                                                                    <Close sx={{ color: '#1c274c' }} onClick={() => removeBid(index)} />
                                                                </IconButton></Grid>
                                                            </Grid>
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider sx={{ borderColor: '#f1f5ff' }} />
                                            </>
                                        ))}
                                    </List>
                                    <Button variant="contained" sx={{ marginTop: '15px', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c', } }} onClick={submitGame}>Play Game</Button>

                                </> : ''
                            }
                        </Card>
                    </Grid>
                    <CustomModal open={open} handleClose={handleModalClose} customstyle={{ width: {xs:'300px', lg:'400px', sm:'300px', md:'350px'}, backgroundColor: '#FFF', padding: '16px' }}>
                        <Box>
                            <List sx={{ width: '100%' }}>

                                {/* <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText>
                                    <Grid container>
                                        <Grid lg={8}>
                                            <Typography>Session</Typography>
                                        </Grid>
                                        <Grid lg={4}>
                                            <Typography>{payloadData['session']}</Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <Divider /> */}
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                        <ListItemText>
                                            <Grid container>
                                                <Grid lg={12} display={'flex'}>
                                                    <Typography>Total Points : </Typography>                                                
                                                    <Typography sx={{marginLeft: '10px', fontWeight:600}}>{payloadData['totalbit']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                        <ListItemText>
                                            <Grid container>
                                                <Grid lg={12} display={'flex'}>
                                                    <Typography>Wallet Balance : </Typography>                                               
                                                    <Typography sx={{marginLeft: '10px', fontWeight:600}}>{outletdata['walletBalance']['wallet_amt']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                        <ListItemText>
                                            <Grid container>
                                                <Grid lg={12} display={'flex'}>
                                                    <Typography >Remaining Balance (After bid) : </Typography>                                               
                                                    <Typography sx={{marginLeft: '10px', fontWeight:600}}>{parseInt(outletdata['walletBalance']['wallet_amt']) - parseInt(payloadData['totalbit'])}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>

                            </List>
                            <Button variant="contained" sx={{ marginTop: '15px', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c', } }} onClick={submitGameFinal}>Submit Game</Button>
                            <Button variant="contained" color='warning' sx={{ marginTop: '15px', marginLeft: '10px' }} onClick={handleModalClose}>Cancel</Button>
                        </Box>
                    </CustomModal>
                </Grid>
            }
        </Container>
    )
}

const mapStateToProps = (state: any) => ({
    isStarLineLoading: state.starline.isStarLineLoading,
    starLineData: state.starline.starLineData,
    starLineMsg: state.starline.starLineMsg,
    starLineGameRateData: state.starline.starLineGameRateData,
    isStarLineGameRateFailed: state.starline.isStarLineGameRateFailed,
    starLineGameStatusActiveInactiveData: state.starline.starLineGameStatusActiveInactiveData,
    isStarLineGameStatusActiveInactiveFailed: state.starline.isStarLineGameStatusActiveInactiveFailed,
    isStarLineGameStatusActiveInactiveSuccess: state.starline.isStarLineGameStatusActiveInactiveSuccess,
    isSubmitStarLineBidSuccess: state.starline.isSubmitStarLineBidSuccess,
    isSubmitStarLineBidFailed: state.starline.isSubmitStarLineBidFailed,
})


export default connect(mapStateToProps)(StarLinePlaceBid);