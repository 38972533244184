import { createSlice } from "@reduxjs/toolkit";

const initalState: any = {
    userData: [],
    isPageLoading: false,
    isLoginLoading: false,
    isAuthenticated: false,
    isLoginSuccess: false,
    isLoginFailed: false,
    isRegisterSuccess: false,
    isRegisterFailed: false,
    pageMessage: '',
    isUserDataFailed: false,
    walletData: {},
    isWalletFailed: false,
    isChangePassSuccess: false,
    isChangePassFailed: false,
    isUpdateUPISuccess: false,
    isUpdateUPIFailed: false,
    isUpdateBankSuccess: false,
    isUpdateBankFailed: false,
    bankData: [],
    isGetBankSuccess: false,
    isGetBankFailed: false,
    isAddPopintsSuccess: false,
    isAddPopintsFailed: false,
    walletBalance: {},
    wdData: {},
    withdrawFailed: false,
    withdrawSuccess: false,
    isUpdateProfileSuccess: false,
    isUpdateProfileFailed: false,
    transferData: {},
    isTransferDataFailed: false,
    isTransferReqDataSuccess: false,
    isTransferReqDataFailed: false,
    orderListData: [],
    isOrderSuccess: false,
    isOrderFailed: false,
    orderStatusResponse: {},
    isOrderListLoading: false,
    adminPGSetupSuccesss: false,
    adminPGSetupFailed: false,
    adminPaymentData: {},
    userPaymentUploadSuccess: false,
    userPaymentUploadFailed: false,
    adminApprovalSuccess: false,
    adminApprovalFailed: false,
    adminApprovalData:[]
}

const authReducer = createSlice({
    name: 'authentication',
    initialState: initalState,
    reducers: {
        checkLogin: (state) => {
            state.isLoginLoading = true;
            state.isPageLoading = true;
        },
        checkLoginResponse: (state, action: any) => {
            state.isLoginLoading = false;
            state.isPageLoading = false;
            state.isLoginSuccess = true;
            state.isLoginFailed = false;
            state.userData = action.payload.res;
            state.isAuthenticated = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Logged in successfully.'
        },
        checkLoginFailed: (state, action: any) => {
            state.isLoginFailed = true;
            state.isLoginLoading = false;
            state.isPageLoading = false;
            console.log('action.payload.res.msg', action.payload.res.msg)
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to login, please try again.'
        },
        checkRegister: (state) => {
            state.isLoginLoading = true;
            state.isPageLoading = true;
            state.isRegisterSuccess = false;
        },
        checkRegisterResponse: (state, action: any) => {
            state.isLoginLoading = false;
            state.isPageLoading = false;
            state.isRegisterSuccess = true;
            state.isRegisterFailed = false;
            state.isAuthenticated = true;
            state.userData = action.payload.res;
            state.pageMessage = 'Registered successfully, now you can login!'
        },
        checkRegisterFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isPageLoading = false;
            state.isRegisterSuccess = false;
            state.isRegisterFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to register, please try again.'
        },
        getUserDataReq: (state) => {
            state.isLoginLoading = true;
        },
        getUserDataRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.userData = action.payload.res;
            state.isUserDataFailed = false;
        },
        getUserDataFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isUserDataFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to get data, please try again.'
        },
        getWalletTransactionReq: (state) => {
            state.isLoginLoading = true;
        },
        getWalletTransactionRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.walletData = action.payload.res;
            state.isWalletFailed = false;
        },
        getWalletTransactionFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isWalletFailed = true;
            state.walletData = {};
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to get data, please try again.'
        },
        changePasswordReq: (state) => {
            state.isLoginLoading = true;
        },
        changePasswordRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isChangePassSuccess = true;
            state.isChangePassFailed = false;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Password changed successfully.'
        },
        changePasswordFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isChangePassSuccess = false;
            state.isChangePassFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to change, please try again.'
        },
        updateUPIReq: (state) => {
            state.isLoginLoading = true;
        },
        updateUPIRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isUpdateUPISuccess = true;
            state.isUpdateUPIFailed = false;
            console.log('action.payload.res.msg', action.payload.res.msg)
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'UPI details update successfully.'
        },
        updateUPIFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isUpdateUPISuccess = false;
            state.isUpdateUPIFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to update, please try again.'
        },
        updateBankReq: (state) => {
            state.isLoginLoading = true;
        },
        updateBankRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isUpdateBankSuccess = true;
            state.isUpdateBankFailed = false;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Bank details udpated successfully.'
        },
        updateBankFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isUpdateBankSuccess = false;
            state.isUpdateBankFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to update, please try again.'
        },
        getBankDetailsReq: (state) => {
            state.isLoginLoading = true;
        },
        getBankDetailsRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isGetBankSuccess = true;
            state.isGetBankFailed = false;
            state.bankData = action.payload.res.payment_details;
            // state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Password changed successfully.'
        },
        getBankDetailsFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isGetBankSuccess = false;
            state.isGetBankFailed = true;
            state.bankData = []
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to change, please try again.'
        },
        addPointsReq: (state) => {
            state.isLoginLoading = true;
        },
        addPointsRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isAddPopintsSuccess = true;
            state.isAddPopintsFailed = false;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Points added successfully.'
        },
        addPointsFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isAddPopintsSuccess = false;
            state.isAddPopintsFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to add points, please try again.'
        },
        getWalletBalanceReq: (state) => {
            state.isLoginLoading = true;
        },
        getWalletBalanceRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.walletBalance = action.payload.res;
        },
        getWalletBalanceFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.walletBalance = {}
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to add points, please try again.'
        },
        getWDReq: (state) => {
            state.isLoginLoading = true;
        },
        getWDRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.wdData = action.payload.res;
        },
        getWDFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.wdData = {}
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to add points, please try again.'
        },
        withdrawReq: (state) => {
            state.isLoginLoading = true;
        },
        withdrawRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.withdrawSuccess = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Withdraw request successfully submitted.'
        },
        withdrawFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.withdrawFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to add points, please try again.'
        },
        updateProfileReq: (state) => {
            state.isLoginLoading = true;
        },
        updateProfileRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isUpdateProfileSuccess = true;
            state.isUpdateProfileFailed = false;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'UPI details update successfully.'
        },
        updateProfileFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isUpdateProfileSuccess = false;
            state.isUpdateProfileFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to update, please try again.'
        },
        getTransferDetailsReq: (state) => {
            console.log('called')
            state.isLoginLoading = false;
        },
        getTransferDetailsRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.transferData = action.payload.res
        },
        getTransferDetailsFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.transferData = {}
            state.isTransferDataFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
        },
        transferDataReq: (state, action: any) => {
            state.isLoginLoading = false;
        },
        transferDataRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isTransferReqDataSuccess = true
        },
        transferDataFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isTransferReqDataSuccess = false;
            state.isTransferReqDataFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to transfer points, please try again.'
        },
        resetUserData: (state) => {
            state.userData = [];
            state.wdData = {};
            state.transferData = {};
            state.bankData = {};
            state.walletBalance = {};
            state.isAuthenticated = false;
        },
        getOrderListReq: (state) => {
            state.isOrderListLoading = true;
        },
        getOrderListRes: (state, action: any) => {
            state.isOrderListLoading = false;
            console.log('action.payload.res ', action.payload.res)
            state.orderListData = action.payload.res.res;
        },
        getOrderListFailed: (state, action: any) => {
            state.isOrderListLoading = false;
            state.orderListData = {}
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
        },

        checkOrderStatusReq: (state) => {
            state.isLoginLoading = false;
        },
        checkOrderStatusRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.isOrderSuccess = true;
            console.log(action.payload.res, "status data");
            state.orderStatusResponse = action.payload.res;
        },
        checkOrderStatusFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.isOrderSuccess = false;
            state.isOrderFailed = true;
            state.orderStatusResponse = {};
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
        },


        adminManualPayInfReq: (state) => {
            state.isLoginLoading = true;
        },
        adminManualPayInfRes: (state, action: any) => {
            state.isLoginLoading = false;
            if (action.payload.res.reqtype === 'add') {
                state.adminPGSetupSuccesss = true;
                state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
            } else {
                state.adminPaymentData = action.payload.res.res;
                state.pageMessage = '';
            }
        },
        adminManualPayInfFailed: (state, action: any) => {
            state.isLoginLoading = false;
            if (action.payload.res.reqtype === 'add') {
                state.adminPGSetupSuccesss = false;
                state.adminPGSetupFailed = true;
                state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
            } else {
                state.adminPaymentData = action.payload.res.res;
                state.pageMessage = '';
            }
        },

        adminApprovePointsReq: (state) => {
            state.isLoginLoading = true;
        },
        adminApprovePointsRes: (state, action: any) => {
            state.isLoginLoading = false;
            if (action.payload.res.reqtype === 'approve') {
            state.adminApprovalSuccess = true;
            state.adminApprovalFailed = false;
            }else {
                state.adminApprovalData = action.payload.res.res;
            }
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
        },
        adminApprovePointsFailed: (state, action: any) => {
            state.isLoginLoading = false;
            if (action.payload.res.reqtype === 'approve') {
            state.adminApprovalSuccess = false;
            state.adminApprovalFailed = true;
            }else {
                state.adminApprovalData = [];
            }
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
        },

        userPaymentReq: (state) => {
            state.isLoginLoading = true;
        },
        userPaymentRes: (state, action: any) => {
            state.isLoginLoading = false;
            state.userPaymentUploadSuccess = true;
            state.userPaymentUploadFailed = false;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
        },
        userPaymentFailed: (state, action: any) => {
            state.isLoginLoading = false;
            state.userPaymentUploadSuccess = false;
            state.userPaymentUploadFailed = true;
            state.pageMessage = (action.payload.res.msg) ? action.payload.res.msg : 'Not able to fetch the data, please try again.'
        },

        resetLoginState: (state) => {
            state.isLoginLoading = false;
            state.isPageLoading = false;
            state.isRegisterSuccess = false;
            state.isRegisterFailed = false;
            state.isLoginFailed = false;
            state.pageMessage = '';
            state.isUserDataFailed = false;
            state.isChangePassSuccess = false;
            state.isChangePassFailed = false;
            state.isUpdateUPISuccess = false;
            state.isUpdateUPIFailed = false;
            state.isUpdateBankSuccess = false;
            state.isUpdateBankFailed = false;
            state.isGetBankSuccess = false;
            state.isGetBankFailed = false;
            state.isUpdateProfileSuccess = false;
            state.isUpdateProfileFailed = false;
            state.isTransferDataFailed = false;
            state.isTransferReqDataSuccess = false;
            state.isTransferReqDataFailed = false;

        }
    }
})

export const authActions = authReducer.actions;
export default authReducer;