import { Alert, Box, Button, Card, CircularProgress, Container, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import { get, isEmpty } from "lodash";
import { homePageActions } from "../../store/reducer/home";
const defLogin: any = { app_key: "@34Y@#8deepmatka" }

const TransferPoints = ({
    isLoginLoading,
    transferDataApi,
    isTransferDataFailed,
    isTransferReqDataSuccess,
    isTransferReqDataFailed,
    pageMessage
}: any) => {
    const outletData: any = useOutletContext();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [transferData, setTransferData] = useState<any>({ mobile_no: '', amount: '' })
    const [showMobileMsg, setShowMobileMsg] = useState<any>({show:false,msg:''});
    const dispatch = useDispatch();
    
    useEffect(() => {
        setShowMobileMsg({show:false,msg:''})
        const pay: any = {
            menuname: 'transferpoints'
        }
        dispatch(homePageActions.selectedMenu(pay))
        return(()=>{
            dispatch(authActions.resetLoginState())
        })
    }, [])
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };


    const handleOnChange = (e: any) => {
        setTransferData((prev: any) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    const handleOnMobileChange = (e: any) => {
        setTransferData((prev: any) => {
            return { ...prev, [e.target.name]: e.target.value }
        })


        if (e.target.value.trim().length == 10) {
            const data: any = get(outletData, 'userData.profile', [])
            if(data[0]['mobile'] === e.target.value){
                setShowMobileMsg({show:true,msg:'You can not use your number'})
            } else {
                const payload: any = {
                    app_key: "@34Y@#8deepmatka",
                    mobile_no: e.target.value
                }
                dispatch(authActions.getTransferDetailsReq(payload))
            }
           
        } else {
            setShowMobileMsg({show:false,msg:''})
        }

    }

    useEffect(() => {
        console.log('transferDataApi', transferDataApi)
        if (transferDataApi) {
            if (!isEmpty(transferDataApi)) {
                if (transferDataApi.status) {
                    setShowMobileMsg({show:false,msg:''})
                } else {
                    setShowMobileMsg({show:true,msg:'Account is not available'})
                }

            } else {
                setShowMobileMsg({show:false,msg:''})
            }
        } else {
            setShowMobileMsg({show:false,msg:''})
        }
    }, [transferDataApi])

    const handleTransfer = () => {
        if (transferData.mobile_no.trim().length < 10) {
            setErrorMessage({ status: true, message: "Invalid mobile number", severity: "error", inputName: '' })
        } else if (transferData.amount.trim().length === 0) {
            setErrorMessage({ status: true, message: "Invalid Points", severity: "error", inputName: '' })
        } else {
            const data: any = get(outletData, 'userData.profile', [])
            const payload: any = {
                app_key: "@34Y@#8deepmatka",
                user_id: data[0]['user_id'],
                mobile_no: transferData.mobile_no,
                amount: transferData.amount
            }
            dispatch(authActions.transferDataReq(payload))
            setTransferData({ mobile_no: '', amount: '' })
        }
    }

    useEffect(() => {
        if (isTransferReqDataSuccess) {
            setErrorMessage({ status: true, message: pageMessage, severity: "success", inputName: '' })
        }

        if (isTransferReqDataFailed) {
            setErrorMessage({ status: true, message: pageMessage, severity: "error", inputName: '' })
        }

        return (() => {
            dispatch(authActions.resetLoginState())
        })
    }, [isTransferReqDataSuccess, isTransferReqDataFailed])

    return (
    <Container>
        {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
        <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
           
                <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', gap: 2, padding: '30px', border:'none', boxShadow: '0px 0px 2px #adadad27', }}>
                  
                    <TextField sx={{ width: "100%", '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }} label="Mobile Number" name="mobile_no" variant="outlined" value={transferData.mobile_no} type="text" helperText={(!showMobileMsg.show) ? '' : showMobileMsg.msg} onChange={handleOnMobileChange} />
                    <TextField sx={{ width: "100%", '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }} label="Amount" name="amount" variant="outlined" value={transferData.amount} type="amount" error={(errorMessage.inputName === 'amount') ? errorMessage.status ? errorMessage.message : '' : ''} helperText={(errorMessage.inputName === 'amount') ? errorMessage.status ? errorMessage.message : '' : ''} onChange={handleOnChange} />
                    <Button variant="contained" sx={{ width: "100%" }} color="primary" onClick={handleTransfer} disabled={showMobileMsg.show}>Transfer</Button>

                </Card>
          
        </Grid>}
    </Container>)
}

const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    transferDataApi: state.authentication.transferData,
    isTransferDataFailed: state.authentication.isTransferDataFailed,
    isTransferReqDataSuccess: state.authentication.isTransferReqDataSuccess,
    isTransferReqDataFailed: state.authentication.isTransferReqDataFailed,
    pageMessage: state.authentication.pageMessage,
})
export default connect(mapStateToProps)(TransferPoints);

