import { all } from "redux-saga/effects";
import authSaga from "./auth-saga";
import homeSaga from "./home";
import reportsSaga from "./reports";
import starlineSaga from "./starline";


export default function* rootSaga(){
    yield all([
        authSaga(),
        homeSaga(),
        reportsSaga(),
        starlineSaga()
    ])
}