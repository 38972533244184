import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { authActions } from "../store/reducer/auth-reducer";
import { isEmpty } from "lodash";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import AlertBar from "../components/AlertBar";


const PayStatus = ({ isLoginLoading, pageMessage, isAddPopintsSuccess, isAddPopintsFailed }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [pgStatus, setPgStatus] = useState<any>(false)
    const dispatch = useDispatch();
    const pathname = useLocation();
    const navigate = useNavigate();
    console.log(pathname)
    useEffect(() => {
        if (!isEmpty(pathname)) {

            if (pathname.pathname) {
                if (pathname.pathname.replace('/', '') === 'success') {
                    setPgStatus(true);
                    const userPGData: any = localStorage.getItem('pgdata');
                    const finaUserPgData = JSON.parse(userPGData);
                    if (!isEmpty(finaUserPgData)) {
                        const payload: any = {
                            app_key: "@34Y@#8deepmatka",
                            user_id: finaUserPgData.user_id,
                            amount: finaUserPgData.amount,
                            txn_id: finaUserPgData.txn_id,
                            txn_ref: finaUserPgData.txn_ref
                        }
                        dispatch(authActions.addPointsReq(payload));
                    } else {
                        localStorage.removeItem('pgdata')
                        setPgStatus(false);
                        setErrorMessage({ status: true, message: 'Requested payment is failed', severity: 'warning', inputName: '' })
                    }
                } else {
                    localStorage.removeItem('pgdata')
                    setPgStatus(false);
                    setErrorMessage({ status: true, message: 'Requested payment is failed', severity: 'warning', inputName: '' })
                }
            } else {
                setPgStatus(false);
                localStorage.removeItem('pgdata')
                setErrorMessage({ status: true, message: 'Requested payment is failed', severity: 'warning', inputName: '' })
            }
        }

    }, [pathname])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    useEffect(() => {

        if (isAddPopintsSuccess) {
            navigate('/add-point')
        }

        if (isAddPopintsFailed) {
            localStorage.removeItem('pgdata')
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }
    }, [isAddPopintsSuccess, isAddPopintsFailed])

    return (
        <Box>
            <Card>
                <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                {isLoginLoading && <CircularProgress />}
                {pgStatus ? <Typography>Payment Success</Typography> : <Typography>Payment Failed</Typography>}
            </Card>
        </Box>
    )
}



const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    pageMessage: state.authentication.pageMessage,
    isAddPopintsSuccess: state.authentication.isAddPopintsSuccess,
    isAddPopintsFailed: state.authentication.isAddPopintsFailed,
})


export default connect(mapStateToProps)(PayStatus);