
import { Alert, Box, Button, Card, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import bankdetails from "../profile/bankdetails";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { MobileDatePicker } from "@mui/x-date-pickers";
import RangeDatepicker from "../../components/RangeDatepicker";
import { reportsAction } from "../../store/reducer/reports";
import ReportList from "../../components/ReportList";
import { starlineActions } from "../../store/reducer/starline";
import { homePageActions } from "../../store/reducer/home";

const BidHistory = ({
    starLineBidHistoryData,
    isStarLineLoading,
    isStarLineWinningHistoryDataFailed,
    starLineMsg }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession);
    const [date_from, setDateFrom] = useState<Dayjs | null>(dayjs(new Date()));
    const [date_to, setDateTo] = useState<Dayjs | null>(dayjs(new Date()));
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [winList, setWinList] = useState<Array<any>>([]);
    const dispatch = useDispatch();
    
    useEffect(()=>{
        const payload: any = {
            app_key: "@34Y@#8deepmatka",
            bid_from: date_from?.toISOString(),
            bid_to: date_to?.toISOString(),
            user_id: finalData.user_id
        }
        dispatch(starlineActions.starLineBidHistoryDataReq(payload))
        const pay: any = {
            menuname: 'starlinebidhistory'
        }
        dispatch(homePageActions.selectedMenu(pay))
    },[])
    
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    const handleChange = (e: any) => {
        console.log(e.target)
    }

    useEffect(() => {
        if (starLineBidHistoryData) {
            setWinList(starLineBidHistoryData.bid_data)
        }
    }, [starLineBidHistoryData])

    const getData = () => {
        const payload: any = {
            app_key: "@34Y@#8deepmatka",
            bid_from: date_from?.toISOString(),
            bid_to: date_to?.toISOString(),
            user_id: finalData.user_id
        }

        if (payload.date_from != '' && payload.date_to != '') {
            setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
            dispatch(starlineActions.starLineBidHistoryDataReq(payload))
        } else {
            setErrorMessage({ status: true, message: "Please choose date.", severity: "error", inputName: '' })
        }
    }
    return (
        <Container>
            <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
            <Grid container>
            <RangeDatepicker date_from={date_from} date_to={date_to} setDateFrom={setDateFrom} setDateTo={setDateTo} handleClick={getData} />
            </Grid>
            <ReportList data={winList} loading={isStarLineLoading} type={'bidhistory'} page={'starline'}/>
        </Container>
    )
}



const mapStateToProps = (state: any) => ({
    starLineBidHistoryData: state.starline.starLineBidHistoryData,
    isStarLineLoading: state.starline.isStarLineLoading,
    isStarLineWinningHistoryDataFailed: state.reports.isStarLineWinningHistoryDataFailed,
    starLineMsg: state.reports.starLineMsg,
})


export default connect(mapStateToProps)(BidHistory);

