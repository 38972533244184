export const gameNumbers: any = {
    'single_digit': 1,
    'jodi_digit': 2,
    'single_pana': 3,
    'double_pana': 4,
    'tripple_pana': 5,
    'half_sangam': 6,
    'full_sangam': 7
}


export const gameNameList: any = {
    "1": 'Single Digit',
    "2": 'Jodi Digit',
    "3": 'Single Pana',
    "4": 'Double Pana',
    "5": 'Tripple Pana',
    "6": 'Half Sangam',
    "7": 'Full Sangam',
}

export const gameNumberInputList: any =
{
    "1": [
        {
            "id": "1",
            "label": "0"
        },
        {
            "id": "2",
            "label": "1"
        },
        {
            "id": "3",
            "label": "2"
        },
        {
            "id": "4",
            "label": "3"
        },
        {
            "id": "5",
            "label": "4"
        },
        {
            "id": "6",
            "label": "5"
        },
        {
            "id": "7",
            "label": "6"
        },
        {
            "id": "8",
            "label": "7"
        },
        {
            "id": "9",
            "label": "8"
        },
        {
            "id": "10",
            "label": "9"
        }
    ],
    "2": [
        {
            "id": "1",
            "label": "00"
        },
        {
            "id": "2",
            "label": "01"
        },
        {
            "id": "3",
            "label": "02"
        },
        {
            "id": "4",
            "label": "03"
        },
        {
            "id": "5",
            "label": "04"
        },
        {
            "id": "6",
            "label": "05"
        },
        {
            "id": "7",
            "label": "06"
        },
        {
            "id": "8",
            "label": "07"
        },
        {
            "id": "9",
            "label": "08"
        },
        {
            "id": "10",
            "label": "09"
        },
        {
            "id": "11",
            "label": "10"
        },
        {
            "id": "12",
            "label": "11"
        },
        {
            "id": "13",
            "label": "12"
        },
        {
            "id": "14",
            "label": "13"
        },
        {
            "id": "15",
            "label": "14"
        },
        {
            "id": "16",
            "label": "15"
        },
        {
            "id": "17",
            "label": "16"
        },
        {
            "id": "18",
            "label": "17"
        },
        {
            "id": "19",
            "label": "18"
        },
        {
            "id": "20",
            "label": "19"
        },
        {
            "id": "21",
            "label": "20"
        },
        {
            "id": "22",
            "label": "21"
        },
        {
            "id": "23",
            "label": "22"
        },
        {
            "id": "24",
            "label": "23"
        },
        {
            "id": "25",
            "label": "24"
        },
        {
            "id": "26",
            "label": "25"
        },
        {
            "id": "27",
            "label": "26"
        },
        {
            "id": "28",
            "label": "27"
        },
        {
            "id": "29",
            "label": "28"
        },
        {
            "id": "30",
            "label": "29"
        },
        {
            "id": "31",
            "label": "30"
        },
        {
            "id": "32",
            "label": "31"
        },
        {
            "id": "33",
            "label": "32"
        },
        {
            "id": "34",
            "label": "33"
        },
        {
            "id": "35",
            "label": "34"
        },
        {
            "id": "36",
            "label": "35"
        },
        {
            "id": "37",
            "label": "36"
        },
        {
            "id": "38",
            "label": "37"
        },
        {
            "id": "39",
            "label": "38"
        },
        {
            "id": "40",
            "label": "39"
        },
        {
            "id": "41",
            "label": "40"
        },
        {
            "id": "42",
            "label": "41"
        },
        {
            "id": "43",
            "label": "42"
        },
        {
            "id": "44",
            "label": "43"
        },
        {
            "id": "45",
            "label": "44"
        },
        {
            "id": "46",
            "label": "45"
        },
        {
            "id": "47",
            "label": "46"
        },
        {
            "id": "48",
            "label": "47"
        },
        {
            "id": "49",
            "label": "48"
        },
        {
            "id": "50",
            "label": "49"
        },
        {
            "id": "51",
            "label": "50"
        },
        {
            "id": "52",
            "label": "51"
        },
        {
            "id": "53",
            "label": "52"
        },
        {
            "id": "54",
            "label": "53"
        },
        {
            "id": "55",
            "label": "54"
        },
        {
            "id": "56",
            "label": "55"
        },
        {
            "id": "57",
            "label": "56"
        },
        {
            "id": "58",
            "label": "57"
        },
        {
            "id": "59",
            "label": "58"
        },
        {
            "id": "60",
            "label": "59"
        },
        {
            "id": "61",
            "label": "60"
        },
        {
            "id": "62",
            "label": "61"
        },
        {
            "id": "63",
            "label": "62"
        },
        {
            "id": "64",
            "label": "63"
        },
        {
            "id": "65",
            "label": "64"
        },
        {
            "id": "66",
            "label": "65"
        },
        {
            "id": "67",
            "label": "66"
        },
        {
            "id": "68",
            "label": "67"
        },
        {
            "id": "69",
            "label": "68"
        },
        {
            "id": "70",
            "label": "69"
        },
        {
            "id": "71",
            "label": "70"
        },
        {
            "id": "72",
            "label": "71"
        },
        {
            "id": "73",
            "label": "72"
        },
        {
            "id": "74",
            "label": "73"
        },
        {
            "id": "75",
            "label": "74"
        },
        {
            "id": "76",
            "label": "75"
        },
        {
            "id": "77",
            "label": "76"
        },
        {
            "id": "78",
            "label": "77"
        },
        {
            "id": "79",
            "label": "78"
        },
        {
            "id": "80",
            "label": "79"
        },
        {
            "id": "81",
            "label": "80"
        },
        {
            "id": "82",
            "label": "81"
        },
        {
            "id": "83",
            "label": "82"
        },
        {
            "id": "84",
            "label": "83"
        },
        {
            "id": "85",
            "label": "84"
        },
        {
            "id": "86",
            "label": "85"
        },
        {
            "id": "87",
            "label": "86"
        },
        {
            "id": "88",
            "label": "87"
        },
        {
            "id": "89",
            "label": "88"
        },
        {
            "id": "90",
            "label": "89"
        },
        {
            "id": "91",
            "label": "90"
        },
        {
            "id": "92",
            "label": "91"
        },
        {
            "id": "93",
            "label": "92"
        },
        {
            "id": "94",
            "label": "93"
        },
        {
            "id": "95",
            "label": "94"
        },
        {
            "id": "96",
            "label": "95"
        },
        {
            "id": "97",
            "label": "96"
        },
        {
            "id": "98",
            "label": "97"
        },
        {
            "id": "99",
            "label": "98"
        },
        {
            "id": "100",
            "label": "99"
        }
    ],
    "3": [
        { "id": "1", "single_digit": "0", "label": "127" },
        { "id": "2", "single_digit": "0", "label": "136" },
        { "id": "3", "single_digit": "0", "label": "145" },
        { "id": "4", "single_digit": "0", "label": "190" },
        { "id": "5", "single_digit": "0", "label": "235" },
        { "id": "6", "single_digit": "0", "label": "280" },
        { "id": "7", "single_digit": "0", "label": "370" },
        { "id": "8", "single_digit": "0", "label": "479" },
        { "id": "9", "single_digit": "0", "label": "460" },
        { "id": "10", "single_digit": "0", "label": "569" },
        { "id": "11", "single_digit": "0", "label": "389" },
        { "id": "12", "single_digit": "0", "label": "578" },
        { "id": "13", "single_digit": "1", "label": "128" },
        { "id": "14", "single_digit": "1", "label": "137" },
        { "id": "15", "single_digit": "1", "label": "146" },
        { "id": "16", "single_digit": "1", "label": "236" },
        { "id": "17", "single_digit": "1", "label": "245" },
        { "id": "18", "single_digit": "1", "label": "290" },
        { "id": "19", "single_digit": "1", "label": "380" },
        { "id": "20", "single_digit": "1", "label": "470" },
        { "id": "21", "single_digit": "1", "label": "489" },
        { "id": "22", "single_digit": "1", "label": "560" },
        { "id": "23", "single_digit": "1", "label": "678" },
        { "id": "24", "single_digit": "1", "label": "579" },
        { "id": "25", "single_digit": "2", "label": "129" },
        { "id": "26", "single_digit": "2", "label": "138" },
        { "id": "28", "single_digit": "2", "label": "147" },
        { "id": "29", "single_digit": "2", "label": "156" },
        { "id": "30", "single_digit": "2", "label": "237" },
        { "id": "31", "single_digit": "2", "label": "246" },
        { "id": "32", "single_digit": "2", "label": "345" },
        { "id": "33", "single_digit": "2", "label": "390" },
        { "id": "34", "single_digit": "2", "label": "480" },
        { "id": "35", "single_digit": "2", "label": "570" },
        { "id": "36", "single_digit": "2", "label": "679" },
        { "id": "37", "single_digit": "2", "label": "589" },
        { "id": "38", "single_digit": "3", "label": "120" },
        { "id": "39", "single_digit": "3", "label": "139" },
        { "id": "40", "single_digit": "3", "label": "148" },
        { "id": "41", "single_digit": "3", "label": "157" },
        { "id": "42", "single_digit": "3", "label": "238" },
        { "id": "43", "single_digit": "3", "label": "247" },
        { "id": "44", "single_digit": "3", "label": "256" },
        { "id": "45", "single_digit": "3", "label": "346" },
        { "id": "46", "single_digit": "3", "label": "490" },
        { "id": "47", "single_digit": "3", "label": "580" },
        { "id": "48", "single_digit": "3", "label": "670" },
        { "id": "49", "single_digit": "3", "label": "689" },
        { "id": "50", "single_digit": "4", "label": "130" },
        { "id": "51", "single_digit": "4", "label": "149" },
        { "id": "52", "single_digit": "4", "label": "158" },
        { "id": "53", "single_digit": "4", "label": "167" },
        { "id": "54", "single_digit": "4", "label": "239" },
        { "id": "55", "single_digit": "4", "label": "248" },
        { "id": "56", "single_digit": "4", "label": "257" },
        { "id": "57", "single_digit": "4", "label": "347" },
        { "id": "58", "single_digit": "4", "label": "356" },
        { "id": "59", "single_digit": "4", "label": "590" },
        { "id": "60", "single_digit": "4", "label": "680" },
        { "id": "61", "single_digit": "4", "label": "789" },
        { "id": "62", "single_digit": "5", "label": "140" },
        { "id": "63", "single_digit": "5", "label": "159" },
        { "id": "64", "single_digit": "5", "label": "168" },
        { "id": "65", "single_digit": "5", "label": "230" },
        { "id": "66", "single_digit": "5", "label": "249" },
        { "id": "67", "single_digit": "5", "label": "258" },
        { "id": "68", "single_digit": "5", "label": "267" },
        { "id": "69", "single_digit": "5", "label": "348" },
        { "id": "70", "single_digit": "5", "label": "357" },
        { "id": "71", "single_digit": "5", "label": "456" },
        { "id": "72", "single_digit": "5", "label": "690" },
        { "id": "73", "single_digit": "5", "label": "780" },
        { "id": "74", "single_digit": "6", "label": "123" },
        { "id": "75", "single_digit": "6", "label": "150" },
        { "id": "76", "single_digit": "6", "label": "169" },
        { "id": "77", "single_digit": "6", "label": "178" },
        { "id": "78", "single_digit": "6", "label": "240" },
        { "id": "79", "single_digit": "6", "label": "259" },
        { "id": "80", "single_digit": "6", "label": "268" },
        { "id": "81", "single_digit": "6", "label": "349" },
        { "id": "82", "single_digit": "6", "label": "358" },
        { "id": "83", "single_digit": "6", "label": "457" },
        { "id": "84", "single_digit": "6", "label": "367" },
        { "id": "85", "single_digit": "6", "label": "790" },
        { "id": "86", "single_digit": "7", "label": "124" },
        { "id": "87", "single_digit": "7", "label": "160" },
        { "id": "88", "single_digit": "7", "label": "179" },
        { "id": "89", "single_digit": "7", "label": "250" },
        { "id": "90", "single_digit": "7", "label": "269" },
        { "id": "91", "single_digit": "7", "label": "278" },
        { "id": "92", "single_digit": "7", "label": "340" },
        { "id": "93", "single_digit": "7", "label": "359" },
        { "id": "94", "single_digit": "7", "label": "368" },
        { "id": "95", "single_digit": "7", "label": "458" },
        { "id": "96", "single_digit": "7", "label": "467" },
        { "id": "97", "single_digit": "7", "label": "890" },
        { "id": "98", "single_digit": "8", "label": "125" },
        { "id": "99", "single_digit": "8", "label": "134" },
        { "id": "100", "single_digit": "8", "label": "170" },
        { "id": "101", "single_digit": "8", "label": "189" },
        { "id": "102", "single_digit": "8", "label": "260" },
        { "id": "103", "single_digit": "8", "label": "279" },
        { "id": "104", "single_digit": "8", "label": "350" },
        { "id": "105", "single_digit": "8", "label": "369" },
        { "id": "106", "single_digit": "8", "label": "378" },
        { "id": "107", "single_digit": "8", "label": "459" },
        { "id": "108", "single_digit": "8", "label": "567" },
        { "id": "109", "single_digit": "8", "label": "468" },
        { "id": "110", "single_digit": "9", "label": "126" },
        { "id": "111", "single_digit": "9", "label": "135" },
        { "id": "112", "single_digit": "9", "label": "180" },
        { "id": "113", "single_digit": "9", "label": "234" },
        { "id": "114", "single_digit": "9", "label": "270" },
        { "id": "115", "single_digit": "9", "label": "289" },
        { "id": "116", "single_digit": "9", "label": "360" },
        { "id": "117", "single_digit": "9", "label": "379" },
        { "id": "118", "single_digit": "9", "label": "450" },
        { "id": "119", "single_digit": "9", "label": "469" },
        { "id": "120", "single_digit": "9", "label": "469" },
        { "id": "121", "single_digit": "9", "label": "568" }
    ],
    "4": [
        { "id": "1", "single_digit": "0", "label": "550" },
        { "id": "2", "single_digit": "0", "label": "668" },
        { "id": "3", "single_digit": "0", "label": "244" },
        { "id": "4", "single_digit": "0", "label": "299" },
        { "id": "5", "single_digit": "0", "label": "226" },
        { "id": "6", "single_digit": "0", "label": "488" },
        { "id": "7", "single_digit": "0", "label": "677" },
        { "id": "8", "single_digit": "0", "label": "118" },
        { "id": "9", "single_digit": "0", "label": "334" },
        { "id": "10", "single_digit": "1", "label": "100" },
        { "id": "11", "single_digit": "1", "label": "119" },
        { "id": "12", "single_digit": "1", "label": "155" },
        { "id": "13", "single_digit": "1", "label": "227" },
        { "id": "14", "single_digit": "1", "label": "335" },
        { "id": "15", "single_digit": "1", "label": "344" },
        { "id": "16", "single_digit": "1", "label": "399" },
        { "id": "17", "single_digit": "1", "label": "588" },
        { "id": "18", "single_digit": "1", "label": "669" },
        { "id": "19", "single_digit": "2", "label": "200" },
        { "id": "20", "single_digit": "2", "label": "110" },
        { "id": "21", "single_digit": "2", "label": "228" },
        { "id": "22", "single_digit": "2", "label": "255" },
        { "id": "23", "single_digit": "2", "label": "336" },
        { "id": "24", "single_digit": "2", "label": "499" },
        { "id": "25", "single_digit": "2", "label": "660" },
        { "id": "26", "single_digit": "2", "label": "688" },
        { "id": "27", "single_digit": "2", "label": "778" },
        { "id": "28", "single_digit": "3", "label": "300" },
        { "id": "29", "single_digit": "3", "label": "166" },
        { "id": "30", "single_digit": "3", "label": "229" },
        { "id": "31", "single_digit": "3", "label": "337" },
        { "id": "32", "single_digit": "3", "label": "355" },
        { "id": "33", "single_digit": "3", "label": "445" },
        { "id": "34", "single_digit": "3", "label": "599" },
        { "id": "35", "single_digit": "3", "label": "779" },
        { "id": "36", "single_digit": "3", "label": "788" },
        { "id": "37", "single_digit": "4", "label": "400" },
        { "id": "38", "single_digit": "4", "label": "112" },
        { "id": "39", "single_digit": "4", "label": "220" },
        { "id": "40", "single_digit": "4", "label": "266" },
        { "id": "41", "single_digit": "4", "label": "338" },
        { "id": "42", "single_digit": "4", "label": "446" },
        { "id": "43", "single_digit": "4", "label": "455" },
        { "id": "44", "single_digit": "4", "label": "699" },
        { "id": "45", "single_digit": "4", "label": "770" },
        { "id": "46", "single_digit": "5", "label": "500" },
        { "id": "47", "single_digit": "5", "label": "113" },
        { "id": "48", "single_digit": "5", "label": "122" },
        { "id": "49", "single_digit": "5", "label": "177" },
        { "id": "50", "single_digit": "5", "label": "339" },
        { "id": "51", "single_digit": "5", "label": "366" },
        { "id": "52", "single_digit": "5", "label": "447" },
        { "id": "53", "single_digit": "5", "label": "799" },
        { "id": "54", "single_digit": "5", "label": "889" },
        { "id": "55", "single_digit": "6", "label": "600" },
        { "id": "56", "single_digit": "6", "label": "114" },
        { "id": "57", "single_digit": "6", "label": "277" },
        { "id": "58", "single_digit": "6", "label": "330" },
        { "id": "59", "single_digit": "6", "label": "448" },
        { "id": "60", "single_digit": "6", "label": "466" },
        { "id": "61", "single_digit": "6", "label": "556" },
        { "id": "62", "single_digit": "6", "label": "880" },
        { "id": "63", "single_digit": "6", "label": "899" },
        { "id": "64", "single_digit": "7", "label": "700" },
        { "id": "65", "single_digit": "7", "label": "115" },
        { "id": "66", "single_digit": "7", "label": "133" },
        { "id": "67", "single_digit": "7", "label": "188" },
        { "id": "68", "single_digit": "7", "label": "223" },
        { "id": "69", "single_digit": "7", "label": "377" },
        { "id": "70", "single_digit": "7", "label": "449" },
        { "id": "71", "single_digit": "7", "label": "557" },
        { "id": "72", "single_digit": "7", "label": "566" },
        { "id": "73", "single_digit": "8", "label": "800" },
        { "id": "74", "single_digit": "8", "label": "116" },
        { "id": "75", "single_digit": "8", "label": "224" },
        { "id": "76", "single_digit": "8", "label": "233" },
        { "id": "77", "single_digit": "8", "label": "288" },
        { "id": "78", "single_digit": "8", "label": "440" },
        { "id": "79", "single_digit": "8", "label": "477" },
        { "id": "80", "single_digit": "8", "label": "558" },
        { "id": "81", "single_digit": "8", "label": "990" },
        { "id": "82", "single_digit": "9", "label": "900" },
        { "id": "83", "single_digit": "9", "label": "117" },
        { "id": "84", "single_digit": "9", "label": "144" },
        { "id": "85", "single_digit": "9", "label": "199" },
        { "id": "86", "single_digit": "9", "label": "225" },
        { "id": "87", "single_digit": "9", "label": "388" },
        { "id": "88", "single_digit": "9", "label": "559" },
        { "id": "89", "single_digit": "9", "label": "577" },
        { "id": "90", "single_digit": "9", "label": "667" }
    ],
    "5": [
        { "id": "1", "label": "000" },
        { "id": "2", "label": "111" },
        { "id": "3", "label": "222" },
        { "id": "4", "label": "333" },
        { "id": "5", "label": "444" },
        { "id": "6", "label": "555" },
        { "id": "7", "label": "666" },
        { "id": "8", "label": "777" },
        { "id": "9", "label": "888" },
        { "id": "10", "label": "999" }
    ],
    "6": [
        { "id": "1", "ank": "open", "label": "0" },
        { "id": "2", "ank": "open", "label": "1" },
        { "id": "3", "ank": "open", "label": "2" },
        { "id": "4", "ank": "open", "label": "3" },
        { "id": "5", "ank": "open", "label": "4" },
        { "id": "6", "ank": "open", "label": "5" },
        { "id": "7", "ank": "open", "label": "6" },
        { "id": "8", "ank": "open", "label": "7" },
        { "id": "9", "ank": "open", "label": "8" },
        { "id": "10", "ank": "open", "label": "9" },
        { "id": "11", "ank": "close", "label": "000" },
        { "id": "12", "ank": "close", "label": "100" },
        { "id": "13", "ank": "close", "label": "110" },
        { "id": "14", "ank": "close", "label": "111" },
        { "id": "15", "ank": "close", "label": "112" },
        { "id": "16", "ank": "close", "label": "113" },
        { "id": "17", "ank": "close", "label": "114" },
        { "id": "18", "ank": "close", "label": "115" },
        { "id": "19", "ank": "close", "label": "116" },
        { "id": "20", "ank": "close", "label": "117" },
        { "id": "21", "ank": "close", "label": "118" },
        { "id": "22", "ank": "close", "label": "119" },
        { "id": "23", "ank": "close", "label": "120" },
        { "id": "24", "ank": "close", "label": "122" },
        { "id": "25", "ank": "close", "label": "123" },
        { "id": "26", "ank": "close", "label": "124" },
        { "id": "27", "ank": "close", "label": "125" },
        { "id": "28", "ank": "close", "label": "126" },
        { "id": "29", "ank": "close", "label": "127" },
        { "id": "30", "ank": "close", "label": "128" },
        { "id": "31", "ank": "close", "label": "129" },
        { "id": "32", "ank": "close", "label": "130" },
        { "id": "33", "ank": "close", "label": "133" },
        { "id": "34", "ank": "close", "label": "134" },
        { "id": "35", "ank": "close", "label": "135" },
        { "id": "36", "ank": "close", "label": "136" },
        { "id": "37", "ank": "close", "label": "137" },
        { "id": "38", "ank": "close", "label": "138" },
        { "id": "39", "ank": "close", "label": "139" },
        { "id": "40", "ank": "close", "label": "140" },
        { "id": "41", "ank": "close", "label": "144" },
        { "id": "42", "ank": "close", "label": "145" },
        { "id": "43", "ank": "close", "label": "146" },
        { "id": "44", "ank": "close", "label": "147" },
        { "id": "45", "ank": "close", "label": "148" },
        { "id": "46", "ank": "close", "label": "149" },
        { "id": "47", "ank": "close", "label": "150" },
        { "id": "48", "ank": "close", "label": "155" },
        { "id": "49", "ank": "close", "label": "156" },
        { "id": "50", "ank": "close", "label": "157" },
        { "id": "51", "ank": "close", "label": "158" },
        { "id": "52", "ank": "close", "label": "159" },
        { "id": "53", "ank": "close", "label": "160" },
        { "id": "54", "ank": "close", "label": "166" },
        { "id": "55", "ank": "close", "label": "167" },
        { "id": "56", "ank": "close", "label": "168" },
        { "id": "57", "ank": "close", "label": "169" },
        { "id": "58", "ank": "close", "label": "170" },
        { "id": "59", "ank": "close", "label": "177" },
        { "id": "60", "ank": "close", "label": "178" },
        { "id": "61", "ank": "close", "label": "179" },
        { "id": "62", "ank": "close", "label": "180" },
        { "id": "63", "ank": "close", "label": "188" },
        { "id": "64", "ank": "close", "label": "189" },
        { "id": "65", "ank": "close", "label": "190" },
        { "id": "66", "ank": "close", "label": "199" },
        { "id": "67", "ank": "close", "label": "200" },
        { "id": "68", "ank": "close", "label": "220" },
        { "id": "69", "ank": "close", "label": "222" },
        { "id": "70", "ank": "close", "label": "223" },
        { "id": "71", "ank": "close", "label": "224" },
        { "id": "72", "ank": "close", "label": "225" },
        { "id": "73", "ank": "close", "label": "226" },
        { "id": "74", "ank": "close", "label": "227" },
        { "id": "75", "ank": "close", "label": "228" },
        { "id": "76", "ank": "close", "label": "229" },
        { "id": "77", "ank": "close", "label": "230" },
        { "id": "78", "ank": "close", "label": "233" },
        { "id": "79", "ank": "close", "label": "234" },
        { "id": "80", "ank": "close", "label": "235" },
        { "id": "81", "ank": "close", "label": "236" },
        { "id": "82", "ank": "close", "label": "237" },
        { "id": "83", "ank": "close", "label": "238" },
        { "id": "84", "ank": "close", "label": "239" },
        { "id": "85", "ank": "close", "label": "240" },
        { "id": "86", "ank": "close", "label": "244" },
        { "id": "87", "ank": "close", "label": "245" },
        { "id": "88", "ank": "close", "label": "246" },
        { "id": "89", "ank": "close", "label": "247" },
        { "id": "90", "ank": "close", "label": "248" },
        { "id": "91", "ank": "close", "label": "249" },
        { "id": "92", "ank": "close", "label": "250" },
        { "id": "93", "ank": "close", "label": "255" },
        { "id": "94", "ank": "close", "label": "256" },
        { "id": "95", "ank": "close", "label": "257" },
        { "id": "96", "ank": "close", "label": "258" },
        { "id": "97", "ank": "close", "label": "259" },
        { "id": "98", "ank": "close", "label": "260" },
        { "id": "99", "ank": "close", "label": "266" },
        { "id": "100", "ank": "close", "label": "267" },
        { "id": "101", "ank": "close", "label": "268" },
        { "id": "102", "ank": "close", "label": "269" },
        { "id": "103", "ank": "close", "label": "270" },
        { "id": "104", "ank": "close", "label": "277" },
        { "id": "105", "ank": "close", "label": "278" },
        { "id": "106", "ank": "close", "label": "279" },
        { "id": "107", "ank": "close", "label": "280" },
        { "id": "108", "ank": "close", "label": "288" },
        { "id": "109", "ank": "close", "label": "289" },
        { "id": "110", "ank": "close", "label": "290" },
        { "id": "111", "ank": "close", "label": "291" },
        { "id": "112", "ank": "close", "label": "292" },
        { "id": "113", "ank": "close", "label": "293" },
        { "id": "114", "ank": "close", "label": "294" },
        { "id": "115", "ank": "close", "label": "295" },
        { "id": "116", "ank": "close", "label": "296" },
        { "id": "117", "ank": "close", "label": "297" },
        { "id": "118", "ank": "close", "label": "298" },
        { "id": "119", "ank": "close", "label": "299" },
        { "id": "120", "ank": "close", "label": "300" },
        { "id": "121", "ank": "close", "label": "330" },
        { "id": "122", "ank": "close", "label": "333" },
        { "id": "123", "ank": "close", "label": "334" },
        { "id": "124", "ank": "close", "label": "335" },
        { "id": "125", "ank": "close", "label": "336" },
        { "id": "126", "ank": "close", "label": "337" },
        { "id": "127", "ank": "close", "label": "338" },
        { "id": "128", "ank": "close", "label": "339" },
        { "id": "129", "ank": "close", "label": "340" },
        { "id": "130", "ank": "close", "label": "344" },
        { "id": "131", "ank": "close", "label": "345" },
        { "id": "132", "ank": "close", "label": "346" },
        { "id": "133", "ank": "close", "label": "347" },
        { "id": "134", "ank": "close", "label": "348" },
        { "id": "135", "ank": "close", "label": "349" },
        { "id": "136", "ank": "close", "label": "350" },
        { "id": "137", "ank": "close", "label": "355" },
        { "id": "138", "ank": "close", "label": "356" },
        { "id": "139", "ank": "close", "label": "357" },
        { "id": "140", "ank": "close", "label": "358" },
        { "id": "141", "ank": "close", "label": "359" },
        { "id": "142", "ank": "close", "label": "360" },
        { "id": "143", "ank": "close", "label": "366" },
        { "id": "144", "ank": "close", "label": "367" },
        { "id": "145", "ank": "close", "label": "368" },
        { "id": "146", "ank": "close", "label": "369" },
        { "id": "147", "ank": "close", "label": "370" },
        { "id": "148", "ank": "close", "label": "377" },
        { "id": "149", "ank": "close", "label": "378" },
        { "id": "150", "ank": "close", "label": "379" },
        { "id": "151", "ank": "close", "label": "380" },
        { "id": "152", "ank": "close", "label": "388" },
        { "id": "153", "ank": "close", "label": "389" },
        { "id": "154", "ank": "close", "label": "390" },
        { "id": "155", "ank": "close", "label": "399" },
        { "id": "156", "ank": "close", "label": "400" },
        { "id": "157", "ank": "close", "label": "440" },
        { "id": "158", "ank": "close", "label": "444" },
        { "id": "159", "ank": "close", "label": "445" },
        { "id": "160", "ank": "close", "label": "446" },
        { "id": "161", "ank": "close", "label": "447" },
        { "id": "162", "ank": "close", "label": "448" },
        { "id": "163", "ank": "close", "label": "449" },
        { "id": "164", "ank": "close", "label": "450" },
        { "id": "165", "ank": "close", "label": "455" },
        { "id": "166", "ank": "close", "label": "456" },
        { "id": "167", "ank": "close", "label": "457" },
        { "id": "168", "ank": "close", "label": "458" },
        { "id": "169", "ank": "close", "label": "459" },
        { "id": "170", "ank": "close", "label": "460" },
        { "id": "171", "ank": "close", "label": "466" },
        { "id": "172", "ank": "close", "label": "467" },
        { "id": "173", "ank": "close", "label": "468" },
        { "id": "174", "ank": "close", "label": "469" },
        { "id": "175", "ank": "close", "label": "470" },
        { "id": "176", "ank": "close", "label": "477" },
        { "id": "177", "ank": "close", "label": "478" },
        { "id": "178", "ank": "close", "label": "479" },
        { "id": "179", "ank": "close", "label": "480" },
        { "id": "180", "ank": "close", "label": "488" },
        { "id": "181", "ank": "close", "label": "489" },
        { "id": "182", "ank": "close", "label": "490" },
        { "id": "183", "ank": "close", "label": "499" },
        { "id": "184", "ank": "close", "label": "500" },
        { "id": "185", "ank": "close", "label": "550" },
        { "id": "186", "ank": "close", "label": "555" },
        { "id": "187", "ank": "close", "label": "556" },
        { "id": "188", "ank": "close", "label": "557" },
        { "id": "189", "ank": "close", "label": "558" },
        { "id": "190", "ank": "close", "label": "559" },
        { "id": "191", "ank": "close", "label": "560" },
        { "id": "192", "ank": "close", "label": "566" },
        { "id": "193", "ank": "close", "label": "567" },
        { "id": "194", "ank": "close", "label": "568" },
        { "id": "195", "ank": "close", "label": "569" },
        { "id": "196", "ank": "close", "label": "570" },
        { "id": "197", "ank": "close", "label": "577" },
        { "id": "198", "ank": "close", "label": "578" },
        { "id": "199", "ank": "close", "label": "579" },
        { "id": "200", "ank": "close", "label": "580" },
        { "id": "201", "ank": "close", "label": "588" },
        { "id": "202", "ank": "close", "label": "589" },
        { "id": "203", "ank": "close", "label": "590" },
        { "id": "204", "ank": "close", "label": "591" },
        { "id": "205", "ank": "close", "label": "592" },
        { "id": "206", "ank": "close", "label": "593" },
        { "id": "207", "ank": "close", "label": "594" },
        { "id": "208", "ank": "close", "label": "595" },
        { "id": "209", "ank": "close", "label": "596" },
        { "id": "210", "ank": "close", "label": "597" },
        { "id": "211", "ank": "close", "label": "598" },
        { "id": "212", "ank": "close", "label": "599" },
        { "id": "213", "ank": "close", "label": "600" },
        { "id": "214", "ank": "close", "label": "660" },
        { "id": "215", "ank": "close", "label": "666" },
        { "id": "216", "ank": "close", "label": "667" },
        { "id": "217", "ank": "close", "label": "668" },
        { "id": "218", "ank": "close", "label": "669" },
        { "id": "219", "ank": "close", "label": "670" },
        { "id": "220", "ank": "close", "label": "677" },
        { "id": "221", "ank": "close", "label": "678" },
        { "id": "222", "ank": "close", "label": "679" },
        { "id": "223", "ank": "close", "label": "680" },
        { "id": "224", "ank": "close", "label": "681" },
        { "id": "225", "ank": "close", "label": "682" },
        { "id": "226", "ank": "close", "label": "683" },
        { "id": "227", "ank": "close", "label": "684" },
        { "id": "228", "ank": "close", "label": "685" },
        { "id": "229", "ank": "close", "label": "686" },
        { "id": "230", "ank": "close", "label": "687" },
        { "id": "231", "ank": "close", "label": "688" },
        { "id": "232", "ank": "close", "label": "689" },
        { "id": "233", "ank": "close", "label": "690" },
        { "id": "234", "ank": "close", "label": "699" },
        { "id": "235", "ank": "close", "label": "700" },
        { "id": "236", "ank": "close", "label": "770" },
        { "id": "237", "ank": "close", "label": "777" },
        { "id": "238", "ank": "close", "label": "778" },
        { "id": "239", "ank": "close", "label": "779" },
        { "id": "240", "ank": "close", "label": "780" },
        { "id": "241", "ank": "close", "label": "799" },
        { "id": "242", "ank": "close", "label": "800" },
        { "id": "243", "ank": "close", "label": "880" },
        { "id": "244", "ank": "close", "label": "899" },
        { "id": "245", "ank": "close", "label": "900" },
        { "id": "246", "ank": "close", "label": "990" },
        { "id": "247", "ank": "close", "label": "999" }
    ],
    "7": [
        { "id": "1", "ank": "open", "label": "000" },
        { "id": "2", "ank": "open", "label": "100" },
        { "id": "3", "ank": "open", "label": "110" },
        { "id": "4", "ank": "open", "label": "111" },
        { "id": "5", "ank": "open", "label": "112" },
        { "id": "6", "ank": "open", "label": "113" },
        { "id": "7", "ank": "open", "label": "114" },
        { "id": "8", "ank": "open", "label": "115" },
        { "id": "9", "ank": "open", "label": "116" },
        { "id": "10", "ank": "open", "label": "117" },
        { "id": "11", "ank": "open", "label": "118" },
        { "id": "12", "ank": "open", "label": "119" },
        { "id": "13", "ank": "open", "label": "120" },
        { "id": "14", "ank": "open", "label": "122" },
        { "id": "15", "ank": "open", "label": "123" },
        { "id": "16", "ank": "open", "label": "124" },
        { "id": "17", "ank": "open", "label": "125" },
        { "id": "18", "ank": "open", "label": "126" },
        { "id": "19", "ank": "open", "label": "127" },
        { "id": "20", "ank": "open", "label": "128" },
        { "id": "21", "ank": "open", "label": "129" },
        { "id": "22", "ank": "open", "label": "130" },
        { "id": "23", "ank": "open", "label": "133" },
        { "id": "24", "ank": "open", "label": "134" },
        { "id": "25", "ank": "open", "label": "135" },
        { "id": "26", "ank": "open", "label": "136" },
        { "id": "27", "ank": "open", "label": "137" },
        { "id": "28", "ank": "open", "label": "138" },
        { "id": "29", "ank": "open", "label": "139" },
        { "id": "30", "ank": "open", "label": "140" },
        { "id": "31", "ank": "open", "label": "144" },
        { "id": "32", "ank": "open", "label": "145" },
        { "id": "33", "ank": "open", "label": "146" },
        { "id": "34", "ank": "open", "label": "147" },
        { "id": "35", "ank": "open", "label": "148" },
        { "id": "36", "ank": "open", "label": "149" },
        { "id": "37", "ank": "open", "label": "150" },
        { "id": "38", "ank": "open", "label": "155" },
        { "id": "39", "ank": "open", "label": "156" },
        { "id": "40", "ank": "open", "label": "157" },
        { "id": "41", "ank": "open", "label": "158" },
        { "id": "42", "ank": "open", "label": "159" },
        { "id": "43", "ank": "open", "label": "160" },
        { "id": "44", "ank": "open", "label": "166" },
        { "id": "45", "ank": "open", "label": "167" },
        { "id": "46", "ank": "open", "label": "168" },
        { "id": "47", "ank": "open", "label": "169" },
        { "id": "48", "ank": "open", "label": "170" },
        { "id": "49", "ank": "open", "label": "177" },
        { "id": "50", "ank": "open", "label": "178" },
        { "id": "51", "ank": "open", "label": "179" },
        { "id": "52", "ank": "open", "label": "180" },
        { "id": "53", "ank": "open", "label": "188" },
        { "id": "54", "ank": "open", "label": "189" },
        { "id": "55", "ank": "open", "label": "190" },
        { "id": "56", "ank": "open", "label": "199" },
        { "id": "57", "ank": "open", "label": "200" },
        { "id": "58", "ank": "open", "label": "220" },
        { "id": "59", "ank": "open", "label": "222" },
        { "id": "60", "ank": "open", "label": "223" },
        { "id": "61", "ank": "open", "label": "224" },
        { "id": "62", "ank": "open", "label": "225" },
        { "id": "63", "ank": "open", "label": "226" },
        { "id": "64", "ank": "open", "label": "227" },
        { "id": "65", "ank": "open", "label": "228" },
        { "id": "66", "ank": "open", "label": "229" },
        { "id": "67", "ank": "open", "label": "230" },
        { "id": "68", "ank": "open", "label": "233" },
        { "id": "69", "ank": "open", "label": "234" },
        { "id": "70", "ank": "open", "label": "235" },
        { "id": "71", "ank": "open", "label": "236" },
        { "id": "72", "ank": "open", "label": "237" },
        { "id": "73", "ank": "open", "label": "238" },
        { "id": "74", "ank": "open", "label": "239" },
        { "id": "75", "ank": "open", "label": "240" },
        { "id": "76", "ank": "open", "label": "244" },
        { "id": "77", "ank": "open", "label": "245" },
        { "id": "78", "ank": "open", "label": "246" },
        { "id": "79", "ank": "open", "label": "247" },
        { "id": "80", "ank": "open", "label": "248" },
        { "id": "81", "ank": "open", "label": "249" },
        { "id": "82", "ank": "open", "label": "250" },
        { "id": "83", "ank": "open", "label": "255" },
        { "id": "84", "ank": "open", "label": "256" },
        { "id": "85", "ank": "open", "label": "257" },
        { "id": "86", "ank": "open", "label": "258" },
        { "id": "87", "ank": "open", "label": "259" },
        { "id": "88", "ank": "open", "label": "260" },
        { "id": "89", "ank": "open", "label": "255" },
        { "id": "90", "ank": "open", "label": "256" },
        { "id": "91", "ank": "open", "label": "257" },
        { "id": "92", "ank": "open", "label": "258" },
        { "id": "93", "ank": "open", "label": "259" },
        { "id": "94", "ank": "open", "label": "260" },
        { "id": "95", "ank": "open", "label": "277" },
        { "id": "96", "ank": "open", "label": "278" },
        { "id": "97", "ank": "open", "label": "279" },
        { "id": "98", "ank": "open", "label": "280" },
        { "id": "99", "ank": "open", "label": "288" },
        { "id": "100", "ank": "open", "label": "289" },
        { "id": "101", "ank": "open", "label": "290" },
        { "id": "102", "ank": "open", "label": "299" },
        { "id": "103", "ank": "open", "label": "300" },
        { "id": "104", "ank": "open", "label": "330" },
        { "id": "105", "ank": "open", "label": "333" },
        { "id": "106", "ank": "open", "label": "334" },
        { "id": "107", "ank": "open", "label": "335" },
        { "id": "108", "ank": "open", "label": "336" },
        { "id": "109", "ank": "open", "label": "337" },
        { "id": "110", "ank": "open", "label": "338" },
        { "id": "111", "ank": "open", "label": "339" },
        { "id": "112", "ank": "open", "label": "340" },
        { "id": "113", "ank": "open", "label": "344" },
        { "id": "114", "ank": "open", "label": "345" },
        { "id": "115", "ank": "open", "label": "346" },
        { "id": "116", "ank": "open", "label": "347" },
        { "id": "117", "ank": "open", "label": "348" },
        { "id": "118", "ank": "open", "label": "349" },
        { "id": "119", "ank": "open", "label": "350" },
        { "id": "120", "ank": "open", "label": "355" },
        { "id": "121", "ank": "open", "label": "356" },
        { "id": "122", "ank": "open", "label": "357" },
        { "id": "123", "ank": "open", "label": "358" },
        { "id": "124", "ank": "open", "label": "359" },
        { "id": "125", "ank": "open", "label": "360" },
        { "id": "126", "ank": "open", "label": "366" },
        { "id": "127", "ank": "open", "label": "367" },
        { "id": "128", "ank": "open", "label": "368" },
        { "id": "129", "ank": "open", "label": "369" },
        { "id": "130", "ank": "open", "label": "370" },
        { "id": "131", "ank": "open", "label": "377" },
        { "id": "132", "ank": "open", "label": "378" },
        { "id": "133", "ank": "open", "label": "379" },
        { "id": "134", "ank": "open", "label": "380" },
        { "id": "135", "ank": "open", "label": "388" },
        { "id": "136", "ank": "open", "label": "389" },
        { "id": "137", "ank": "open", "label": "390" },
        { "id": "138", "ank": "open", "label": "388" },
        { "id": "139", "ank": "open", "label": "389" },
        { "id": "140", "ank": "open", "label": "390" },
        { "id": "141", "ank": "open", "label": "440" },
        { "id": "142", "ank": "open", "label": "444" },
        { "id": "143", "ank": "open", "label": "445" },
        { "id": "144", "ank": "open", "label": "446" },
        { "id": "145", "ank": "open", "label": "447" },
        { "id": "146", "ank": "open", "label": "448" },
        { "id": "147", "ank": "open", "label": "449" },
        { "id": "148", "ank": "open", "label": "450" },
        { "id": "149", "ank": "open", "label": "455" },
        { "id": "150", "ank": "open", "label": "456" },
        { "id": "151", "ank": "open", "label": "457" },
        { "id": "152", "ank": "open", "label": "458" },
        { "id": "153", "ank": "open", "label": "459" },
        { "id": "154", "ank": "open", "label": "460" },
        { "id": "155", "ank": "open", "label": "466" },
        { "id": "156", "ank": "open", "label": "467" },
        { "id": "157", "ank": "open", "label": "468" },
        { "id": "158", "ank": "open", "label": "469" },
        { "id": "159", "ank": "open", "label": "470" },
        { "id": "160", "ank": "open", "label": "477" },
        { "id": "161", "ank": "open", "label": "478" },
        { "id": "162", "ank": "open", "label": "479" },
        { "id": "163", "ank": "open", "label": "480" },
        { "id": "164", "ank": "open", "label": "488" },
        { "id": "165", "ank": "open", "label": "489" },
        { "id": "166", "ank": "open", "label": "490" },
        { "id": "167", "ank": "open", "label": "499" },
        { "id": "168", "ank": "open", "label": "500" },
        { "id": "169", "ank": "open", "label": "550" },
        { "id": "170", "ank": "open", "label": "555" },
        { "id": "171", "ank": "open", "label": "556" },
        { "id": "172", "ank": "open", "label": "557" },
        { "id": "173", "ank": "open", "label": "558" },
        { "id": "174", "ank": "open", "label": "559" },
        { "id": "175", "ank": "open", "label": "560" },
        { "id": "176", "ank": "open", "label": "566" },
        { "id": "177", "ank": "open", "label": "567" },
        { "id": "178", "ank": "open", "label": "568" },
        { "id": "179", "ank": "open", "label": "569" },
        { "id": "180", "ank": "open", "label": "570" },
        { "id": "181", "ank": "open", "label": "577" },
        { "id": "182", "ank": "open", "label": "578" },
        { "id": "183", "ank": "open", "label": "579" },
        { "id": "184", "ank": "open", "label": "580" },
        { "id": "185", "ank": "open", "label": "588" },
        { "id": "186", "ank": "open", "label": "589" },
        { "id": "187", "ank": "open", "label": "590" },
        { "id": "188", "ank": "open", "label": "599" },
        { "id": "189", "ank": "open", "label": "600" },
        { "id": "190", "ank": "open", "label": "660" },
        { "id": "191", "ank": "open", "label": "666" },
        { "id": "192", "ank": "open", "label": "667" },
        { "id": "193", "ank": "open", "label": "668" },
        { "id": "194", "ank": "open", "label": "669" },
        { "id": "195", "ank": "open", "label": "670" },
        { "id": "196", "ank": "open", "label": "677" },
        { "id": "197", "ank": "open", "label": "678" },
        { "id": "198", "ank": "open", "label": "679" },
        { "id": "199", "ank": "open", "label": "680" },
        { "id": "200", "ank": "open", "label": "688" },
        { "id": "201", "ank": "open", "label": "689" },
        { "id": "202", "ank": "open", "label": "690" },
        { "id": "203", "ank": "open", "label": "699" },
        { "id": "204", "ank": "open", "label": "700" },
        { "id": "205", "ank": "open", "label": "770" },
        { "id": "206", "ank": "open", "label": "777" },
        { "id": "207", "ank": "open", "label": "778" },
        { "id": "208", "ank": "open", "label": "779" },
        { "id": "209", "ank": "open", "label": "780" },
        { "id": "210", "ank": "open", "label": "799" },
        { "id": "211", "ank": "open", "label": "800" },
        { "id": "212", "ank": "open", "label": "880" },
        { "id": "213", "ank": "open", "label": "888" },
        { "id": "214", "ank": "open", "label": "889" },
        { "id": "215", "ank": "open", "label": "890" },
        { "id": "216", "ank": "open", "label": "899" },
        { "id": "217", "ank": "open", "label": "900" },
        { "id": "218", "ank": "open", "label": "990" },
        { "id": "219", "ank": "open", "label": "999" },
        { "id": "220", "ank": "close", "label": "000" },
        { "id": "221", "ank": "close", "label": "100" },
        { "id": "223", "ank": "close", "label": "110" },
        { "id": "224", "ank": "close", "label": "111" },
        { "id": "225", "ank": "close", "label": "112" },
        { "id": "226", "ank": "close", "label": "113" },
        { "id": "227", "ank": "close", "label": "114" },
        { "id": "228", "ank": "close", "label": "115" },
        { "id": "229", "ank": "close", "label": "116" },
        { "id": "230", "ank": "close", "label": "117" },
        { "id": "231", "ank": "close", "label": "118" },
        { "id": "232", "ank": "close", "label": "119" },
        { "id": "233", "ank": "close", "label": "120" },
        { "id": "234", "ank": "close", "label": "122" },
        { "id": "235", "ank": "close", "label": "123" },
        { "id": "236", "ank": "close", "label": "124" },
        { "id": "237", "ank": "close", "label": "125" },
        { "id": "238", "ank": "close", "label": "126" },
        { "id": "239", "ank": "close", "label": "127" },
        { "id": "240", "ank": "close", "label": "128" },
        { "id": "241", "ank": "close", "label": "129" },
        { "id": "242", "ank": "close", "label": "130" },
        { "id": "243", "ank": "close", "label": "133" },
        { "id": "244", "ank": "close", "label": "134" },
        { "id": "245", "ank": "close", "label": "135" },
        { "id": "246", "ank": "close", "label": "136" },
        { "id": "247", "ank": "close", "label": "137" },
        { "id": "248", "ank": "close", "label": "138" },
        { "id": "249", "ank": "close", "label": "139" },
        { "id": "250", "ank": "close", "label": "140" },
        { "id": "251", "ank": "close", "label": "144" },
        { "id": "252", "ank": "close", "label": "145" },
        { "id": "253", "ank": "close", "label": "146" },
        { "id": "254", "ank": "close", "label": "147" },
        { "id": "255", "ank": "close", "label": "148" },
        { "id": "256", "ank": "close", "label": "149" },
        { "id": "257", "ank": "close", "label": "150" },
        { "id": "258", "ank": "close", "label": "155" },
        { "id": "259", "ank": "close", "label": "156" },
        { "id": "260", "ank": "close", "label": "157" },
        { "id": "261", "ank": "close", "label": "158" },
        { "id": "262", "ank": "close", "label": "159" },
        { "id": "263", "ank": "close", "label": "160" },
        { "id": "264", "ank": "close", "label": "166" },
        { "id": "265", "ank": "close", "label": "167" },
        { "id": "266", "ank": "close", "label": "168" },
        { "id": "267", "ank": "close", "label": "169" },
        { "id": "268", "ank": "close", "label": "170" },
        { "id": "269", "ank": "close", "label": "177" },
        { "id": "270", "ank": "close", "label": "178" },
        { "id": "271", "ank": "close", "label": "179" },
        { "id": "272", "ank": "close", "label": "180" },
        { "id": "273", "ank": "close", "label": "188" },
        { "id": "274", "ank": "close", "label": "189" },
        { "id": "275", "ank": "close", "label": "190" },
        { "id": "276", "ank": "close", "label": "199" },
        { "id": "277", "ank": "close", "label": "200" },
        { "id": "278", "ank": "close", "label": "220" },
        { "id": "279", "ank": "close", "label": "222" },
        { "id": "280", "ank": "close", "label": "223" },
        { "id": "281", "ank": "close", "label": "224" },
        { "id": "282", "ank": "close", "label": "225" },
        { "id": "283", "ank": "close", "label": "226" },
        { "id": "284", "ank": "close", "label": "227" },
        { "id": "285", "ank": "close", "label": "228" },
        { "id": "286", "ank": "close", "label": "229" },
        { "id": "287", "ank": "close", "label": "230" },
        { "id": "288", "ank": "close", "label": "233" },
        { "id": "289", "ank": "close", "label": "234" },
        { "id": "290", "ank": "close", "label": "235" },
        { "id": "291", "ank": "close", "label": "236" },
        { "id": "292", "ank": "close", "label": "237" },
        { "id": "293", "ank": "close", "label": "238" },
        { "id": "294", "ank": "close", "label": "239" },
        { "id": "295", "ank": "close", "label": "240" },
        { "id": "296", "ank": "close", "label": "244" },
        { "id": "297", "ank": "close", "label": "245" },
        { "id": "298", "ank": "close", "label": "246" },
        { "id": "299", "ank": "close", "label": "247" },
        { "id": "300", "ank": "close", "label": "248" },
        { "id": "301", "ank": "close", "label": "249" },
        { "id": "302", "ank": "close", "label": "250" },
        { "id": "303", "ank": "close", "label": "255" },
        { "id": "304", "ank": "close", "label": "256" },
        { "id": "305", "ank": "close", "label": "257" },
        { "id": "306", "ank": "close", "label": "258" },
        { "id": "307", "ank": "close", "label": "259" },
        { "id": "308", "ank": "close", "label": "260" },
        { "id": "309", "ank": "close", "label": "266" },
        { "id": "310", "ank": "close", "label": "267" },
        { "id": "311", "ank": "close", "label": "268" },
        { "id": "312", "ank": "close", "label": "269" },
        { "id": "313", "ank": "close", "label": "270" },
        { "id": "314", "ank": "close", "label": "277" },
        { "id": "315", "ank": "close", "label": "278" },
        { "id": "316", "ank": "close", "label": "279" },
        { "id": "317", "ank": "close", "label": "280" },
        { "id": "318", "ank": "close", "label": "288" },
        { "id": "319", "ank": "close", "label": "289" },
        { "id": "320", "ank": "close", "label": "290" },
        { "id": "321", "ank": "close", "label": "299" },
        { "id": "322", "ank": "close", "label": "300" },
        { "id": "323", "ank": "close", "label": "330" },
        { "id": "324", "ank": "close", "label": "333" },
        { "id": "325", "ank": "close", "label": "334" },
        { "id": "326", "ank": "close", "label": "335" },
        { "id": "327", "ank": "close", "label": "336" },
        { "id": "328", "ank": "close", "label": "337" },
        { "id": "329", "ank": "close", "label": "338" },
        { "id": "330", "ank": "close", "label": "339" },
        { "id": "331", "ank": "close", "label": "340" },
        { "id": "332", "ank": "close", "label": "344" },
        { "id": "333", "ank": "close", "label": "345" },
        { "id": "334", "ank": "close", "label": "346" },
        { "id": "335", "ank": "close", "label": "347" },
        { "id": "336", "ank": "close", "label": "348" },
        { "id": "337", "ank": "close", "label": "349" },
        { "id": "338", "ank": "close", "label": "350" },
        { "id": "339", "ank": "close", "label": "355" },
        { "id": "340", "ank": "close", "label": "356" },
        { "id": "341", "ank": "close", "label": "357" },
        { "id": "342", "ank": "close", "label": "358" },
        { "id": "343", "ank": "close", "label": "359" },
        { "id": "344", "ank": "close", "label": "360" },
        { "id": "345", "ank": "close", "label": "366" },
        { "id": "346", "ank": "close", "label": "367" },
        { "id": "347", "ank": "close", "label": "368" },
        { "id": "348", "ank": "close", "label": "369" },
        { "id": "349", "ank": "close", "label": "370" },
        { "id": "350", "ank": "close", "label": "377" },
        { "id": "351", "ank": "close", "label": "378" },
        { "id": "352", "ank": "close", "label": "379" },
        { "id": "353", "ank": "close", "label": "380" },
        { "id": "354", "ank": "close", "label": "388" },
        { "id": "355", "ank": "close", "label": "389" },
        { "id": "356", "ank": "close", "label": "390" },
        { "id": "357", "ank": "close", "label": "399" },
        { "id": "358", "ank": "close", "label": "400" },
        { "id": "359", "ank": "close", "label": "440" },
        { "id": "360", "ank": "close", "label": "444" },
        { "id": "361", "ank": "close", "label": "445" },
        { "id": "362", "ank": "close", "label": "446" },
        { "id": "363", "ank": "close", "label": "447" },
        { "id": "364", "ank": "close", "label": "448" },
        { "id": "365", "ank": "close", "label": "449" },
        { "id": "366", "ank": "close", "label": "450" },
        { "id": "367", "ank": "close", "label": "455" },
        { "id": "368", "ank": "close", "label": "456" },
        { "id": "369", "ank": "close", "label": "457" },
        { "id": "370", "ank": "close", "label": "458" },
        { "id": "371", "ank": "close", "label": "459" },
        { "id": "372", "ank": "close", "label": "460" },
        { "id": "373", "ank": "close", "label": "466" },
        { "id": "374", "ank": "close", "label": "467" },
        { "id": "375", "ank": "close", "label": "468" },
        { "id": "376", "ank": "close", "label": "469" },
        { "id": "377", "ank": "close", "label": "470" },
        { "id": "378", "ank": "close", "label": "477" },
        { "id": "379", "ank": "close", "label": "478" },
        { "id": "380", "ank": "close", "label": "479" },
        { "id": "381", "ank": "close", "label": "480" },
        { "id": "382", "ank": "close", "label": "488" },
        { "id": "383", "ank": "close", "label": "489" },
        { "id": "384", "ank": "close", "label": "490" },
        { "id": "385", "ank": "close", "label": "499" },
        { "id": "386", "ank": "close", "label": "500" },
        { "id": "387", "ank": "close", "label": "550" },
        { "id": "388", "ank": "close", "label": "555" },
        { "id": "389", "ank": "close", "label": "556" },
        { "id": "390", "ank": "close", "label": "557" },
        { "id": "391", "ank": "close", "label": "558" },
        { "id": "392", "ank": "close", "label": "559" },
        { "id": "393", "ank": "close", "label": "560" },
        { "id": "394", "ank": "close", "label": "566" },
        { "id": "395", "ank": "close", "label": "567" },
        { "id": "396", "ank": "close", "label": "568" },
        { "id": "397", "ank": "close", "label": "569" },
        { "id": "398", "ank": "close", "label": "570" },
        { "id": "399", "ank": "close", "label": "577" },
        { "id": "400", "ank": "close", "label": "578" },
        { "id": "401", "ank": "close", "label": "579" },
        { "id": "402", "ank": "close", "label": "580" },
        { "id": "403", "ank": "close", "label": "588" },
        { "id": "404", "ank": "close", "label": "589" },
        { "id": "405", "ank": "close", "label": "590" },
        { "id": "406", "ank": "close", "label": "599" },
        { "id": "407", "ank": "close", "label": "600" },
        { "id": "408", "ank": "close", "label": "660" },
        { "id": "409", "ank": "close", "label": "666" },
        { "id": "410", "ank": "close", "label": "667" },
        { "id": "411", "ank": "close", "label": "668" },
        { "id": "412", "ank": "close", "label": "669" },
        { "id": "413", "ank": "close", "label": "670" },
        { "id": "414", "ank": "close", "label": "677" },
        { "id": "415", "ank": "close", "label": "678" },
        { "id": "416", "ank": "close", "label": "679" },
        { "id": "417", "ank": "close", "label": "680" },
        { "id": "418", "ank": "close", "label": "688" },
        { "id": "419", "ank": "close", "label": "689" },
        { "id": "420", "ank": "close", "label": "690" },
        { "id": "421", "ank": "close", "label": "699" },
        { "id": "422", "ank": "close", "label": "700" },
        { "id": "423", "ank": "close", "label": "770" },
        { "id": "424", "ank": "close", "label": "777" },
        { "id": "425", "ank": "close", "label": "778" },
        { "id": "426", "ank": "close", "label": "779" },
        { "id": "427", "ank": "close", "label": "780" },
        { "id": "428", "ank": "close", "label": "788" },
        { "id": "429", "ank": "close", "label": "789" },
        { "id": "430", "ank": "close", "label": "790" },
        { "id": "431", "ank": "close", "label": "799" },
        { "id": "432", "ank": "close", "label": "800" },
        { "id": "433", "ank": "close", "label": "880" },
        { "id": "434", "ank": "close", "label": "888" },
        { "id": "435", "ank": "close", "label": "889" },
        { "id": "436", "ank": "close", "label": "890" },
        { "id": "437", "ank": "close", "label": "899" },
        { "id": "438", "ank": "close", "label": "900" },
        { "id": "439", "ank": "close", "label": "990" },
        { "id": "440", "ank": "close", "label": "999" }
    ]
}
