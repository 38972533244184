import axiosInstance from './axiosClient';

const AuthService = {
  login: async (payload: any) => {
    const url = 'api-user-login';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  register: async (payload: any) => {
    const url = 'api-user-registration';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }       
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  fetchUserData: async (payload: any) => {
    const url = 'api-get-profile';
    console.log(payload)
    try {
      const response = await axiosInstance.post(url, payload);
      //post(url,{})
      console.log('User Data:', response.data);
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }
      // console.error('An error occurred:', error.message);
      // Here, you might handle errors coming from the backend
    }
  },
  fetchWalletTransaction: async (payload: any)=>{    
    const url = 'api-wallet-transaction-history';
    console.log(payload)
    try {
      const response = await axiosInstance.post(url, payload);
      //post(url,{})
      console.log('User Data:', response.data);
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }
      // console.error('An error occurred:', error.message);
      // Here, you might handle errors coming from the backend
    }
  },
  changePassword: async (payload: any) => {
    const url = 'api-change-password';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  updateUPIDetails: async (payload: any) => {
    const url = 'api-add-user-upi-details';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  updateBankDetails: async (payload: any) => {
    const url = 'api-add-user-bank-details';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  getBankDetails: async (payload: any) => {
    const url = 'api-get-user-payment-details';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  addPoints: async (payload: any) => {
    const url = 'api-add-money-via-upi';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  walletBalance: async (payload: any) => {
    const url = 'api-get-current-date';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  getWithdrawFund: async (payload: any) => {
    const url = 'api-user-withdraw-transaction-history';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  requestWithdrawFund: async (payload: any) => {
    const url = 'api-user-withdraw-fund-request';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  updateProfile: async (payload: any) => {
    const url = 'api-profile-update';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  checkUserToTransfer: async (payload: any) => {
    const url = 'api-check-user-for-transfer-amt';    
    try {
      const response = await axiosInstance.post(url, payload);  
      // if(response.data.status!=true){
      //   return {
      //     status: 'failed',
      //     res: response.data
      //   }
      // }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  requestAmountTransfer: async (payload: any) => {
    const url = 'api-user-transfer-wallet-balance';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  getOrderList: async (payload: any) => {
    const url = 'get-payment-list';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  updatePaymentStatus: async (payload: any) => {
    const url = 'get-payment-status';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  userManualPayReq: async (payload: any) => {
    const url = 'manual-payment';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  adminPaymentInfo: async (payload: any) => {
    const url = 'manual-payment-data';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
  
  approvePayments: async (payload: any) => {
    const url = 'manual-payment-approval';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  }, 
}

export default AuthService;