import { useEffect, useState } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { Box, Button, Card, CircularProgress, Container, Grid, IconButton, Typography } from "@mui/material";
import { GraphicEq, PlayArrow } from '@mui/icons-material';
import { connect, useDispatch } from 'react-redux';
import { authActions } from '../../store/reducer/auth-reducer';
import { homePageActions } from '../../store/reducer/home';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { ReactComponent as DiceIcon } from '../../assets/onedice.svg';
import { ReactComponent as TwoDice } from '../../assets/twodice.svg';

import { ReactComponent as SinglePan } from '../../assets/singlepanna.svg';
import { ReactComponent as DoublePan } from '../../assets/doublepanna.svg';
import { ReactComponent as TripplePan } from '../../assets/tripplepanna.svg';
import { gameNumbers } from './gameConstants';
import AlertBar from '../../components/AlertBar';
import { starlineActions } from '../../store/reducer/starline';
import { isEmpty } from 'lodash';

const styles: any = {
    iconStyle: {
        height: '32px',
        width: '32px'
    }
}

const incoList: any = {
    'single_digit': { component: <DiceIcon style={styles.iconStyle} />, name: 'Single Digit' },
    'jodi_digit': { component: <TwoDice style={styles.iconStyle} />, name: 'Jodi Digit' },
    'single_pana': { component: <SinglePan style={styles.iconStyle} />, name: 'Single Panna' },
    'double_pana': { component: <DoublePan style={styles.iconStyle} />, name: 'Double Panna' },
    'tripple_pana': { component: <TripplePan style={styles.iconStyle} />, name: 'Tripple Panna' },
    'half_sangam': { component: <div style={{ display: 'flex' }}><DiceIcon style={styles.iconStyle} /><SinglePan style={styles.iconStyle} /></div>, name: 'Half Sangam' },
    'full_sangam': { component: <div style={{ display: 'flex' }}><DiceIcon style={styles.iconStyle} /><TripplePan style={styles.iconStyle} /></div>, name: 'Full Sangam' }
}



const GamePlay = ({ homePageData, gameData, isGameLoading, isCheckGameStatusSuccess, gameStatusData }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { game_id } = useParams();
    const [gameList, setGameList] = useState<any>([])
    const nav = useNavigation()
    const [gameStatusList, setGameStatusList] = useState<any>({ open_time: '', close_time: '' })
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })

    useEffect(() => {
        const gamePayload: any = {
            app_key: "@34Y@#8deepmatka",
            user_id: 27,
            player_id: 27
        };
        dispatch(homePageActions.dashboardDataRequest(gamePayload))
        const pay: any = {
            menuname: 'gamelist'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    useEffect(() => {
        if (!isEmpty(homePageData)) {
            const _homepagedata = homePageData.result.filter((item: any) => item.game_id == game_id)[0];
            const name: any = _homepagedata.game_name
            dispatch(starlineActions.setMenuHeaderName(name))
        }

        const data: any = {
            app_key: "@34Y@#8deepmatka",
            game_id: game_id
        };

        dispatch(homePageActions.gameDataRequest(data))
        dispatch(homePageActions.checkGameStatusReq(data))


    }, [game_id, homePageData])


    useEffect(() => {
        if (isCheckGameStatusSuccess) {
            if (Object.keys(gameStatusData).length > 0) {
                if (checkTime(gameStatusData.open_time, gameStatusData.close_time, 'close')) {
                    navigate('/home')
                } else {
                    setGameStatusList(gameStatusData)
                }
            } else {
                navigate('/home')
            }
        }
    }, [isCheckGameStatusSuccess, gameStatusData])

    useEffect(() => {
        if (Object.keys(gameData).length > 0) {
            let temp: any = []
            gameData.game_rates.forEach((element: any) => {
                let data: any = Object.keys(element);
                let filterdata = data.filter((item: any) => {
                    if (item != 'game_rate_id' && item != 'insert_date') {
                        let searchString = item.split('_')[0] + '_' + item.split('_')[1]
                        if (temp.length) {
                            if (!temp.includes(item.match(searchString)[0])) {
                                temp.push(item.match(searchString)[0])
                            }
                        } else {
                            temp.push(item.match(searchString)[0])
                        }

                    }
                    return item
                });

            });
            setGameList(temp)
        }
    }, [gameData])

    const gotoPlaceBid = (event: any, item: any) => {
        if (item === 'jodi_digit' || item === 'half_sangam' || item === 'full_sangam') {
            if (checkTime(gameStatusData.open_time, gameStatusData.close_time, 'open')) {
                setErrorMessage({ status: true, message: "Bid is closed for today, Session expired.", severity: "error", inputName: '' })
            } else {
                navigate('/play-game/place-bid/' + game_id + '/' + gameNumbers[item])
            }
        } else {
            navigate('/play-game/place-bid/' + game_id + '/' + gameNumbers[item])
        }
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    function checkTime(opentime: any, closetime: any, req: any) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todayDate = mm + '/' + dd + '/' + yyyy;
        const startTimeHours = new Date(todayDate + ' ' + opentime).getHours();
        const startTimeMin = new Date(todayDate + ' ' + opentime).getMinutes()
        const endTimeHours = new Date(todayDate + ' ' + closetime).getHours();
        const endTimeMin = new Date(todayDate + ' ' + closetime).getMinutes()
        var currentD = today;
        var startHappyHourD = new Date();
        startHappyHourD.setHours(startTimeHours, startTimeMin, 0); // 10:00 pm
        var endHappyHourD = new Date();
        endHappyHourD.setHours(endTimeHours, endTimeMin, 0); // 10.00 pm
        if (req === 'open') {
            if (currentD >= startHappyHourD) {
                return true
            } else {
                return false
            }
        } else if (req === 'close') {
            if (currentD >= endHappyHourD) {
                return true
            } else {
                return false
            }
        }
    }

    return (
        <div style={{ flex: 1, width: '100%', marginTop: '0px' }}>
            {isGameLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
            <Container sx={{ paddingLeft: '0px !important', paddingRight: '0px !important', marginTop: '0px !important', backgroundColor: '#f1f5ff' }}>
                <Grid container gap={3} padding={'20px'} sx={{
                    display:'flex',
                    justifyContent:{
                        xs:'center',
                        sm:'center',
                        lg:'flex-start',
                        md:'flex-start'
                    }
                }}>
                    <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                    {gameList.map((item: any) => (<Grid lg={2} md={2} sm={4} xs={5} ><Card onClick={(event: any) => {
                        gotoPlaceBid(event, item)
                    }} sx={{
                        borderRadius: '22px',
                        backgroundColor: '#FFF',
                        boxShadow: '0px 0px 2px #adadad27',
                        minHeight: '80px', cursor: 'pointer', padding: '20px', gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textTransform: 'capitalize', ":hover": {
                            backgroundColor: '#cde6ff',
                            // color:'#FFFF'
                        }
                    }}>
                        {incoList[item]['component']}
                        <Typography variant='h6' sx={{textAlign:'center', color: '#1c274c', fontFamily: 'calibri', fontSize: '1.2rem', fontWeight: '800', lineHeight: 1 }}>{incoList[item]['name']}</Typography>
                    </Card>
                    </Grid>))
                    }
                </Grid>
            </Container>}
        </div>

    )
}

const mapStateToProps = (state: any) => ({
    gameData: state.homepage.gameData,
    isGameLoading: state.homepage.isGameLoading,
    isCheckGameStatusLoading: state.homepage.isCheckGameStatusLoading,
    gameStatusData: state.homepage.gameStatusData,
    isCheckGameStatusFailed: state.homepage.isCheckGameStatusFailed,
    isCheckGameStatusSuccess: state.homepage.isCheckGameStatusSuccess,
    homePageData: state.homepage.homePageData,
})


export default connect(mapStateToProps)(GamePlay);