import axiosInstance from './axiosClient';

const HomeServices = {
    getDashboardData: async (payload: any) => {
        const url = 'api-get-dashboard-data';
        try {
            const response = await axiosInstance.post(url, payload);
            //post(url,{})
            console.log('User Data:', response.data);
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }
            // console.error('An error occurred:', error.message);api-get-slider-images
            // Here, you might handle errors coming from the backend
        }
    },
    getSliderImages: async (payload: any) => {
        const url = 'api-get-slider-images';
        try {
            const response = await axiosInstance.post(url, payload);
            //post(url,{})
            console.log('User Data:', response.data);
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }
            // console.error('An error occurred:', error.message);api-get-slider-images
            // Here, you might handle errors coming from the backend
        }
    },
    getGameData: async (payload: any) => {
        const url = 'api-game-rates';
        try {
            const response = await axiosInstance.post(url, payload);
            //post(url,{})
            console.log('User Data:', response.data);
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }
            // console.error('An error occurred:', error.message);
            // Here, you might handle errors coming from the backend
        }
    },
    getHowToPlayData: async (payload: any) => {
        const url = 'api-how-to-play';
        try {
            const response = await axiosInstance.post(url, payload);
            //post(url,{})
            console.log('User Data:', response.data);
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }
            // console.error('An error occurred:', error.message);
            // Here, you might handle errors coming from the backend
        }
    },
    getContactDetails: async (payload: any) => {
        const url = 'api-get-contact-details';
        try {
            const response = await axiosInstance.post(url, payload);
            //post(url,{})
            console.log('User Data:', response.data);
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }
            // console.error('An error occurred:', error.message);
            // Here, you might handle errors coming from the backend
        }
    },
    checkGameActiveInactive: async (payload: any) => {
        const url = 'api-check-games-active-inactive';
        try {
            const response = await axiosInstance.post(url, payload);
            //post(url,{})
            console.log('User Data:', response.data);
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }
            // console.error('An error occurred:', error.message);
            // Here, you might handle errors coming from the backend
        }
    },
    submitBid: async (payload: any) => {
        const url = 'api-submit-bid';
        try {
            const response = await axiosInstance.post(url, payload);
            //post(url,{})
            console.log('User Data:', response.data);
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }
            // console.error('An error occurred:', error.message);
            // Here, you might handle errors coming from the backend
        }
    },

    

    
    
}

export default HomeServices;