import { Alert, Box, Button, Card, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Input, List, ListItem, ListItemButton, ListItemText, Radio, RadioGroup, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import bankdetails from "../profile/bankdetails";
import GooglePayButton from '@google-pay/button-react';
import axios from 'axios'
import { homePageActions } from "../../store/reducer/home";
import { ArrowDownward, ContentCopy, Refresh } from "@mui/icons-material";
import { get, isEmpty } from "lodash";


const ManualPayment = ({
    isLoginLoading,
    adminPGSetupSuccesss,
    adminPGSetupFailed,
    adminPaymentData,
    userPaymentUploadSuccess,
    userPaymentUploadFailed,
    adminApprovalSuccess,
    adminApprovalFailed
}: any) => {
    const outletData:any = useOutletContext();
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)
    const dispatch = useDispatch();
    const [fileString, setFileString] = useState<any>('');
    const [qrDetails, setQrDetails] = useState<any>({});
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [points, setPoints] = useState<any>('');

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };


    useEffect(() => {
        const payload: any = {
            user_id: finalData.user_id,
            reqtype: 'fetch'
        };
        dispatch(authActions.adminManualPayInfReq(payload))
        
    }, [])

    console.log(outletData.walletBalance.min_withdrawal);

    useEffect(() => {
        if (adminPaymentData.length) {
            setQrDetails(adminPaymentData[0])
        } else {
            setQrDetails([]);
        }

    }, [adminPaymentData])

    function getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleFileChange = (e: any) => {       
        let file = e.target.files[0];
        setFileString('');       
        let filename = file.name;        
        let _ext = filename.split('.').pop();
        if (file) {
            if (_ext.toLowerCase() === 'jpg' || _ext.toLowerCase() === 'png' || _ext.toLowerCase() === 'jpeg') {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded.bind(this);
                reader.readAsBinaryString(file);
            } else {
                setErrorMessage({ status: true, message: "JPEG & PNG is supported.", severity: "warning", inputName: '' })
            }
        } else {
            setErrorMessage({ status: true, message: "Please enter valid file.", severity: "warning", inputName: '' })
        }
    }



    const _handleReaderLoaded = (e: any) => {
        console.log("file uploaded 2: ", e);
        let binaryString = e.target.result;
        setFileString(btoa(binaryString))
    };

    const ontextChange = (e: any) => {
        setPoints(e.target.value)
    }

    const handleSubmit = () => {
        if(fileString===''){
            setErrorMessage({ status: true, message: "Please upload payment receipt screenshot.", severity: "warning", inputName: '' })
        } else if(points===''){
            setErrorMessage({ status: true, message: "Please enter paid amount.", severity: "warning", inputName: '' })
        } else {
            const payload: any = {
                user_id: finalData.user_id,
                order_id: 'ORD' + Date.now(),
                json_req: fileString,
                amount: points,
                reason: 'Payment initiated',
                user_details: JSON.stringify(finalData),
            };            
            dispatch(authActions.userPaymentReq(payload))
        }       
    }

    useEffect(() => {
        if (userPaymentUploadSuccess) {
            setErrorMessage({ status: true, message: "Submitted successfully, please reload the page after sometime.", severity: "success", inputName: '' })
        }

        if (userPaymentUploadFailed) {
            setErrorMessage({ status: true, message: "Failed please try agian", severity: "warning", inputName: '' })
        }
    }, [
        userPaymentUploadSuccess,
        userPaymentUploadFailed,
    ])
    return (<Container>
        {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
            <Grid container display={'flex'} justifyContent={'center'} mb={20}>
                <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: 'none', boxShadow: '0px 0px 2px #adadad27', alignItems: "center", justifyContent: 'center', gap: 2, padding: '15px 15px 15px 15px' }}>

                        {/* {qrDetails['upi_id'] != null && <Typography>UPI ID : {qrDetails['upi_id']}</Typography>}  */}
                        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Grid xs={12} sx={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', textAlign:'center'}}>
                                <Typography sx={{
                                    backgroundColor: '#ffc7c7',
                                    width: '100%',
                                    borderRadius: '15px',
                                    padding: '5px',
                                    color: '#cb1d1d',
                                }}>*Min deposit amout is : {get(outletData,'walletBalance.min_deposite',0)}</Typography>
                                <Typography sx={{
                                         backgroundColor:  '#d4ffc9',
                                         borderRadius: '15px',
                                        padding: '7px',
                                        marginTop: '5px',
                                        width: '100%',
                                        fontSize: '16px'
                                }}>Pay and send the Screenshot with registered mobile number. <br/> Scan & Pay here  </Typography>
                                <ArrowDownward/>
                                </Box>
                                
                                {/* <Typography>{qrDetails.upi_id}</Typography> */}
                                
                                <Box sx={{
                                      height: '200px', width: '100%', img: {
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                        // border: '1px solid grey',
    
                                    }, 
                                }}> {qrDetails['qr_image'] != null && <img src={`data:image;base64,${qrDetails['qr_image']}`} />} </Box>
                                <Typography sx={{textAlign:'center', paddingTop:'10px'}} >{qrDetails.upi_id} <ContentCopy style={{cursor:'pointer', height:'16px', width:'16px'}} onClick={() => { navigator.clipboard.writeText(qrDetails.upi_id); setErrorMessage({ status: true, message: "Copied!!", severity: "success", inputName: '' }) }} /> </Typography>
                            </Grid>
                            <Grid xs={12}>
                                <FormControl sx={{ display: 'flex', width: '100%' }}>
                                    {/* <FormLabel sx={{ marginTop: '15px', marginBottom: '5px', color: '#1c274c' }}>Upload Screenshot</FormLabel> */}
                                    {/* <TextField type="file" onChange={handleFileChange} name='image' id="file" /> */}
                                    <Button
                                        variant="contained"
                                        component="label"
                                        onChange={handleFileChange}
                                        sx={{
                                            marginTop:'5px',
                                            height: '80px',
                                            backgroundColor: 'transparent',
                                            color: '#1c274c',
                                            fontWeight: '600',
                                            boxShadow: 'none',
                                            border: '1px dotted grey',
                                            fontSize: '14px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',                                            
                                            '&:hover': { backgroundColor: 'transparent' }     
                                        }}
                                    >
                                        Upload Payment Screenshot
                                        <span style={{ fontSize: '10px' }}>{fileString != '' ? '1 file selected' : 'No files selected'}</span>
                                        <input
                                            name='image' id="file"
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            hidden
                                        />
                                    </Button>

                                    {/* <FormLabel sx={{ marginTop: '15px', marginBottom: '5px', color: '#1c274c' }}>Paid Amount?</FormLabel> */}
                                    <TextField placeholder="Total paid amount" sx={{ marginTop:'5px', width: "100%", '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }}  type="text" onChange={ontextChange} name='points' id="file" />

                                    <Button  variant="contained" sx={{ width: "100%", marginTop:'10px', boxShadow: '0px 0px 2px #adadad27', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c' } }}  onClick={handleSubmit}>Request to Add Point</Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>}
    </Container>);
}


const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    adminPGSetupSuccesss: state.authentication.adminPGSetupSuccesss,
    adminPGSetupFailed: state.authentication.adminPGSetupFailed,
    adminPaymentData: state.authentication.adminPaymentData,
    userPaymentUploadSuccess: state.authentication.userPaymentUploadSuccess,
    userPaymentUploadFailed: state.authentication.userPaymentUploadFailed,
    adminApprovalSuccess: state.authentication.adminApprovalSuccess,
    adminApprovalFailed: state.authentication.adminApprovalFailed,
})


export default connect(mapStateToProps)(ManualPayment);