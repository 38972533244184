import {call, put, takeLeading} from 'redux-saga/effects'
import { authActions } from '../reducer/auth-reducer';
import HomeServices from '../api/home';
import { homePageActions } from '../reducer/home';


function* dashboardData({payload}:any):any {    
    const res = yield call(HomeServices.getDashboardData, payload);   
    if(res.status==='success'){       
        yield put(homePageActions.dashboardDataResponse(res))
    } else {
        yield put(homePageActions.dashboardDataFailed(res))
    }
}

function* getSliderData({payload}:any):any {    
    const res = yield call(HomeServices.getSliderImages, payload);   
    if(res.status==='success'){       
        yield put(homePageActions.sliderDataRes(res))
    } else {
        yield put(homePageActions.sliderDataFailed(res))
    }
}

// /getSliderImages

function* gameData({payload}:any):any {    
    const res = yield call(HomeServices.getGameData, payload);
    if(res.status==='success'){       
        yield put(homePageActions.gameDataResponse(res))
    } else {
        yield put(homePageActions.gameDataFailed(res))
    }
}

function* getHtpData({payload}:any):any {    
    const res = yield call(HomeServices.getHowToPlayData, payload);
    if(res.status==='success'){       
        yield put(homePageActions.htpDataResponse(res))
    } else {
        yield put(homePageActions.htpDataFailed(res))
    }
}


function* getConData({payload}:any):any {    
    const res = yield call(HomeServices.getContactDetails, payload);
    if(res.status==='success'){       
        yield put(homePageActions.contactDataResponse(res))
    } else {
        yield put(homePageActions.contactDataFailed(res))
    }
}

function* checkStatus({payload}:any):any {    
    const res = yield call(HomeServices.checkGameActiveInactive, payload);
    if(res.status==='success'){       
        yield put(homePageActions.checkGameStatusRes(res))
    } else {
        yield put(homePageActions.checkGameStatusFailed(res))
    }
}

function* submitBid({payload}:any):any {    
    const res = yield call(HomeServices.submitBid, payload);
    if(res.status==='success'){       
        yield put(homePageActions.submitBidRes(res))
    } else {
        yield put(homePageActions.submitBidFailed(res))
    }
}



function* homeSaga(){
    yield takeLeading('homepage/dashboardDataRequest', dashboardData);
    yield takeLeading('homepage/gameDataRequest', gameData);
    yield takeLeading('homepage/htpDataRequest', getHtpData);
    yield takeLeading('homepage/contactDataRequest', getConData);
    yield takeLeading('homepage/checkGameStatusReq', checkStatus);
    yield takeLeading('homepage/submitBidReq', submitBid);
    yield takeLeading('homepage/sliderDataRequest', getSliderData);
    
}

export default homeSaga;