import axios from 'axios';

const axiosInstance =  axios.create({
  baseURL: 'https://saihome.buzz/super/',
//   withCredentials: false,
//   crossDomain: true,
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'app_key': '@34Y@#8deepmatka',
    // 'Access-Control-Allow-Origin' : '*',
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        console.log('call the refresh token api here')
        // Handle 401 error, e.g., redirect to login or refresh token
      }
      return Promise.reject(error)
    },
  )

  export default axiosInstance;