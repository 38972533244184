import { createBrowserRouter } from 'react-router-dom'
import AuthRoute from './AuthRouter'
import ProtectedRoute from './ProtectedRoute'
import Home from '../pages/home'
import Gameplay from '../pages/gameplay'
import Login from '../pages/login'
import Register from '../pages/login/register'
import PlaceBid from '../pages/gameplay/placeBid'
import Profile from '../pages/profile'
import Walletstatement from '../pages/profile/walletstatement'
import GameRates from '../pages/gameplay/gameRates'
import Howtoplay from '../pages/gameplay/howtoplay'
import ChangePassword from '../pages/profile/changePassword'
import Contactdetails from '../pages/profile/contactdetails'
import Upidetails from '../pages/profile/upidetails'
import Bankdetails from '../pages/profile/bankdetails'
import Points from '../pages/points'
import Winninghistory from '../pages/points/winninghistory'
import Bidhistory from '../pages/points/bidhistory'
import WithdrawPoints from '../pages/points/withdrawPoints'
import StarLine from '../pages/starline'
import BidHistory from '../pages/starline/bidhistory'
import WinHistory from '../pages/starline/winhistory'
import GameList from '../pages/starline/gamelist'
import StarLinePlaceBid from '../pages/starline/placebid'
import Transferpoints from '../pages/points/transferpoints'
import ErrorPage from '../components/ErrorPage'
import Layout from '../pages/Layout'
import MakePayment from '../pg'
import PayStatus from '../pg/paystatus'
import ManualPayment from '../pages/points/manualPayment'
import Uploadpgdata from '../pages/admin/uploadpgdata'
import Approvepayments from '../pages/admin/approvepayments'



const RouterConfig = createBrowserRouter([
    {
        element: <AuthRoute />,
        errorElement: <ErrorPage />,
        children: [
            {

                path: '/',
                element: <Home/>
        
            },
            {
                path: '/home',
                element: <Home/>
            },
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/register',
                element: <Register />
            },
            {
                path: '/starline',
                element: <StarLine />
            },
            {
                path: '/game-rates',
                element: <GameRates />
            },
            {
                path: '/how-to-play',
                element: <Howtoplay />
            },
            {
                path: '/contact-us',
                element: <Contactdetails />
            },      
            {
                path: '/approve-payments/:userid/:orderid',
                element: <Approvepayments />
            },    
            {
                path: '/approve-payments',
                element: <Approvepayments />
            },
        ]
    },
    {
        element: <ProtectedRoute />,
        errorElement: <ErrorPage />,
        path: '/',
        children: [
            {
                path: '/home',
                element: <Home/>
            },
            {
                path: '/play-game/:game_id',
                element: <Gameplay />
            },
            {
                path: '/play-game/place-bid/:game_id/:gamename',
                element: <PlaceBid />
            },
            {
                path: '/my-profile',
                element: <Profile />
            },
            // {
            //     path: '/add-point',
            //     element: <Points />
            // },
            {
                path: '/withdraw-points',
                element: <WithdrawPoints />
            },
            {
                path: '/wallet-statement',
                element: <Walletstatement />
            },
            {
                path: '/winning-history',
                element: <Winninghistory />
            },
            {
                path: '/bid-history',
                element: <Bidhistory />
            },
            {
                path: '/manage-bank-details',
                element: <Bankdetails />
            },
            {
                path: '/upi-setting',
                element: <Upidetails />
            },
            {
                path: '/game-rates',
                element: <GameRates />
            },
            {
                path: '/notices',
                element: <Gameplay />
            },
            {
                path: '/how-to-play',
                element: <Howtoplay />
            },
            {
                path: '/contact-us',
                element: <Contactdetails />
            },
            {
                path: '/share-with-friends',
                element: <Gameplay />
            },
            {
                path: '/rate-app',
                element: <Gameplay />
            },
            {
                path: '/change-password',
                element: <ChangePassword />
            },
            {
                path: '/logout',
                element: <Gameplay />
            },
            {
                path: '/starline',
                element: <StarLine />
            },
            {
                path: '/starline/bidhistory',
                element: <BidHistory />
            },
            {
                path: '/starline/winhistory',
                element: <WinHistory />
            },
            {
                path: '/starline/game-list/:game_id',
                element: <GameList />
            },
            {
                path: '/starline/place-bid/:game_id/:gamename',
                element: <StarLinePlaceBid />
            },
            {
                path: '/transfer-points',
                element: <Transferpoints />
            },
            {
                path: '/payment',
                element: <MakePayment />
            },
            {
                path: '/success',
                element: <PayStatus />
            },
            {
                path: '/failed',
                element: <PayStatus />
            },
            {
                path: '/add-point',
                element: <ManualPayment />
            },
            {
                path: '/upload-pg-data',
                element: <Uploadpgdata />
            },
           
        ]
    }
], { basename: "/" })


export default RouterConfig;