import { createSlice } from "@reduxjs/toolkit";

const initalState: any = {    
    isStarLineLoading : false,
    starLineMsg:'',
    starLineData : {},
    isStarLineDataFailed : false,
    starLineGameRateData : {},
    isStarLineGameRateFailed: false,
    starLineGameStatusData: {},
    isStarLineGameStatusFailed: false,
    starLineGameStatusActiveInactiveData: {},
    isStarLineGameStatusActiveInactiveFailed: false,
    isSubmitStarLineBidSuccess :false,
    isSubmitStarLineBidFailed :false,
    starLineBidHistoryData: {},
    isStarLineBidHistoryDataFailed : false,
    starLineWinningHistoryData: {},
    isStarLineWinningHistoryDataFailed : false,
    isStarLineGameStatusActiveInactiveSuccess :false,
    menuName:''
}

const starlineReducer = createSlice({
    name: 'starline',
    initialState: initalState,
    reducers: {
        starLineDataReq : (state)=>{
            state.isStarLineLoading = true;
        },
        starLineDataRes : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.starLineData = action.payload.res;
        },
        starLineDataFailed : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.starLineData = {};
            state.isStarLineDataFailed = true;
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Failed to load, please try again.'
        },
        starLineGameRatesReq : (state)=>{
            state.isStarLineLoading = true;
        },
        starLineGameRatesRes : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.starLineGameRateData = action.payload.res;
        },
        starLineGameRatesFailed : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.isStarLineGameRateFailed = true;
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Failed to load, please try again.'
        },
        starLineGameStatusReq : (state)=>{
            state.isStarLineLoading = true;
        },
        starLineGameStatusRes : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.starLineGameStatusData = action.payload.res;           
        },
        starLineGameStatusFailed : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.isStarLineGameStatusFailed= true;
            state.starLineGameStatusData= {};
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Failed to load, please try again.'
        },
        starLineGameStatusActiveInactiveReq : (state)=>{
            state.isStarLineLoading = true;
        },
        starLineGameStatusActiveInactiveRes : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.isStarLineGameStatusActiveInactiveSuccess = true;
            state.starLineGameStatusActiveInactiveData = action.payload?.res?.result[0];   
        },
        starLineGameStatusActiveInactiveFailed : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.isStarLineGameStatusActiveInactiveFailed = true;
            state.starLineGameStatusActiveInactiveData = {};
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Failed to load, please try again.'
        },
        submitStarLineBidReq : (state)=>{
            state.isStarLineLoading = true;
        },
        submitStarLineBidRes : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.isSubmitStarLineBidSuccess =true;
            state.isSubmitStarLineBidFailed = false;
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Bid submitted successfully.'
        },
        submitStarLineBidFailed : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.isSubmitStarLineBidSuccess =false;
            state.isSubmitStarLineBidFailed = true;
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Failed to bid, please try again.'
        },
        starLineBidHistoryDataReq : (state)=>{
            state.isStarLineLoading = true;
        },
        starLineBidHistoryDataRes : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.starLineBidHistoryData = action.payload.res;
        },
        starLineBidHistoryDataFailed : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.isStarLineBidHistoryDataFailed = true;
            state.starLineBidHistoryData = {}
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Failed to load, please try again.'
        },
        starLineWinningHistoryDataReq : (state)=>{
            state.isStarLineLoading = true;
        },
        starLineWinningHistoryDataRes : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.starLineWinningHistoryData = action.payload.res;
        },
        starLineWinningHistoryDataFailed : (state,action: any)=>{
            state.isStarLineLoading = false;
            state.starLineWinningHistoryData = {};
            state.isStarLineWinningHistoryDataFailed = true;
            state.starLineMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Failed to load, please try again.'
        },
        setMenuHeaderName:(state,action:any)=>{
            state.menuName = action.payload;
        },
        resetStarLIne:(state)=>{
            state.isStarLineLoading = false;
            state.starLineMsg = '';
            state.isStarLineDataFailed = false;
            state.isStarLineGameRateFailed = false;
            state.isStarLineGameStatusFailed= false;
            state.isStarLineGameStatusActiveInactiveFailed = false;
            state.isStarLineBidHistoryDataFailed = false;
            state.isStarLineWinningHistoryDataFailed = false;
            state.isStarLineGameStatusActiveInactiveSuccess = false;
            state.isSubmitStarLineBidSuccess = false;
            state.isSubmitStarLineBidFailed = false;
        }
    }
})

export const starlineActions = starlineReducer.actions;
export default starlineReducer;