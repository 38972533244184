import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const ErrorPage = ()=>{
    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(() => {
            navigate('/home')
        }, 3000);
    },[])
    return(
        <Box sx={{display:'flex', height:'100vh', justifyContent:'center', alignItems:'center'}}>
            <Typography variant="h3">Opps page not found</Typography>
        </Box>
    )
}
export default ErrorPage;