import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
// { status: false, message: '', severity: '', inputName:'' }
const AlertBar = ({errorMessage, handleClose }:any) => {
    const action = (
        <Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );
    return (<Snackbar
        open={errorMessage.status}
        autoHideDuration={3000}
        message={errorMessage.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        action={action}
    >
        <Alert
            onClose={handleClose}
            severity={errorMessage.severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {errorMessage.message}
        </Alert>
    </Snackbar>)
}
export default AlertBar;