import { Alert, Box, Button, Card, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Input, List, ListItem, ListItemButton, ListItemText, Radio, RadioGroup, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import bankdetails from "../profile/bankdetails";
import GooglePayButton from '@google-pay/button-react';
import axios from 'axios'
import { homePageActions } from "../../store/reducer/home";
import { ContentCopy, CopyAll, Refresh } from "@mui/icons-material";
import { isEmpty } from "lodash";


const UploadPgData = ({
    isLoginLoading,
    adminPGSetupSuccesss,
    adminPGSetupFailed,
    adminPaymentData,
    userPaymentUploadSuccess,
    userPaymentUploadFailed,
    adminApprovalSuccess,
    adminApprovalFailed
}: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [fileString, setFileString] = useState<any>('');
    const [upiId, setUpiId] = useState<any>('');
    const [qrDetails, setQrDetails] = useState<any>({});
    const [qrImage, setQRImage] = useState<any>({ fileString: '' });

    useEffect(() => {
        const payload: any = {
            user_id: finalData.user_id,
            reqtype: 'fetch'
        };
        dispatch(authActions.adminManualPayInfReq(payload))
    }, [])

    useEffect(() => {
        if (adminPaymentData.length) {
            setQrDetails(adminPaymentData[0])
        } else {
            setQrDetails([]);
        }
    }, [adminPaymentData])


    // useEffect(()=>{
    //     if(!isEmpty(qrDetails)){      
    // https://www.techiediaries.com/react-formdata-file-upload-multipart-form-tutorial/

    //         const _qrd:any = JSON.parse(qrDetails['qr_image']);
    //         console.log(typeof qrDetails['qr_image'], _qrd)  
    //         // const newit : any = JSON.parse(_qrd)
    //         // setQRImage(newit)
    //         setQRImage({fileString:''});
    //     } else {
    //         setQRImage({fileString:''});
    //     }        
    // },[qrDetails])

    // console.log(qrImage,"qrImage");

    useEffect(() => {
        if (adminPGSetupSuccesss) {
            setErrorMessage({ status: true, message: "Details updated.", severity: "success", inputName: '' })
            const payload: any = {
                user_id: finalData.user_id,
                reqtype: 'fetch'
            };
            dispatch(authActions.adminManualPayInfReq(payload))
        }

        if (adminPGSetupFailed) {
            setErrorMessage({ status: true, message: "Failed to update.", severity: "warning", inputName: '' })
        }
    }, [adminPGSetupSuccesss, adminPGSetupFailed])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    const _handleReaderLoaded = (e: any) => {
        console.log("file uploaded 2: ", e);
        let binaryString = e.target.result;
        setFileString(btoa(binaryString))
    };


    const handleFileChange = (e: any) => {
        let file = e.target.files[0];
        setFileString('');
        let filename = file.name;
        let _ext = filename.split('.').pop();
        if (file) {
            if (_ext.toLowerCase() === 'jpg' || _ext.toLowerCase() === 'png' || _ext.toLowerCase() === 'jpeg') {
                const reader = new FileReader();
                // compress(filename, {
                //     width: 400,
                //     type: 'image/png',
                //     max: 200, // max size
                //     min: 20, // min size
                //     quality: 0.8,
                //   }).then(result => {
                //     console.log(result)
                //   })
                reader.onload = _handleReaderLoaded.bind(this);
                reader.readAsBinaryString(file);
            } else {
                setErrorMessage({ status: true, message: "JPEG & PNG is supported.", severity: "warning", inputName: '' })
            }
        } else {
            setErrorMessage({ status: true, message: "Please enter valid file.", severity: "warning", inputName: '' })
        }
    }



    const ontextChange = (e: any) => {
        setUpiId(e.target.value)
    }

    const handleSubmit = () => {
        if (fileString != '' || upiId != '') {
            const payload: any = {
                user_id: finalData.user_id,
                qr_image: fileString,
                upi_id: upiId,
                qr_status: 0,
                reqtype: 'add'
            };
            // console.log('payload',payload)
            dispatch(authActions.adminManualPayInfReq(payload))
        } else {
            setErrorMessage({ status: true, message: "Please enter required field.", severity: "warning", inputName: '' })
        }
    }
    return (
        <Container sx={{ marginBottom: 5 }}>
            {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                <Grid container display={'flex'} justifyContent={'center'}>
                    <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                    <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', border: 'none', boxShadow: '0px 0px 2px #adadad27', alignItems: "center", justifyContent: 'center', gap: 2, padding: '10px 10px 10px 10px' }}>
                            <Box sx={{
                                height: '200px', width: '100%', img: {
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    // border: '1px solid grey',

                                },

                            }}>
                                {qrDetails.qr_image != '' && <img src={`data:image;base64,${qrDetails.qr_image}`} />}
                            </Box>

                            {/* <CopyToClipboard text={qrDetails.upi_id}
                                onCopy={() => {}}> */}
                            <Typography >{qrDetails.upi_id} <ContentCopy style={{cursor:'pointer', height:'24px', width:'24px'}} onClick={() => { navigator.clipboard.writeText(qrDetails.upi_id); setErrorMessage({ status: true, message: "Copied!!", severity: "success", inputName: '' }) }} /> </Typography>
                            {/* </CopyToClipboard> */}

                            <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid xs={12}>

                                    <FormControl sx={{ display: 'flex', width: '100%' }}>
                                        {/* <FormLabel sx={{ marginTop: '15px', marginBottom: '5px', color: '#1c274c' }}>Upload QR Image</FormLabel> */}
                                        {/* <TextField type="file" onChange={handleFileChange} name='image' id="file" /> */}
                                        <Button
                                            variant="contained"
                                            component="label"
                                            onChange={handleFileChange}
                                            sx={{
                                                height: '100px',
                                                backgroundColor: 'transparent',
                                                color: '#1c274c',
                                                fontWeight: '600',
                                                boxShadow: 'none',
                                                border: '1px dotted grey',
                                                fontSize: '18px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                '&:hover': { backgroundColor: 'transparent' }
                                            }}
                                        >
                                            Upload QR
                                            <span style={{ fontSize: '10px' }}>{fileString != '' ? '1 file selected' : 'No files selected'}</span>

                                            <input
                                                name='image' id="file"
                                                type="file"
                                                accept=".jpg,.jpeg,.png"
                                                hidden
                                            />
                                        </Button>

                                        <FormLabel sx={{ marginTop: '15px', marginBottom: '5px', color: '#1c274c' }}>UPI ID</FormLabel>
                                        <TextField sx={{ width: "100%", '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }} type="text" onChange={ontextChange} name='image' id="file" />

                                        <Button variant="contained" sx={{ marginTop: '10px', width: "100%", boxShadow: '0px 0px 2px #adadad27', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c' } }} onClick={handleSubmit}>Upload Details</Button>
                                    </FormControl>


                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>}
        </Container>
    )
}

const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    adminPGSetupSuccesss: state.authentication.adminPGSetupSuccesss,
    adminPGSetupFailed: state.authentication.adminPGSetupFailed,
    adminPaymentData: state.authentication.adminPaymentData,
    userPaymentUploadSuccess: state.authentication.userPaymentUploadSuccess,
    userPaymentUploadFailed: state.authentication.userPaymentUploadFailed,
    adminApprovalSuccess: state.authentication.adminApprovalSuccess,
    adminApprovalFailed: state.authentication.adminApprovalFailed,
})


export default connect(mapStateToProps)(UploadPgData);