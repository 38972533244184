import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { homePageActions } from "../../store/reducer/home";
import { Autocomplete, Box, Button, Card, CircularProgress, Container, Divider, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import { gameNameList, gameNumberInputList } from "./gameConstants";
import AlertBar from "../../components/AlertBar";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Close, Delete } from "@mui/icons-material";
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import CustomModal from "../../components/CustomModal";
import { authActions } from "../../store/reducer/auth-reducer";
import { starlineActions } from "../../store/reducer/starline";

const formFields: any = {
    "1": {
        date: { label: "Choose Date", field: 'date', value: '', placeholder: '' },
        session: { label: "Choose Session", field: 'radio', value: ['Open', 'Close'] },
        open: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        close: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        points: { label: "Point", field: 'text', value: '', placeholder: 'Enter points' },
    },
    "2": {
        date: { label: "Choose Date", field: 'date', value: '' },
        open: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        points: { label: "Point", field: 'text', value: '', placeholder: 'Enter points' },
    },
    "3": {
        date: { label: "Choose Date", field: 'date', value: '', placeholder: '' },
        session: { label: "Choose Session", field: 'radio', value: ['Open', 'Close'] },
        open: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        close: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        points: { label: "Point", field: 'text', value: '', placeholder: 'Enter points' },
    },
    "4": {
        date: { label: "Choose Date", field: 'date', value: '', placeholder: '' },
        session: { label: "Choose Session", field: 'radio', value: ['Open', 'Close'] },
        open: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        close: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        points: { label: "Point", field: 'text', value: '', placeholder: 'Enter points' },
    },
    "5": {
        date: { label: "Choose Date", field: 'date', value: '', placeholder: '' },
        session: { label: "Choose Session", field: 'radio', value: ['Open', 'Close'] },
        open: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        close: { label: "Digits", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        points: { label: "Point", field: 'text', value: '', placeholder: 'Enter points' },
    },
    "6": {
        date: { label: "Choose Date", field: 'date', value: '' },
        session: { label: "Choose Session", field: 'radio', value: ['Open', 'Close'] },
        open: { label: "Open", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        close: { label: "Close", field: 'autocomplete', value: '', placeholder: 'Enter digits' },
        points: { label: "Enter Points", field: 'text', value: '', placeholder: 'Enter points' },
    },
    "7": {
        date: { label: "Choose Date", field: 'date', value: '' },
        open: { label: "Open Panna", field: 'autocomplete', value: '', placeholder: 'Enter close panna' },
        close: { label: "Close Panna", field: 'autocomplete', value: '', placeholder: 'Enter open panna' },
        points: { label: "Enter Points", field: 'text', value: '', placeholder: 'Enter points' },
    },
}

const PlaceBid = ({ homePageData, gameData, isGameLoading, gameStatusData, isSubmitBidSuccess, gameStatusMsg, isSubmitBidFailed }: any) => {
    const outletdata: any = useOutletContext();
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession);
    const { gamename } = useParams<any>();
    const pageParams = useParams<any>();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [date_from, setDateFrom] = useState<Dayjs | null>(dayjs(new Date()));
    const [statusData, setStatusData] = useState<any>({ open_time: '', close_time: '' })
    const [routeData, setRouteData] = useState<any>({})
    const [payloadData, setPayload] = useState<any>({})
    const [gameRates, setGameRates] = useState<any>({
        "single_digit_val_1": "",
        "single_digit_val_2": "",
        "jodi_digit_val_1": "",
        "jodi_digit_val_2": "",
        "single_pana_val_1": "",
        "single_pana_val_2": "",
        "double_pana_val_1": "",
        "double_pana_val_2": "",
        "tripple_pana_val_1": "",
        "tripple_pana_val_2": "",
        "half_sangam_val_1": "",
        "half_sangam_val_2": "",
        "full_sangam_val_1": "",
        "full_sangam_val_2": "",
    })

    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })

    const [openInputValue, setOpenInputValue] = useState('');
    const [closeInputValue, setCloseInputValue] = useState('');

    const [formData, setFormData] = useState<any>({
        session: 'Open',
        open: openInputValue,
        close: closeInputValue,
        points: ''
    })


    const [resultData, setResultData] = useState<any>([])
    const dispatch = useDispatch();

    useEffect(() => {
        const gamePayload: any = {
            app_key: "@34Y@#8deepmatka",
            user_id: 27,
            player_id: 27
        };
        dispatch(homePageActions.dashboardDataRequest(gamePayload))
        const pay: any = {
            menuname: 'placebud'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    useEffect(() => {
        const _gamename: any = gamename;
        const name: any = gameNameList[_gamename]
        dispatch(starlineActions.setMenuHeaderName(name));
    }, [gamename])

    useEffect(() => {

        const data: any = {
            app_key: "@34Y@#8deepmatka",
            game_id: pageParams.game_id
        };
        dispatch(homePageActions.gameDataRequest(data))
        dispatch(homePageActions.checkGameStatusReq(data))
        setRouteData(pageParams)
    }, [pageParams])

    useEffect(() => {
        if (gameStatusData) {
            setStatusData(gameStatusData);
            if (checkTime(gameStatusData.open_time, gameStatusData.close_time, 'open')) {
                setFormData((prev: any) => {
                    return { ...prev, session: 'Close' }
                })
            } else {
                setFormData((prev: any) => {
                    return { ...prev, session: 'Open' }
                })
            }
        }
    }, [gameStatusData])

    useEffect(() => {
        if (gameData) {
            if (Object.keys(gameData).length > 0) {
                setGameRates(gameData?.game_rates[0])
            }

        }
    }, [gameData])

    const handleChange = (event: any, name: any) => {
        // console.log("event.target.value", event.target.value, name)
        // if (event.target.value === 'open' || event.target.value === 'close') {
        //     setOpenInputValue('');
        //     // setCloseInputValue('');
        //     // setFormData((prev: any) => {
        //     //     return { ...prev, [name]: event.target.value}
        //     // })
        // } else {
            setFormData((prev: any) => {
                return { ...prev, [name]: event.target.value }
            })
        // }

    }


    useEffect(() => {

        const keys = Object.keys(gameRates);
        if (keys.length > 0) {
            console.log(keys, gameNameList[routeData.gamename])
            let temp: any = [];
            console.log(gameNameList[routeData.gamename])
            if (gameNameList[routeData.gamename]) {
                keys.forEach((item: any) => {
                    if (item.search(gameNameList[routeData.gamename].replace(' ', '_').toLowerCase()) != -1) {
                        temp.push(gameRates[item])
                    }
                })
            }

            console.log(temp)
        }
    }, [gameData, gameRates])

    const submitBid = () => {
       let req = gameNumberInputList[gameidname].some((item:any)=>item.label == openInputValue);
       console.log(req,'is present')

        if (formData.session != '') {
            if (openInputValue != '' || closeInputValue != '') {
                if (formData.points >= outletdata['walletBalance']['min_bid_amount']) {
                    if (parseInt(formData.points) > parseInt(outletdata['walletBalance']['wallet_amt'])) {
                        setErrorMessage({ status: true, message: `You do not have sufficient balance.`, severity: "error", inputName: '' })
                    } else {
                        const _new_res: any = {
                            points: formData.points,
                            session: formData.session,
                            digits: openInputValue,
                            closedigits: closeInputValue,
                        }

                        if (resultData.length) {
                            const _res: any = [...resultData, _new_res]
                            setResultData(_res)
                        } else {
                            setResultData([{
                                points: formData.points,
                                session: formData.session,
                                digits: openInputValue,
                                closedigits: closeInputValue,
                            }])
                        }
                    }
                } else {
                    setErrorMessage({ status: true, message: `Minimum bid point is ${outletdata['walletBalance']['min_bid_amount']}`, severity: "error", inputName: '' })
                }
            } else {
                setErrorMessage({ status: true, message: `Please enter valid digits.`, severity: "error", inputName: '' })
            }
        } else {
            setErrorMessage({ status: true, message: `Please select session.`, severity: "error", inputName: '' })
        }
    }

    useEffect(() => {
        if (checkTime(statusData.open_time, statusData.close_time, 'open')) {
            setFormData((prev: any) => {
                return { ...prev, session: 'Close', open: '', points: '' }
            })
        } else {
            setFormData((prev: any) => {
                return { ...prev, session: 'Open', open: '', points: '' }
            })
        }
        setOpenInputValue('');
        setCloseInputValue('');
    }, [resultData])

    const submitGame = () => {
        const _homepagedata = homePageData.result.filter((item: any) => item.game_id == routeData.game_id)[0];
        const payload: any = {
            app_key: "@34Y@#8deepmatka",
            new_result: {
                gameid: routeData.game_id,
                user_id: finalData.user_id,
                Gamename: _homepagedata.game_name,
                pana: gameNameList[routeData.gamename],
                totalbit: resultData.reduce((n: any, { points }: any) => parseInt(n) + parseInt(points), 0),
                session: resultData[0]['session'],
                result: resultData,
                bid_date: date_from
            }
        }
        setPayload({
            gameid: routeData.game_id,
            user_id: finalData.user_id,
            Gamename: _homepagedata.game_name,
            pana: gameNameList[routeData.gamename],
            totalbit: resultData.reduce((n: any, { points }: any) => parseInt(n) + parseInt(points), 0),
            session: resultData[0]['session'],
            bid_date: date_from
        })
        handleModalOpen();
    }

    const submitGameFinal = () => {
        const _homepagedata = homePageData.result.filter((item: any) => item.game_id == routeData.game_id)[0];
        const payload: any = {
            app_key: "@34Y@#8deepmatka",
            new_result: {
                gameid: routeData.game_id,
                user_id: finalData.user_id,
                Gamename: _homepagedata.game_name,
                pana: gameNameList[routeData.gamename],
                totalbit: resultData.reduce((n: any, { points }: any) => parseInt(n) + parseInt(points), 0),
                session: resultData[0]['session'],
                result: resultData,
                bid_date: date_from
            }
        }

        dispatch(homePageActions.submitBidReq(payload))
        setResultData([]);
        handleModalClose();
    }

    useEffect(() => {
        if (isSubmitBidSuccess) {
            setPayload({})
            setErrorMessage({ status: true, message: `${gameStatusMsg} redirecting to homepage.`, severity: "success", inputName: '' })
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": finalData.user_id }
            dispatch(authActions.getWalletBalanceReq(data))
            setTimeout(() => {
                navigate('/home')
            }, 2000);
        }

        if (isSubmitBidFailed) {
            setPayload({})
            setErrorMessage({ status: false, message: gameStatusMsg, severity: "error", inputName: '' })
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": finalData.user_id }
            dispatch(authActions.getWalletBalanceReq(data))
        }

        return (() => {
            dispatch(homePageActions.resetLoadingState())
        })
    }, [isSubmitBidSuccess, isSubmitBidFailed])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    const removeBid = (i: any) => {
        setResultData(resultData.filter((item: any, index: number) => {
            return i != index
        }))
    }

    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);

    function checkTime(opentime: any, closetime: any, req: any) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todayDate = mm + '/' + dd + '/' + yyyy;
        const startTimeHours = new Date(todayDate + ' ' + opentime).getHours();
        const startTimeMin = new Date(todayDate + ' ' + opentime).getMinutes()
        const endTimeHours = new Date(todayDate + ' ' + closetime).getHours();
        const endTimeMin = new Date(todayDate + ' ' + closetime).getMinutes()
        var currentD = today;
        var startHappyHourD = new Date();
        startHappyHourD.setHours(startTimeHours, startTimeMin, 0); // 10:00 pm
        var endHappyHourD = new Date();
        endHappyHourD.setHours(endTimeHours, endTimeMin, 0); // 10.00 pm

        if (req === 'open') {
            if (currentD >= startHappyHourD) {
                return true
            } else {
                return false
            }
        } else if (req === 'close') {
            if (currentD >= endHappyHourD) {
                return true
            } else {
                return false
            }
        }
    }
    const gameidname: any = gamename;
// console.log(openInputValue,closeInputValue)
    return (
        <Container >
            {isGameLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :


                <Grid container gap={2}>
                    <Grid lg={6} md={6} sm={12} xs={12} sx={{ marginBottom: '50px' }} >
                        <Card sx={{
                            padding: '32px', gap: '2', boxShadow: '0px 0px 2px #adadad27',
                            border: '0px solid transparent'
                        }}>
                            {Object.keys(formFields[gameidname]).map((item: any, index: number) => (
                                <FormControl sx={{ display: 'flex', color: '#1c274c' }}>
                                    {/* <h2>{formData['session'].toLowerCase()} - {item}</h2> */}

                                    {(formFields[gameidname][item]['field'] === 'text') ?
                                        <><FormLabel sx={{ color: '#1c274c', marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>{formFields[gameidname][item]['label']}</FormLabel>
                                            <TextField sx={{
                                                '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }, width: "100%"
                                            }} name={item} variant="outlined" value={formData[item]} onChange={(event: any) => handleChange(event, item)} /></> :
                                        (formFields[gameidname][item]['field'] === 'date') ? <><FormLabel sx={{ color: '#1c274c', marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>{formFields[gameidname][item]['label']}</FormLabel>
                                            <MobileDatePicker sx={{ '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }, width: '100%' }} name="date_from" disabled={true} value={date_from} /></> :

                                            (formFields[gameidname][item]['field'] === 'autocomplete') ?
                                                (gameidname === '6') ?
                                                    <>
                                                        <FormLabel sx={{ color: '#1c274c', marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>
                                                            {formFields[gameidname][item]['label']} {
                                                                (formData['session'].toLowerCase() === 'open') ? item === 'open' ? "Digits" : 'Panna' : item === 'open' ? "Panna" : 'Digits'}
                                                        </FormLabel>
                                                        <Autocomplete
                                                            key={'gamenosix'}
                                                            isOptionEqualToValue={(option, value) => option.valueOf === value.valueOf}
                                                            value={(item === 'open') ? openInputValue : closeInputValue}
                                                            inputValue={(item === 'open') ? openInputValue : closeInputValue}
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            clearOnBlur={true}
                                                            options={gameNumberInputList[gameidname].filter((listitem: any) => {
                                                                if (gameidname) {
                                                                    if (gameidname == 6 || gameidname == 7) {
                                                                        if (formData['session'].toLowerCase() === 'open') {
                                                                            if (item === 'open') {
                                                                                return listitem.ank == 'open'
                                                                            } else {
                                                                                return listitem.ank == 'close'
                                                                            }

                                                                        } else {
                                                                            if (item === 'open') {
                                                                                return listitem.ank == 'close'
                                                                            } else {
                                                                                return listitem.ank == 'open'
                                                                            }
                                                                        }
                                                                        // return listitem.ank == item
                                                                    }
                                                                    return listitem
                                                                }
                                                                return listitem
                                                            })}
                                                            sx={{ width: '100%', '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }}
                                                            onInputChange={(event, newopenInputValue, reason) => {                                                                
                                                                if (item === 'open') { setOpenInputValue(newopenInputValue as string); } else { setCloseInputValue(newopenInputValue as string); }                                                                
                                                            }}


                                                            disabled={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') : false}
                                                            renderInput={(params) => <TextField {...params} InputProps={{
                                                                ...params.InputProps,                                                                
                                                            }}
                                                                placeholder={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') ? 'Select session' : '' : ''} />}
                                                            />
                                                    </> :
                                                    (gameidname === '7') ?
                                                        <>
                                                            <FormLabel sx={{ color: '#1c274c', marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>
                                                                {formFields[gameidname][item]['label']}                                                               
                                                            </FormLabel>
                                                            <Autocomplete
                                                                key={'gamenoseven'}
                                                                disablePortal
                                                                id="combo-box-demo"
                                                                clearOnBlur={true}
                                                                options={gameNumberInputList[gameidname].filter((listitem: any) => {
                                                                    if (gameidname) {
                                                                        if (gameidname == 6 || gameidname == 7) {
                                                                            return listitem.ank == item
                                                                        }
                                                                        return listitem
                                                                    }
                                                                    return listitem
                                                                })}
                                                                sx={{ width: '100%', '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }}
                                                                onInputChange={(event, newopenInputValue, reason) => {                                                                    
                                                                    if (item === 'open') { setOpenInputValue(newopenInputValue); } else { setCloseInputValue(newopenInputValue); }                                                                    
                                                                }}
                                                                disabled={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') : false}
                                                                renderInput={(params) => <TextField {...params} InputProps={{
                                                                    ...params.InputProps,
                                                                    // type: 'search',
                                                                }}
                                                                    placeholder={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') ? 'Select session' : '' : ''} />}
                                                                                                      
                                                                inputValue={(item === 'open') ? openInputValue : closeInputValue}

                                                            />
                                                        </>
                                                        :
                                                        (formData['session'].toLowerCase() == item) ?
                                                            <>
                                                                <FormLabel sx={{ color: '#1c274c', marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>
                                                                    {formFields[gameidname][item]['label']}
                                                                </FormLabel>
                                                                <Autocomplete
                                                                    key={'gamenoeight'}
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    clearOnBlur={true}
                                                                    options={gameNumberInputList[gameidname].filter((item: any) => {
                                                                        if (gameidname) {
                                                                            if (gameidname == 6 || gameidname == 7) {
                                                                                return item.ank === formData['session'].toLowerCase()
                                                                            }
                                                                            return item
                                                                        }
                                                                        return item
                                                                    })}
                                                                    sx={{ width: '100%', '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }}
                                                                    onInputChange={(event, newopenInputValue, reason) => {                                                                        
                                                                        if (item === 'open') { setOpenInputValue(newopenInputValue); } else { setCloseInputValue(newopenInputValue); }                                                                        
                                                                    }}
                                                                    disabled={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') : false}
                                                                    renderInput={(params) => <TextField {...params} InputProps={{
                                                                        ...params.InputProps,
                                                                        // type: 'search',
                                                                    }}
                                                                        placeholder={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') ? 'Select session' : '' : ''} />}
                                                                                                         
                                                                    inputValue={(item === 'open') ? openInputValue : closeInputValue}

                                                                />
                                                            </>
                                                            :
                                                            <></>
                                                :
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    sx={{ marginTop: '10px' }}
                                                >
                                                    {formFields[gameidname][item]['value'].map((keys: any) => (
                                                        <FormControlLabel sx={{ color: '#1c274c', }} disabled={(checkTime(statusData.open_time, statusData.close_time, 'open') && keys === 'Open') || (checkTime(statusData.open_time, statusData.close_time, 'close') && keys === 'Close')} value={keys} control={<Radio checked={formData[item] === keys} onChange={(event: any) => handleChange(event, item)} />} label={keys} />))
                                                    }

                                                </RadioGroup>
                                    }
                                </FormControl>
                            ))}
                            <Button variant="contained" sx={{ marginTop: '15px', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c', } }} onClick={submitBid}>Add Bid</Button>
                            {resultData.length ?
                                <>
                                    <List sx={{ width: '100%' }}>
                                        {resultData.map((item: any, index: number) => (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                                        <ListItemText sx={{ alignItems: 'center',  padding: 0 }}>
                                                            <Grid container>
                                                                <Grid lg={10} md={10} xs={10} sm={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', color: '#1c274c', justifyContent: 'flex-start', alignItems: 'center' }}>

                                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.points}Point</Typography>
                                                                        <div style={{ height: '18px', width: '1px', backgroundColor: '#f1f5ff', marginLeft: '10px', marginRight: '10px' }}></div>
                                                                        {(gameidname === '2' || gameidname === '7') ? '' : <Typography sx={{ fontSize: '0.8rem' }}>{item.session} </Typography>}
                                                                        <div style={{ height: '18px', width: '1px', backgroundColor: '#f1f5ff', marginLeft: '10px', marginRight: '10px' }}></div>
                                                                        <Typography sx={{ fontSize: '0.8rem' }}>Digit : {(item.closedigits === 'Open') ? item.digits : item.closedigits} {(item.closedigits!='') ? ':'+ item.closedigits : ''}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid lg={2} md={2} xs={2} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><IconButton>
                                                                    <Close sx={{ color: '#1c274c' }} onClick={() => removeBid(index)} />
                                                                </IconButton></Grid>
                                                            </Grid>
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        ))}
                                    </List>
                                    <Button variant="contained" sx={{ marginTop: '15px', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c', } }} onClick={submitGame}>Play Game</Button>

                                </> : ''
                            }
                        </Card>
                    </Grid>
                    <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                    <CustomModal open={open} handleClose={handleModalClose} customstyle={{ width: { xs: '300px', lg: '400px', sm: '300px', md: '350px' }, backgroundColor: '#FFF', padding: '16px' }} >
                        <Box>
                            <List sx={{ width: '100%' }}>

                                <ListItem disablePadding>
                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                        <ListItemText>
                                            <Grid container>
                                                <Grid lg={12} display={'flex'}>
                                                    <Typography>Session</Typography> : <Typography sx={{ marginLeft: '10px', fontWeight: 600 }}>{payloadData['session']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                        <ListItemText>
                                            <Grid container>
                                                <Grid lg={12} display={'flex'}>
                                                    <Typography>Total Points</Typography> :
                                                    <Typography sx={{ marginLeft: '10px', fontWeight: 600 }}>{payloadData['totalbit']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                        <ListItemText>
                                            <Grid container>
                                                <Grid lg={8} display={'flex'}>
                                                    <Typography>Wallet Balance</Typography> :
                                                    <Typography sx={{ marginLeft: '10px', fontWeight: 600 }}>{outletdata['walletBalance']['wallet_amt']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ paddingLeft: '0px', paddingRight: '0px', padding: 0 }}>
                                        <ListItemText>
                                            <Grid container>
                                                <Grid lg={8} display={'flex'}>
                                                    <Typography>Remaining Balance After Bid</Typography> :
                                                    <Typography sx={{ marginLeft: '10px', fontWeight: 600 }}>{parseInt(outletdata['walletBalance']['wallet_amt']) - parseInt(payloadData['totalbit'])}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>

                            </List>
                            <Button variant="contained" sx={{ marginTop: '15px', backgroundColor: '#1c274c', ':hover': { backgroundColor: '#1c274c', } }} onClick={submitGameFinal}>Submit Game</Button>
                            <Button variant="contained" color='warning' sx={{ marginTop: '15px', marginLeft: '10px' }} onClick={handleModalClose}>Cancel</Button>
                        </Box>
                    </CustomModal>
                </Grid>
            }
        </Container>
    )
}

const mapStateToProps = (state: any) => ({
    gameData: state.homepage.gameData,
    homePageData: state.homepage.homePageData,
    isGameLoading: state.homepage.isGameLoading,
    gameStatusData: state.homepage.gameStatusData,
    isSubmitBidSuccess: state.homepage.isSubmitBidSuccess,
    isSubmitBidFailed: state.homepage.isSubmitBidFailed,
    gameStatusMsg: state.homepage.gameStatusMsg,
})


export default connect(mapStateToProps)(PlaceBid);


// (formData['session'].toLowerCase() == item) ?
//                                             <><FormLabel sx={{ marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>
//                                                 {(gameidname==='6') ? (formData['session'].toLowerCase() === 'open') ? "Open digits" : "Open panna" : (gameidname === '7') ? "Close panna"  : formFields[gameidname][item]['label']}
//                                             </FormLabel>
//                                                 <Autocomplete
//                                                     disablePortal
//                                                     id="combo-box-demo"
//                                                     clearOnBlur={true}
//                                                     options={gameNumberInputList[gameidname].filter((item: any) => {
//                                                         if (gameidname) {
//                                                             if (gameidname == 6 || gameidname == 7) {
//                                                                 return item.ank === formData['session'].toLowerCase()
//                                                             }
//                                                             return item
//                                                         }
//                                                         return item
//                                                     })}
//                                                     sx={{ width: '100%' }}
//                                                     onInputChange={(event, newopenInputValue) => {
//                                                         if (item === 'open') { setOpenInputValue(newopenInputValue); } else { setCloseInputValue(newopenInputValue); }
//                                                     }}
//                                                     disabled={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') : false}
//                                                     renderInput={(params) => <TextField {...params} InputProps={{
//                                                         ...params.InputProps,
//                                                         // type: 'search',
//                                                     }}
//                                                         placeholder={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') ? 'Select session' : '' : ''} />}

//                                                     // disableClearable
//                                                     // freeSolo
//                                                     inputValue={(item === 'open') ? openInputValue : closeInputValue}

//                                                 /></> : (gameidname === '6' || gameidname === '7') ? <><FormLabel sx={{ marginTop: (index > 0) ? '15px' : '5px', marginBottom: '5px' }}>
//                                                     {(gameidname==='6') ? (formData['session'].toLowerCase() === 'open') ? "Close panna" : "Close digits" : (gameidname === '7') ? "Close panna"  : formFields[gameidname][item]['label']}
//                                                 </FormLabel>
//                                                     <Autocomplete
//                                                         disablePortal
//                                                         id="combo-box-demo"
//                                                         clearOnBlur={true}
//                                                         options={gameNumberInputList[gameidname].filter((item: any) => {
//                                                             if (gameidname) {
//                                                                 if (gameidname == 6 || gameidname == 7) {
//                                                                     return item.ank === formData['session'].toLowerCase()
//                                                                 }
//                                                                 return item
//                                                             }
//                                                             return item
//                                                         })}
//                                                         sx={{ width: '100%' }}
//                                                         onInputChange={(event, newopenInputValue) => {
//                                                             if (item === 'open') { setOpenInputValue(newopenInputValue); } else { setCloseInputValue(newopenInputValue); }
//                                                         }}
//                                                         disabled={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') : false}
//                                                         renderInput={(params) => <TextField {...params} InputProps={{
//                                                             ...params.InputProps,
//                                                             // type: 'search',
//                                                         }}
//                                                             placeholder={(gameidname == 6 || gameidname == 7) ? (formData['session'] === '') ? 'Select session' : '' : ''} />}

//                                                         // disableClearable
//                                                         // freeSolo
//                                                         inputValue={(item === 'open') ? openInputValue : closeInputValue}

//                                                     /></> : ''