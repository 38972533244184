

import { Box, Button, Card, Grid } from "@mui/material";

import { MobileDatePicker } from "@mui/x-date-pickers";

const RangeDatepicker = ({ date_from, date_to, setDateFrom, setDateTo, handleClick }: any) => {
    return (
    <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Card sx={{borderBottomLeftRadius:0,borderBottomRightRadius:0, border:'none', boxShadow: '0px 0px 2px #adadad27', display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center',  gap: 2, padding: '30px 30px 30px 30px' }}>          
            <MobileDatePicker sx={{width: '100%', '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' },'.MuiInputLabel-root':{color:'#1c274c'} }} label="From Date" name="date_from" value={date_from} onChange={(newValue) => setDateFrom(newValue)} />
            <MobileDatePicker sx={{width: '100%', '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c'},'.MuiInputLabel-root':{color:'#1c274c'}}} label="To Date" name="date_to" value={date_to} onChange={(newValue) => setDateTo(newValue)} />
            <Button variant="contained" sx={{width:"100%",boxShadow: '0px 0px 2px #adadad27', backgroundColor:'#1c274c', ':hover':{backgroundColor:'#1c274c'}}} onClick={handleClick}>Search</Button>
        </Card>
    </Grid>)
}

export default RangeDatepicker;