import { Alert, Box, Button, Card, CircularProgress, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import { homePageActions } from "../../store/reducer/home";
const defLogin: any = { app_key: "@34Y@#8deepmatka", mobile: '', password: '' }

const Login = ({ isAuthenticated, isPageLoading, userData, isLoginFailed, pageMessage, isLoginSuccess }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [loginData, setLoginData] = useState<any>(defLogin)

    useEffect(()=>{
        const pay: any = {
            menuname: 'starline'
        }
        dispatch(homePageActions.selectedMenu(pay))
    },[])

    const handleOnChange = (event: any) => {
        setLoginData((prev: any) => {
            return { ...prev, [event.target.name]: event.target.value }
        })
    }
    const handleLogin = (event: any) => {
        event.stopPropagation();
        if (loginData.mobile.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid userid or mobile number", severity: 'warning', inputName: 'mobile' })
        } else if (loginData.password.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid password", severity: 'warning', inputName: 'password' })
        } else {
            setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
            dispatch(authActions.checkLogin(loginData))
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            sessionStorage.setItem('isAuthenticated', isAuthenticated);
            sessionStorage.setItem('userData', JSON.stringify(userData));
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": userData.user_id }
            dispatch(authActions.getUserDataReq(data))
            dispatch(authActions.getWalletBalanceReq(data))
            navigate('/')
        } else {
            if (sessionStorage.getItem('isAuthenticated')) {
                navigate('/')
                const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": userData.user_id }
                dispatch(authActions.getUserDataReq(data))
                dispatch(authActions.getWalletBalanceReq(data))
            } else {
                navigate('/login')                
            }
        }
    }, [isAuthenticated])

    useEffect(() => {
       
        if (isLoginSuccess) {
            setLoginData(defLogin)
            setErrorMessage({ status: true, message: pageMessage, severity: 'success', inputName: '' })
        }

        if (isLoginFailed) {
            setLoginData(defLogin)
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }
       
    }, [isLoginSuccess, isLoginFailed])

    console.log('logindata',loginData)

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    const gotoRegister = () => {
        navigate('/register')
    }

    return (
        <Grid container>
            <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
            <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              
                    <Card sx={{ display: 'flex',boxShadow: '0px 0px 2px #adadad27',border:0, flexDirection: 'column',alignItems:"center", justifyContent: 'center',  gap: 2, padding: '30px' }}>
                        <TextField sx={{ width: "100%" }} label="Mobile Number" name="mobile" variant="outlined" value={loginData.mobile} type="text" error={(errorMessage.inputName === 'mobile') ? errorMessage.status ? errorMessage.message : '' : ''} helperText={(errorMessage.inputName === 'mobile') ? errorMessage.status ? errorMessage.message : '' : ''} onChange={handleOnChange} />
                        <TextField sx={{ width: "100%" }} label="Password" name="password" variant="outlined" value={loginData.password} type="password" error={(errorMessage.inputName === 'password') ? errorMessage.status ? errorMessage.message : '' : ''} helperText={(errorMessage.inputName === 'password') ? errorMessage.status ? errorMessage.message : '' : ''} onChange={handleOnChange} />
                        <Button variant="contained" sx={{width:"100%",boxShadow: '0px 0px 2px #adadad27', backgroundColor:'#1c274c', ':hover':{backgroundColor:'#1c274c'}}} onClick={handleLogin}>Login</Button>
                        {isPageLoading && <CircularProgress />}
                        <Typography sx={{ display: 'flex', width: "100%", flexDirection: "row", color:'#1c274c', alignItems: 'center', justifyContent: 'center' }}>Don't have an account? &nbsp;<Typography sx={{ color:'#1c274c', fontWeight: 'bold', cursor: 'pointer' }} color='primary' onClick={gotoRegister}> Register here </Typography></Typography>
                    </Card>               
            </Grid>
        </Grid>
    )
}



const mapStateToProps = (state: any) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    isPageLoading: state.authentication.isPageLoading,
    userData: state.authentication.userData,
    isLoginFailed: state.authentication.isLoginFailed,
    pageMessage: state.authentication.pageMessage,
    isLoginSuccess: state.authentication.isLoginSuccess,    
})


export default connect(mapStateToProps)(Login);
