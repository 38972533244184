import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Layout from "../pages/Layout";
import Login from "../pages/login";
import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";


const ProtectedRoute = ({ userData, isAuthenticated }: any) => {   
    // return (sessionStorage.getItem('isAuthenticated') ? <Layout /> : <Navigate to="/login" />)
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        const userToken = sessionStorage.getItem('isAuthenticated');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
    
    return (
        <Fragment>
            {
                isLoggedIn ? <Layout/> : null
            }
        </Fragment>
    );
}



const mapStateToProps = (state: any) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    userData: state.authentication.userData,
})


export default connect(mapStateToProps)(ProtectedRoute);
