import { Box, Card, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { get } from 'lodash';


const ReportList = ({ data, loading, type, page }: any) => {
    const colWidth: any = {
        amount: '120px',
        amount_status: '100px',
        session: '120px',
        closedigits: '100px',
        digits: '120px',
        game_name: '300px',
        pana: '300px',
        transaction_type: '120px',
        tx_request_number: '120px',
        wining_date: '100%',
        transaction_note: '100%',
    }

    const bidCols: any = {
        bid_date: '',
        bid_tx_id: '',
        closedigits: '',
        digits: '',
        game_name: '',
        pana: '',
        points: '',
        session: '',
    }


    return (
        <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
            <Card sx={{
                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 0, border: 'none', boxShadow: '0px 0px 2px #adadad27', display: 'flex',
                marginBottom: '5px', flexDirection: 'column', alignItems: "center", justifyContent: 'center', gap: 2, padding: '0px 15px 30px 15px'
            }}>
                {loading && <CircularProgress />}
                {(data && data.length) ? <List sx={{ width: '100%', maxHeight: '400px', overflowY: 'scroll', overflowX: 'none' }}>
                    {data.map((item: any) => (
                        <>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ padding: 0 }}>
                                    <ListItemText sx={{ padding: 0 }}>
                                        {
                                            // amount                                            
                                            // amount_status                                            
                                            // closedigits                                            
                                            // digits                                           
                                            // game_name                                           
                                            // pana                                            
                                            // session                                           
                                            // transaction_note                                           
                                            // transaction_type                                            
                                            // tx_request_number                                           
                                            // wining_date   
                                            //bidhistory===============   
                                            // bid_date
                                            // bid_tx_id
                                            // closedigits
                                            // digits
                                            // game_name
                                            // pana
                                            // points
                                            // session                                   
                                            type === 'winreport' ?
                                                <Grid container sx={{ padding: 0 }}>
                                                    <Grid lg={8} xs={7} sx={{ textTransform: 'capitalize' }}>
                                                        <Box display={'flex'} flexDirection={'column'}>
                                                        <Typography sx={{ fontSize: '0.8rem' }}><b>{`${get(item,'game_name','')}`}</b> {`(${get(item,'pana','')})`}</Typography>
                                                            <Typography sx={{ fontSize: '0.8rem' }}><b> {`${get(item,'session','NA')}:`}</b>{`${item['session'].toLowerCase() === 'open' ? get(item,'digits','NA') : get(item,'closedigits','NA')}`} <b>Amount : </b> {`${get(item,'amount','NA')}`}</Typography>
                                                            {/* <Typography sx={{ fontSize: '0.8rem' }}>{`Amount : ${item['amount']}`}</Typography> */}
                                                        </Box>

                                                        {/* {Object.keys(colWidth).filter((filteritem: any) => {
                                                            if (page === 'starline') {
                                                                return filteritem != 'session' && filteritem != 'amount_status' && filteritem != 'transaction_type' && filteritem != 'tx_request_number'
                                                            } else {
                                                                return filteritem != 'amount_status' && filteritem != 'transaction_type' && filteritem != 'tx_request_number'
                                                            }
                                                        }).map((key: any) =>
                                                            item[key] && <Grid sx={{ textTransform: 'capitalize', display: 'block', width: colWidth[key] }}><Typography>{(key.replace('_', ' '))} : {item[key]}</Typography></Grid>
                                                        )} */}

                                                        {/* <Typography>{item.game_name}</Typography>

                                                        <Typography>{item.pana}</Typography>
                                                        <Typography>{item.session}</Typography>
                                                        <Typography>{item.bid_date}</Typography>
                                                        <Typography>{item.bid_tx_id}</Typography> */}
                                                    </Grid>
                                                    <Grid lg={4} xs={5}>
                                                        <Box display={'flex'} flexDirection={'column'}>
                                                        <Typography sx={{ fontSize: '0.8rem' }}><b>Req# : </b>{`${get(item,'tx_request_number','NA')}`}</Typography>                                                            
                                                            {/* <Typography sx={{ fontSize: '0.8rem' }}> {`Type : ${item['transaction_note']}`}</Typography> */}
                                                            <Typography sx={{ fontSize: '0.8rem' }}><b>Date# : </b>{`${get(item,'wining_date','NA')}`}</Typography>

                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12}><Typography sx={{ fontSize: '0.8rem' }}><b>Note : </b> {`${get(item,'transaction_note','')}`}</Typography></Grid>
                                                </Grid>
                                                :
                                                <Grid container>
                                                    <Grid lg={8} xs={7} sx={{ textTransform: 'capitalize' }}>
                                                        <Box display={'flex'} flexDirection={'column'}>
                                                            <Typography sx={{ fontSize: '0.8rem' }}><b>{`${get(item,'game_name','')}`}</b> {`(${get(item,'pana','')})`}</Typography>
                                                            <Typography sx={{ fontSize: '0.8rem' }}><b> {`${get(item,'session','')}:`}</b> {`${item['session'].toLowerCase() === 'open' ? get(item,'digits','') : get(item,'closedigits','')}`}</Typography>
                                                        </Box>

                                                        {/* {Object.keys(item).filter((filteritem: any) => {
                                                            if (page === 'starline') {
                                                                return filteritem != 'session' && filteritem != 'amount_status' && filteritem != 'transaction_type' && filteritem != 'tx_request_number'
                                                            } else {
                                                                return filteritem
                                                            }
                                                        }).map((key: any) =>
                                                            item[key] && <Typography>{(key.replace('_', ' '))} : {item[key]}</Typography>
                                                        )} */}
                                                    </Grid>
                                                    <Grid lg={4} xs={5}>
                                                        <Box display={'flex'} flexDirection={'column'}>
                                                            <Typography sx={{ fontSize: '0.8rem' }}><b>Points</b>{` : ${get(item,'points','')}`}</Typography>
                                                            <Typography sx={{ fontSize: '0.8rem' }}><b>Bid#</b>{` : ${get(item,'bid_tx_id','')}`}</Typography>
                                                            <Typography sx={{ fontSize: '0.8rem' }}> <b>Date</b>{` : ${get(item,'bid_date','')}`}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                        }

                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                        </>
                    ))}
                </List> : <>Date Not Available</>} </Card> </Grid>)
}

export default ReportList;

// { "bid_data": [{ "game_name": "Time Bazar", "pana": "Single Digit", "session": "Close", "digits": "1", "closedigits": "", "points": "5", "bid_tx_id": "8541058", "bid_date": "20 July 24 01:13" }], "msg": "Bid History Data Available", "status": true }
