import { Box, Card, CircularProgress, Container, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { homePageActions } from "../../store/reducer/home";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const WalletStatement = ({ walletData, isLoginLoading }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession);
    const [localWalletData, setLocalWalletData] = useState<any>({ transaction_history: [], in_history: [], out_history: [] })
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (Object.keys(finalData).length) {
            const data: any = {
                "app_key": "@34Y@#8deepmatka",
                "user_id": finalData.user_id
            }
            dispatch(authActions.getWalletTransactionReq(data))
        }
        const pay: any = {
            menuname: 'passbook'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    useEffect(() => {
        if (Object.keys(walletData).length) {
            setLocalWalletData(walletData)
        }

    }, [walletData])
    return (
        <Container>
            {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :

                <Card sx={{ padding: '5px 0px 5px 0px', border: 'none', boxShadow: '0px 0px 2px #adadad27' }}>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="All" {...a11yProps(0)} />
                            <Tab label="In" {...a11yProps(1)} />
                            <Tab label="Out" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <List sx={{ width: '100%', maxHeight: '500px', overflowY: 'scroll', overflowX: 'none' }}>
                            {localWalletData.transaction_history.map((item: any) => (
                                <>
                                    <ListItem disablePadding sx={{ padding: 0 }}>
                                        <ListItemButton component="a" sx={{ padding: 0 }}>
                                            <ListItemText sx={{ padding: 0 }}>
                                                <Grid container>
                                                    <Grid lg={10} md={10} sm={10} xs={10} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.transaction_note}</Typography>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.insert_date}</Typography>

                                                    </Grid>
                                                    <Grid lg={2} md={2} sm={2} xs={2} display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'flex-end'}>
                                                        <Typography>{(item.transaction_type == 2) ? '-' : ''} {item.amount}</Typography>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.tx_request_number}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>

                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <List sx={{ width: '100%', maxHeight: '400px', overflowY: 'scroll', overflowX: 'none' }}>
                            {localWalletData.in_history.map((item: any) => (
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton component="a" sx={{ padding: 0 }}>
                                            <ListItemText sx={{ padding: 0 }}>
                                                <Grid container>
                                                <Grid lg={10} md={10} sm={10} xs={10} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.transaction_note}</Typography>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.insert_date}</Typography>

                                                    </Grid>
                                                    <Grid lg={2} md={2} sm={2} xs={2} display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'flex-end'}>
                                                        <Typography>{(item.transaction_type == 2) ? '-' : ''} {item.amount}</Typography>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.tx_request_number}</Typography></Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <List sx={{ width: '100%', maxHeight: '400px', overflowY: 'scroll', overflowX: 'none' }}>
                            {localWalletData.out_history.map((item: any) => (
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton component="a" sx={{ padding: 0 }}>
                                            <ListItemText sx={{ padding: 0 }}>
                                                <Grid container>
                                                <Grid lg={10} md={10} sm={10} xs={10} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.transaction_note}</Typography>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.insert_date}</Typography>

                                                    </Grid>
                                                    <Grid lg={2} md={2} sm={2} xs={2} display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'flex-end'}>
                                                        <Typography>{(item.transaction_type == 2) ? '-' : ''} {item.amount}</Typography>
                                                        <Typography sx={{ fontSize: '0.8rem' }}>{item.tx_request_number}</Typography></Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>
                    </CustomTabPanel>

                </Card>}

        </Container>
    )
}


const mapStateToProps = (state: any) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    isLoginLoading: state.authentication.isLoginLoading,
    walletData: state.authentication.walletData,
})


export default connect(mapStateToProps)(WalletStatement);