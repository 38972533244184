import { Alert, Box, Button, Card, CircularProgress, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import { homePageActions } from "../../store/reducer/home";

const defRegister :any = {
    app_key: "@34Y@#8deepmatka",
    name: '',
    mobile: '',
    password : '',        
}

const Register = ({ isRegisterSuccess, isPageLoading, pageMessage, isRegisterFailed, isAuthenticated, userData }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName:'' })
    const [registerData, setRegisterData] = useState<any>(defRegister)

    useEffect(()=>{
        const pay: any = {
            menuname: 'starline'
        }
        dispatch(homePageActions.selectedMenu(pay))
    },[])

    const handleOnChange = (event: any) => {
        setRegisterData((prev: any) => {
            return { ...prev, [event.target.name]: event.target.value }
        })
    }
    const handleLogin = (event: any) => {
        event.stopPropagation();
        if (registerData.name.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid name", severity: 'warning', inputName:'name' })
        }  else if (registerData.mobile.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter mobile number", severity: 'warning', inputName:'mobile'  })
        } else if (registerData.password.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid password", severity: 'warning', inputName:'password'  })
        } else {
            setErrorMessage({ status: false, message: "", severity: "", inputName:'' })
            dispatch(authActions.checkRegister({...registerData, email:'', security_pin:''}))
        }
    }

   
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName:'' })
    };

   useEffect(()=>{
    if(isRegisterSuccess){
        setRegisterData(defRegister)
        setErrorMessage({ status: true, message: pageMessage , severity: "success", inputName:'' })  
        sessionStorage.setItem('isAuthenticated', isAuthenticated);
        sessionStorage.setItem('userData', JSON.stringify(userData));
        const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": userData.user_id }
        dispatch(authActions.getUserDataReq(data))
        dispatch(authActions.getWalletBalanceReq(data))
        navigate('/')      
    }

    if(isRegisterFailed){
        setErrorMessage({ status: true, message: pageMessage , severity: "success", inputName:'' })
        setRegisterData(defRegister)
    }
    // return(()=>{
    //     setErrorMessage({ status: false, message: "", severity: "", inputName:'' })
    //     dispatch(authActions.resetLoginState())
    // })
   },[isRegisterSuccess, isRegisterFailed, isAuthenticated])

    return (
        <Grid container>
            <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
            <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              
                    <Card sx={{ display: 'flex',boxShadow: '0px 0px 2px #adadad27', border:0, flexDirection: 'column',alignItems:"center", justifyContent: 'center',  gap: 2, padding: '40px 40px 40px 40px' }}>
                        {Object.keys(defRegister).map((item:any,index:number)=>(
                            item!='app_key' && <TextField sx={{width:"100%", '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }}} label={(item==='security_pin')  ? item.replace('_',' ').toUpperCase() : item.toUpperCase()} name={item} variant="outlined" defaultValue="" type={(item==='password' || item==='security_pin') ? 'password' : 'text'} error={(errorMessage.inputName===item) ? errorMessage.status ? errorMessage.message : '' : '' } helperText={(errorMessage.inputName===item) ? errorMessage.status ? errorMessage.message : '' : '' } onChange={handleOnChange} />
                        ))}
                        
                        
                        <Button variant="contained" sx={{width:"100%", backgroundColor:'#1c274c', ':hover':{
                            backgroundColor:'#1c274c', 
                        }}} color="primary" onClick={handleLogin}>Register</Button>
                        {isPageLoading && <CircularProgress />}
                        <Typography sx={{display:'flex',width:"100%",color:'#1c274c', flexDirection:"row", alignItems:'center', justifyContent:'center'}}>Have an account? &nbsp;<Typography sx={{fontWeight:'bold', cursor:'pointer', color:'#1c274c'}}  color='primary' onClick={()=>{navigate('/login')}}> Login here </Typography></Typography>
                    </Card>
                
            </Grid>
        </Grid>
    )
}



const mapStateToProps = (state: any) => ({
    isRegisterSuccess: state.authentication.isRegisterSuccess,
    isPageLoading: state.authentication.isPageLoading,
    pageMessage : state.authentication.pageMessage,
    userData: state.authentication.userData,
    isRegisterFailed : state.authentication.isRegisterFailed,
    isAuthenticated : state.authentication.isAuthenticated,    
})


export default connect(mapStateToProps)(Register);
