

import { useEffect, useState } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { Box, Button, Card, CircularProgress, Container, Divider, Grid, IconButton, Typography } from "@mui/material";
import { GraphicEq, PlayArrow } from '@mui/icons-material';
import { connect, useDispatch } from 'react-redux';
import { authActions } from '../../store/reducer/auth-reducer';
import { homePageActions } from '../../store/reducer/home';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { ReactComponent as DiceIcon } from '../../assets/onedice.svg';
import { ReactComponent as TwoDice } from '../../assets/twodice.svg';

import { ReactComponent as SinglePan } from '../../assets/singlepanna.svg';
import { ReactComponent as DoublePan } from '../../assets/doublepanna.svg';
import { ReactComponent as TripplePan } from '../../assets/tripplepanna.svg';
import { gameNumbers } from './gameConstants';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const styles: any = {
    iconStyle: {
        height: '32px',
        width: '32px'
    }
}

const incoList: any = {
    'single_digit': { component: <DiceIcon style={styles.iconStyle} />, name: 'Single Digit' },
    'jodi_digit': { component: <TwoDice style={styles.iconStyle} />, name: 'Jodi Digit' },
    'single_pana': { component: <SinglePan style={styles.iconStyle} />, name: 'Single Panna' },
    'double_pana': { component: <DoublePan style={styles.iconStyle} />, name: 'Double Panna' },
    'tripple_pana': { component: <TripplePan style={styles.iconStyle} />, name: 'Tripple Panna' },
    'half_sangam': { component: <div style={{ display: 'flex' }}><DiceIcon style={styles.iconStyle} /><SinglePan style={styles.iconStyle} /></div>, name: 'Half Sangam' },
    'full_sangam': { component: <div style={{ display: 'flex' }}><DiceIcon style={styles.iconStyle} /><TripplePan style={styles.iconStyle} /></div>, name: 'Full Sangam' }
}



const GameRates = ({ gameData, isGameLoading }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { game_id } = useParams();
    const [gameList, setGameList] = useState<any>({ gamedata: [], gamekeys: [] })
    const [gameRates, setGameRates] = useState<any>({})
    const nav = useNavigation()

    useEffect(() => {
        const data: any = {
            app_key: "@34Y@#8deepmatka"
        };
        dispatch(homePageActions.gameDataRequest(data))
        const pay: any = {
            menuname: 'gamerates'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    useEffect(() => {
        if (Object.keys(gameData).length > 0) {
            let temp: any = []
            let temp_data: any = []
            setGameRates(gameData.game_rates)
            gameData.game_rates.forEach((element: any) => {
                let data: any = Object.keys(element);
                let filterdata = data.filter((item: any) => {
                    if (item != 'game_rate_id' && item != 'insert_date') {
                        let searchString = item.split('_')[0] + '_' + item.split('_')[1]
                        if (temp.length) {
                            if (!temp.includes(item.match(searchString)[0])) {
                                temp.push({ [item.match(searchString)[0]]: element[item] })

                            }
                        } else {
                            temp.push({ [item.match(searchString)[0]]: element[item] })

                        }

                        if (temp_data.length) {
                            if (!temp_data.includes(item.match(searchString)[0])) {
                                temp_data.push(item.match(searchString)[0])
                            }
                        } else {
                            temp_data.push(item.match(searchString)[0])
                        }

                    }
                    return item
                });

            });
            setGameList({ gamedata: temp_data, gamekeys: temp })
        }
    }, [gameData])
  
    const gotoPlaceBid = (event: any, item: any) => {
        navigate('/play-game/place-bid/' + game_id + '/' + gameNumbers[item])
    }

    useEffect(() => {
        const ddd: any = gameList.gamekeys.filter((item: any) => Object.keys(item).includes('single_digit'))
        console.log(ddd)
    }, [gameList])

    return (

        <Container>            
            {isGameLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
           
           
                    <Card sx={{padding: '0px', gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',border:'none', boxShadow: '0px 0px 2px #adadad27' }}>
                        <List sx={{width:'100%'}}>
                            {gameList.gamedata.map((item: any) => (
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            <ListItemText> {incoList[item]['name']}
                                                <Box display={'flex'}>{gameList.gamekeys.filter((filteritem: any) => Object.keys(filteritem).includes(item)).map((values: any, index:number) => (
                                                    <div>{values[item]}&nbsp; {(index>0) ? '' : '-'} &nbsp; </div>
                                                ))}</Box> </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </>
                            ))
                            }
                        </List>
                    </Card>
                
            </Grid>}

        </Container >

    )
}

const mapStateToProps = (state: any) => ({
    gameData: state.homepage.gameData,
    isGameLoading: state.homepage.isGameLoading
})


export default connect(mapStateToProps)(GameRates);