import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

const initalState: any = {
    homePageData: {},
    isPageLoading:false,
    isHomeLoading: false,
    isGameLoading: false,
    gameData : {},
    htpData:{},
    contactData:{},
    isCheckGameStatusLoading:false,
    isCheckGameStatusFailed:false,
    isCheckGameStatusSuccess:false,
    gameStatusData: {},
    gameStatusMsg : '',
    isSubmitBidLoading:false,
    isSubmitBidSuccess : false,
    sliderData: [],
    selectedMenuName:''
}

const homeReducer = createSlice({
    name: 'homepage',
    initialState: initalState,
    reducers: {
        dashboardDataRequest: (state) => {
            state.isHomeLoading = true;
            state.isPageLoading = true;
        },
        dashboardDataResponse: (state, action: any) => {
            state.isHomeLoading = false;
            state.isPageLoading = false;
            state.homePageData = action.payload.res;
            console.log('action.payload.res',state.homePageData)
        },
        dashboardDataFailed: (state) => {
            state.isHomeLoading = false;
            state.isPageLoading = false;
            state.homePageData = {}
        },
        sliderDataRequest: (state) => {
            state.isHomeLoading = true;            
        },
        sliderDataRes: (state, action: any) => {
            state.isHomeLoading = false;          
            state.sliderData = get(action.payload.res,'sliderdata',[]);           
        },
        sliderDataFailed: (state) => {
            state.isHomeLoading = false;           
            state.sliderData = []
        },
        gameDataRequest: (state) => {
            state.isGameLoading = true;            
        },
        gameDataResponse: (state, action: any) => {
            state.isGameLoading = false;           
            state.gameData = action.payload.res;           
        },
        gameDataFailed: (state) => {
            state.isGameLoading = false;
            state.gameData = {}
        }, 
        htpDataRequest: (state) => {
            state.isGameLoading = true;            
        },
        htpDataResponse: (state, action: any) => {
            state.isGameLoading = false;           
            state.htpData = action.payload.res;           
        },
        htpDataFailed: (state) => {
            state.isGameLoading = false;
            state.htpData = {}
        },
        contactDataRequest: (state) => {
            state.isGameLoading = true;            
        },
        contactDataResponse: (state, action: any) => {
            state.isGameLoading = false;           
            state.contactData = action.payload.res;           
        },
        contactDataFailed: (state) => {
            state.isGameLoading = false;
            state.contactData = {}
        },
        checkGameStatusReq: (state) => {
            state.isCheckGameStatusLoading = true;            
        },
        checkGameStatusRes: (state, action: any) => {
            state.isCheckGameStatusLoading = false;           
            state.gameStatusData = action.payload?.res?.result[0];   
            state.isCheckGameStatusSuccess = true;                
        },
        checkGameStatusFailed: (state) => {
            state.isCheckGameStatusLoading = false;
            state.gameStatusData = {};
            state.isCheckGameStatusFailed = true;
        },
        submitBidReq: (state) => {
            state.isSubmitBidLoading = true;            
        },
        selectedMenu:(state,action:any)=>{
            state.selectedMenuName = action.payload.menuname
        },
        submitBidRes: (state, action: any) => {
            state.isSubmitBidLoading = false;   
            state.isSubmitBidSuccess = true;
            state.isSubmitBidFailed = false;
            state.gameStatusMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Bid successfull'                              
        },
        submitBidFailed: (state,action:any) => {
            state.isSubmitBidLoading = false;     
            state.isSubmitBidFailed = true; 
            state.gameStatusMsg = (action.payload.res.msg) ? action.payload.res.msg : 'Bid failed'        
        },
        resetLoadingState : (state)=>{
            state.isSubmitBidLoading = false;
            state.isSubmitBidSuccess = false;
            state.isSubmitBidFailed = false; 
            state.gameStatusMsg= '';
        }
    }
})

export const homePageActions = homeReducer.actions;
export default homeReducer;