// api-wining-history-data

import axiosInstance from './axiosClient';

const ReportService = {
  getWinningHistory: async (payload: any) => {
    const url = 'api-wining-history-data';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  getBidHistory: async (payload: any) => {
    const url = 'api-bid-history-data';    
    try {
      const response = await axiosInstance.post(url, payload);  
      if(response.data.status!=true){
        return {
          status: 'failed',
          res: response.data
        }
      }    
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }      
    }
  },
  fetchWalletTransaction: async (payload: any)=>{    
    const url = 'api-wallet-transaction-history';
    console.log(payload)
    try {
      const response = await axiosInstance.post(url, payload);
      //post(url,{})
      console.log('User Data:', response.data);
      return {
        status: 'success',
        res: response.data
      }
    } catch (error) {
      return {
        status: 'failed',
        res: []
      }
      // console.error('An error occurred:', error.message);
      // Here, you might handle errors coming from the backend
    }
  },
 
}

export default ReportService;