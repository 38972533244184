import { Box, Button, Card, CircularProgress, Container, Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import dayjs, { Dayjs } from 'dayjs';
import { authActions } from "../../store/reducer/auth-reducer";
import AlertBar from "../../components/AlertBar";
import { useOutletContext } from "react-router-dom";
import get from 'lodash/get';
import { isEmpty } from "lodash";
import { homePageActions } from "../../store/reducer/home";


const ProfileDetails = ({ userData, isUpdateProfileSuccess, isUpdateProfileFailed, pageMessage, isLoginLoading }: any) => {    
    const outletData: any = useOutletContext();    
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [formData, setFormData] = useState<any>({})
    const handleChange = (e: any) => {
        setFormData((prev: any) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    useEffect(() => {
        if (!isEmpty(outletData)) {
            if (!isEmpty(outletData.userData)) {
                const data: any = get(outletData, 'userData.profile', [])
                console.log(data)
                setFormData((data.length) ? data[0] : {})
            }
        }
        const pay: any = {
            menuname: 'profile'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [outletData])

    const updateProfile = (e: any) => {
        if (formData.user_name.trim().length === 0) {
            setErrorMessage({ status: true, message: "User name required.", severity: 'warning', inputName: '' })
        } else if (formData.mobile.trim().length === 0) {
            setErrorMessage({ status: true, message: "Mobile is required", severity: 'warning', inputName: '' })
        } else {
            const payload: any = {
                "app_key": "@34Y@#8deepmatka",
                user_id: formData.user_id,
                user_name: formData.user_name,
                email: formData.email
            }
            dispatch(authActions.updateProfileReq(payload))
            setErrorMessage({ status: false, message: "", severity: '', inputName: '' })
        }
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    useEffect(() => {
        if (isUpdateProfileSuccess) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'success', inputName: '' })
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": formData.user_id }
            dispatch(authActions.getUserDataReq(data))
        }

        if (isUpdateProfileFailed) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'error', inputName: '' })
        }

        return(()=>{
            dispatch(authActions.resetLoginState())
        })
    }, [isUpdateProfileSuccess, isUpdateProfileFailed, pageMessage])

    return (
        <Container>           
            {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div>  : <Card sx={{ padding: '30px', gap: '2', border:'none', boxShadow: '0px 0px 2px #adadad27' }}>
                <Grid container gap={2} sx={{color:'#1c274c'}}>
                <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                    <Grid  lg={3} sm={12} xs={12} md={3}>
                        <Typography sx={{ fontWeight: '400', color:'#1c274c' }}>Name</Typography>
                        {/* <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{finalData.user_name}</Typography> */}
                        <TextField sx={{width:'100%','.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }}} type="text" name="user_name" value={formData.user_name} variant="outlined" onChange={handleChange} />
                    </Grid>
                    <Grid lg={3} sm={12} xs={12} md={3}>
                        <Typography sx={{ fontWeight: '400' ,color:'#1c274c' }}>Email</Typography>
                        {/* <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{(finalData.email != '') ? finalData.email : 'Email not present.'}</Typography> */}
                        <TextField sx={{width:'100%','.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }}}  type="email" name="email" value={formData.email} variant="outlined" onChange={handleChange} />
                    </Grid>
                    <Grid lg={3} sm={12} xs={12} md={3}>
                        <Typography sx={{ fontWeight: '400',color:'#1c274c'  }}>Mobile</Typography>
                        {/* <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{finalData.mobile}</Typography> */}
                        <TextField sx={{width:'100%', '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }}}  type="text" name="mobile" value={formData.mobile} variant="outlined" disabled={true} />
                    </Grid>
                    <Grid lg={3} sm={12} xs={12} md={3}>
                        <Button variant="contained" sx={{width:"100%",boxShadow: '0px 0px 2px #adadad27', backgroundColor:'#1c274c', ':hover':{backgroundColor:'#1c274c'}}} onClick={updateProfile}>Update Profile</Button>
                    </Grid>
                </Grid>                               
            </Card>}
        </Container>
    )
}


const mapStateToProps = (state: any) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    userData: state.authentication.userData,
    isUpdateProfileSuccess: state.authentication.isUpdateProfileSuccess,
    isUpdateProfileFailed: state.authentication.isUpdateProfileFailed,
    pageMessage: state.authentication.pageMessage,
    isLoginLoading: state.authentication.isLoginLoading,
})


export default connect(mapStateToProps)(ProfileDetails);