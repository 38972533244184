import {call, put, takeLeading} from 'redux-saga/effects'
import { starlineActions } from '../reducer/starline';
import StarlineGames from '../api/starlineapi';


function* reqStarLineGame({payload}:any):any {    
    const res = yield call(StarlineGames.getAllStarlineGame, payload);
    if(res.status==='success'){
        yield put(starlineActions.starLineDataRes(res))
    } else {
        yield put(starlineActions.starLineDataFailed(res))
    }
}

function* reqStarLineGameRates({payload}:any):any {    
    const res = yield call(StarlineGames.getStarLineGameRates, payload);
    if(res.status==='success'){
        yield put(starlineActions.starLineGameRatesRes(res))
    } else {
        yield put(starlineActions.starLineGameRatesFailed(res))
    }
}

function* reqStarLineActiveInactive({payload}:any):any {    
    const res = yield call(StarlineGames.getStarLineGamesActiveInactive, payload);
    if(res.status==='success'){
        yield put(starlineActions.starLineGameStatusActiveInactiveRes(res))
    } else {
        yield put(starlineActions.starLineGameStatusActiveInactiveFailed(res))
    }
}


function* reqWinData({payload}:any):any {    
    const res = yield call(StarlineGames.getStarLineWinningHistoryData, payload);
    if(res.status==='success'){
        yield put(starlineActions.starLineWinningHistoryDataRes(res))
    } else {
        yield put(starlineActions.starLineWinningHistoryDataFailed(res))
    }
}

function* reqBidData({payload}:any):any {    
    const res = yield call(StarlineGames.getStarLineBidHistoryData, payload);
    if(res.status==='success'){
        yield put(starlineActions.starLineBidHistoryDataRes(res))
    } else {
        yield put(starlineActions.starLineBidHistoryDataFailed(res))
    }
}

function* submitBid({payload}:any):any {    
    const res = yield call(StarlineGames.submitStarLineBid, payload);
    if(res.status==='success'){
        yield put(starlineActions.submitStarLineBidRes(res))
    } else {
        yield put(starlineActions.submitStarLineBidFailed(res))
    }
}

function* starlineSaga(){
    yield takeLeading('starline/starLineDataReq', reqStarLineGame);   
    yield takeLeading('starline/starLineGameRatesReq', reqStarLineGameRates);   
    yield takeLeading('starline/starLineGameStatusActiveInactiveReq', reqStarLineActiveInactive); 
    yield takeLeading('starline/starLineWinningHistoryDataReq', reqWinData);   
    yield takeLeading('starline/starLineBidHistoryDataReq', reqBidData); 
    yield takeLeading('starline/submitStarLineBidReq', submitBid);   
}

export default starlineSaga