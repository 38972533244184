import { createSlice } from "@reduxjs/toolkit";

const initalState: any = {
    winningReportData: [],
    isWinReportLoading:false,
    isBidReportLoading: false,
    isGameLoading: false,
    bidData : {},  
    isWinReportFailed:false,
    isBidReportFailed: false,  
}

const reportsReducer = createSlice({
    name: 'reports',
    initialState: initalState,
    reducers: {
        winningHistoryReq: (state) => {
            state.isWinReportLoading = true;
        },
        winningHistoryRes: (state, action: any) => {
            state.isWinReportLoading = false;
            state.winningReportData = action.payload.res;         
        },
        winningHistoryFailed: (state) => {
            state.isWinReportLoading = false;
            state.winningReportData = {}
            state.isWinReportFailed = true;
        },
        bidHistoryReq: (state) => {
            state.isBidReportLoading = true;            
        },
        bidHistoryRes: (state, action: any) => {
            state.isBidReportLoading = false;           
            state.bidData = action.payload.res;           
        },
        bidHistoryFailed: (state) => {
            state.isBidReportLoading = false;
            state.bidData = {}
            state.isBidReportFailed = true;
        },        
    }
})

export const reportsAction = reportsReducer.actions;
export default reportsReducer;