import { Outlet, useNavigate } from "react-router-dom";
import Home from "./home";
import CustomAppBar from "../components/CustomAppBar";
import { Box, CircularProgress, Paper } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { authActions } from "../store/reducer/auth-reducer";
import { isEmpty } from "lodash";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { AccountBoxOutlined, Favorite, Folder, GamepadOutlined, HomeOutlined, ListOutlined, LocationCity, Restore, WalletOutlined } from "@mui/icons-material";
import AlertBar from "../components/AlertBar";
import { homePageActions } from "../store/reducer/home";


const Layout = ({ walletBalance, userData, isLoginLoading, selectedMenuName }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    useEffect(() => {
        if (!isEmpty(finalData)) {
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": finalData.user_id }
            dispatch(authActions.getUserDataReq(data))
            dispatch(authActions.getWalletBalanceReq(data))
        }      
        
    }, [])

    // useEffect(() => {
    //     if (value == '') {
    //         setValue('home');
    //         navigate('/home')
    //     }
    // }, [value])



    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (isEmpty(finalData)) {
            if (newValue === 'home') {
                setValue(newValue);

                const pay: any = {
                    menuname: newValue
                }
                dispatch(homePageActions.selectedMenu(pay))
                navigate('/home')
            } else {
                setErrorMessage({ status: true, message: "Login to get access.", severity: "warning", inputName: '' })
            }
        } else {
            const pay: any = {
                menuname: newValue
            }
            dispatch(homePageActions.selectedMenu(pay))

            if (newValue === 'home') {
                setValue(newValue);
                navigate('/home')
            } else if (newValue === 'history') {
                setValue(newValue);
                navigate('/winning-history')
            } else if (newValue === 'mybids') {
                setValue(newValue);
                navigate('/bid-history')
            } else if (newValue === 'passbook') {
                setValue(newValue);
                navigate('/wallet-statement')
            } else if (newValue === 'profile') {
                setValue(newValue);
                navigate('/my-profile')
            }
        }


    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };


    return (
        <div style={{ flex: 1, width: '100%', height: '100vh', backgroundColor: '#f1f5ff !important' }}>
            <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
            <CustomAppBar walletBalance={walletBalance} userData={userData} />
            <Box sx={{ height: 'calc(100vh-100px)', paddingLeft: '0px', paddingRight: '0px', paddingTop: { lg: '120px', md: '120px', sm: '80px', xs: '80px' }, overflow: 'auto', backgroundColor: '#f1f5ff !important' }}>
                <Outlet context={{ walletBalance: walletBalance, userData: userData }} />
            </Box>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#1c274c', display: 'flex', justifyContent: 'center' }} elevation={3}>
                <BottomNavigation sx={{
                    minWidth: 400,
                    '& .Mui-selected': {
                        color: '#ffce85  !important',
                        backgroundColor: '#2e3e73',
                        '.MuiSvgIcon-root': {
                            color: '#ffce85 !important',
                        }
                    },
                    '&.MuiBottomNavigation-root': {
                        backgroundColor: '#1c274c',
                        height: '70px',
                        '.MuiBottomNavigationAction-root': {
                            color: '#f1f5ff',

                        }
                    }

                }} value={selectedMenuName} onChange={handleChange} showLabels>
                    <BottomNavigationAction
                        label="Home"
                        value="home"
                        icon={<HomeOutlined />}
                    />
                    <BottomNavigationAction
                        label="History"
                        value="history"
                        icon={<ListOutlined />}
                    />
                    <BottomNavigationAction
                        label="My Bids"
                        value="mybids"
                        icon={<GamepadOutlined />}
                    />
                    <BottomNavigationAction
                        label="Passbook"
                        value="passbook"
                        icon={<WalletOutlined />}
                    />
                    <BottomNavigationAction
                        label="Profile"
                        value="profile"
                        icon={<AccountBoxOutlined />}
                    />
                </BottomNavigation>
            </Paper>

        </div>
    )
}
// isPageLoading

const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    userData: state.authentication.userData,
    walletBalance: state.authentication.walletBalance,
    selectedMenuName: state.homepage.selectedMenuName
})


export default connect(mapStateToProps)(Layout);
