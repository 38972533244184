import {call, put, takeLeading} from 'redux-saga/effects'
import { authActions } from '../reducer/auth-reducer';
import AuthService from '../api/auth-api';


function* userLogin({payload}:any):any {    
    const res = yield call(AuthService.login, payload);
    if(res.status==='success'){
        yield put(authActions.checkLoginResponse(res))
    } else {
        yield put(authActions.checkLoginFailed(res))
    }
}

function* userRegister({payload}:any):any {    
    const res = yield call(AuthService.register, payload);
    if(res.status==='success'){
        yield put(authActions.checkRegisterResponse(res))
    } else {
        yield put(authActions.checkRegisterFailed(res))
    }
}

function* fetchUserData({payload}:any):any {    
    const res = yield call(AuthService.fetchUserData, payload);
    if(res.status==='success'){
        yield put(authActions.getUserDataRes(res))
    } else {
        yield put(authActions.getUserDataFailed(res))
    }
}

function* fetchWalletData({payload}:any):any {    
    const res = yield call(AuthService.fetchWalletTransaction, payload);
    if(res.status==='success'){
        yield put(authActions.getWalletTransactionRes(res))
    } else {
        yield put(authActions.getWalletTransactionFailed(res))
    }
}

function* doChangePassword({payload}:any):any {    
    const res = yield call(AuthService.changePassword, payload);
    if(res.status==='success'){
        yield put(authActions.changePasswordRes(res))
    } else {
        yield put(authActions.changePasswordFailed(res))
    }
}

function* updateUPI({payload}:any):any {    
    const res = yield call(AuthService.updateUPIDetails, payload);
    if(res.status==='success'){
        yield put(authActions.updateUPIRes(res))
    } else {
        yield put(authActions.updateUPIFailed(res))
    }
}

function* updateBank({payload}:any):any {    
    const res = yield call(AuthService.updateBankDetails, payload);
    if(res.status==='success'){
        yield put(authActions.updateBankRes(res))
    } else {
        yield put(authActions.updateBankFailed(res))
    }
}


function* getBank({payload}:any):any {    
    const res = yield call(AuthService.getBankDetails, payload);
    if(res.status==='success'){
        yield put(authActions.getBankDetailsRes(res))
    } else {
        yield put(authActions.getBankDetailsFailed(res))
    }
}


function* addPoints({payload}:any):any {    
    const res = yield call(AuthService.addPoints, payload);
    if(res.status==='success'){
        yield put(authActions.addPointsRes(res))
    } else {
        yield put(authActions.addPointsFailed(res))
    }
}


function* getBalance({payload}:any):any {    
    const res = yield call(AuthService.walletBalance, payload);
    if(res.status==='success'){
        yield put(authActions.getWalletBalanceRes(res))
    } else {
        yield put(authActions.getWalletBalanceFailed(res))
    }
}

function* getWDResuest({payload}:any):any {    
    const res = yield call(AuthService.getWithdrawFund, payload);
    if(res.status==='success'){
        yield put(authActions.getWDRes(res))
    } else {
        yield put(authActions.getWDFailed(res))
    }
}

function* withdrawReq({payload}:any):any {    
    const res = yield call(AuthService.requestWithdrawFund, payload);
    if(res.status==='success'){
        yield put(authActions.withdrawRes(res))
    } else {
        yield put(authActions.withdrawFailed(res))
    }
}

function* updateProfileRequest({payload}:any):any {    
    const res = yield call(AuthService.updateProfile, payload);
    if(res.status==='success'){
        yield put(authActions.updateProfileRes(res))
    } else {
        yield put(authActions.updateProfileFailed(res))
    }
}

function* getTransferData({payload}:any):any {    
    const res = yield call(AuthService.checkUserToTransfer, payload);
    if(res.status==='success'){
        yield put(authActions.getTransferDetailsRes(res))
    } else {
        yield put(authActions.getTransferDetailsFailed(res))
    }
}

function* initiateTransfer({payload}:any):any {    
    const res = yield call(AuthService.requestAmountTransfer, payload);
    if(res.status==='success'){
        yield put(authActions.transferDataRes(res))
    } else {
        yield put(authActions.transferDataFailed(res))
    }
}

function* getOrders({payload}:any):any {    
    const res = yield call(AuthService.getOrderList, payload);
    if(res.status==='success'){
        yield put(authActions.getOrderListRes(res))
    } else {
        yield put(authActions.getOrderListFailed(res))
    }
}

function* updateOrderStatus({payload}:any):any {    
    const res = yield call(AuthService.updatePaymentStatus, payload);
    if(res.status==='success'){
        yield put(authActions.checkOrderStatusRes(res))
    } else {
        yield put(authActions.checkOrderStatusFailed(res))
    }
}

function* getadminPGDetails({payload}:any):any {    
    const res = yield call(AuthService.adminPaymentInfo, payload);
    if(res.status==='success'){
        yield put(authActions.adminManualPayInfRes(res))
    } else {
        yield put(authActions.adminManualPayInfFailed(res))
    }
}

function* uploadQR({payload}:any):any {    
    const res = yield call(AuthService.userManualPayReq, payload);
    if(res.status==='success'){
        yield put(authActions.userPaymentRes(res))
    } else {
        yield put(authActions.userPaymentFailed(res))
    }
}

function* approvePayments({payload}:any):any {    
    const res = yield call(AuthService.approvePayments, payload);
    if(res.status==='success'){
        yield put(authActions.adminApprovePointsRes(res))
    } else {
        yield put(authActions.adminApprovePointsFailed(res))
    }
}

function* authSaga(){
    yield takeLeading('authentication/checkLogin', userLogin);
    yield takeLeading('authentication/checkRegister', userRegister);
    yield takeLeading('authentication/getUserDataReq', fetchUserData);
    yield takeLeading('authentication/getWalletTransactionReq', fetchWalletData);
    yield takeLeading('authentication/changePasswordReq', doChangePassword);
    yield takeLeading('authentication/updateUPIReq', updateUPI);
    yield takeLeading('authentication/updateBankReq', updateBank);
    yield takeLeading('authentication/getBankDetailsReq', getBank);
    yield takeLeading('authentication/addPointsReq', addPoints);
    yield takeLeading('authentication/getWalletBalanceReq', getBalance);
    yield takeLeading('authentication/getWDReq', getWDResuest);
    yield takeLeading('authentication/withdrawReq', withdrawReq);
    yield takeLeading('authentication/updateProfileReq', updateProfileRequest);
    yield takeLeading('authentication/getTransferDetailsReq', getTransferData);
    yield takeLeading('authentication/transferDataReq', initiateTransfer);
    yield takeLeading('authentication/getOrderListReq', getOrders);
    yield takeLeading('authentication/checkOrderStatusReq', updateOrderStatus);

    yield takeLeading('authentication/userPaymentReq', uploadQR);
    yield takeLeading('authentication/adminManualPayInfReq',getadminPGDetails );
    yield takeLeading('authentication/adminApprovePointsReq',approvePayments );
    
}

export default authSaga