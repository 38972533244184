import { Alert, Box, Button, Card, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, ImageList, ImageListItem, List, ListItem, ListItemButton, ListItemText, Radio, RadioGroup, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import bankdetails from "../profile/bankdetails";
import GooglePayButton from '@google-pay/button-react';
import axios from 'axios'
import { homePageActions } from "../../store/reducer/home";
import { CloseOutlined, Refresh } from "@mui/icons-material";
import { isEmpty } from "lodash";
import CustomModal from "../../components/CustomModal";

const ApprovePayments = (
    {
        isLoginLoading,
        adminPGSetupSuccesss,
        adminPGSetupFailed,
        adminPaymentData,
        userPaymentUploadSuccess,
        userPaymentUploadFailed,
        adminApprovalSuccess,
        adminApprovalFailed,
        adminApprovalData,
        pageMessage
    }: any
) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [fileString, setFileString] = useState<any>('');
    const [upiId, setUpiId] = useState<any>('');
    const [qrDetails, setQrDetails] = useState<any>([]);
    const [filterqrDetails, setfilterQrDetails] = useState<any>([]);
    const [openModal, setopenModal] = useState<any>({ status: false, imageString: ' ' });

    useEffect(() => {
        const payload: any = {
            user_id: finalData.user_id,
            req_type: 'fetch'
        };
        dispatch(authActions.adminApprovePointsReq(payload))
    }, [])

    useEffect(() => {
        if (adminApprovalData.length) {
            setQrDetails(adminApprovalData)
        } else {
            setQrDetails([]);
        }

    }, [adminApprovalData])

    useEffect(() => {
        setfilterQrDetails(qrDetails)
    }, [qrDetails])

    const approveReq = (item: any, userdata: any, status: any) => {
        const payload: any = {
            user_id: userdata.user_id,
            amount: item.amount,
            txn_id: item.order_id,
            txn_ref: item.order_id,
            req_type: 'approve',
            trans_status: status,
            order_id: item.order_id,
            tid: item.tid
        }
        dispatch(authActions.adminApprovePointsReq(payload))
    }

    useEffect(() => {
        if (adminApprovalSuccess) {
            closePopup();
            setErrorMessage({ status: true, message: "Approved, Points added on user wallet.", severity: "success", inputName: '' })
            const payload: any = {
                user_id: finalData.user_id,
                req_type: 'fetch'
            };
            dispatch(authActions.adminApprovePointsReq(payload))
        }

        if (adminApprovalFailed) {
            closePopup();
            setErrorMessage({ status: true, message: pageMessage, severity: "warning", inputName: '' })
        }
    }, [adminApprovalSuccess,
        adminApprovalFailed,])

    const openModalPopup = (item: any) => {
        setopenModal({ status: true, imageString: item })
    }
    const closePopup = () => {
        setopenModal({ status: false, imageString: ' ' });
    }
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    const handleSearch = (e: any) => {
        let _qrdetails = [...qrDetails];      
        if (e.target.value.length >= 3 ) {
            _qrdetails = qrDetails.filter((item: any) => {
                const _userData = JSON.parse(item.userdata);
                return _userData.user_name.toLowerCase().includes(e.target.value.toLowerCase()) || _userData.mobile.trim().includes(e.target.value.trim())
            })
        } else {
            _qrdetails = [...qrDetails];
        }
        setfilterQrDetails(_qrdetails);
    }

    // const filteredQRDetails = useMemo(()=>{

    // },[])

    return (
        <Container>
            {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                <Card sx={{ display: 'flex', flexDirection: 'column', border: 'none', boxShadow: '0px 0px 2px #adadad27', alignItems: "center", justifyContent: 'center', gap: 2, padding: '10px 10px 10px 10px' }}>
                    <List sx={{ width: '100%', height: 'calc(100vh - 100px)', overflowY: 'scroll', overflowX: 'none' }}>

                        <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginBottom:'5px'}}>
                        <Typography sx={{width:'150px'}}>List of Transactions</Typography>
                        <TextField variant="standard" placeholder="Search" onChange={handleSearch}  />
                        </Box>
                       
                        <Divider />
                        {filterqrDetails.map((item: any, index: number) => (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ padding: 0 }}>
                                        <ListItemText sx={{ padding: 0 }}>
                                            <Grid container>
                                                <Grid xs={7} sx={{ textTransform: 'capitalize' }}>
                                                    {(item.userdata) ? <Typography sx={{ fontSize: '0.8rem' }}>{JSON.parse(item.userdata).user_name} - {JSON.parse(item.userdata).mobile}</Typography>
                                                        : ''}
                                                    <Typography sx={{ fontSize: '0.8rem' }}><b>Amount : </b>{item.amount}</Typography>
                                                    <Typography sx={{ fontSize: '0.8rem' }}><b>Date  : </b>{item.trans_date}</Typography>
                                                    <Typography sx={{ fontSize: '0.8rem' }}><b>Status  : </b>{item.trans_status == '403' ? 'Pending' : item.trans_status == '202' ? 'Rejected' : 'Approved'}</Typography>
                                                    <Button onClick={() => openModalPopup(item)}>Check Image</Button>
                                                </Grid>

                                                <Grid xs={5} sx={{ textTransform: 'capitalize', fontSize: '10', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Box sx={{
                                                        height: '110px', width: '110px', img: {
                                                            height: '100%',
                                                            width: '100%',
                                                            objectFit: 'contain',
                                                            border: '1px solid grey',

                                                        },
                                                    }}>
                                                        <img
                                                            srcSet={`data:image;base64,${item.json_req}`}
                                                            src={`data:image;base64,${item.json_req}`}
                                                            alt={item.tid}
                                                            loading="lazy"
                                                            height={'100%'}
                                                            width={'100%'}
                                                        />
                                                    </Box>

                                                </Grid>

                                            </Grid>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <CustomModal open={openModal.status} customstyle={{ width: '200px', height: 'auto', backgroundColor: '#FFF', padding: '16px' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontSize: '14px', color: '#1c274c', }}>Validate Transaction</Typography>
                                        <CloseOutlined onClick={closePopup} sx={{ cursor: 'pointer', textAlign: 'right', color: '#1c274c', height: '20px', width: '20px' }} />
                                    </Box>
                                    <Box sx={{
                                        height: '200px', width: '100%', img: {
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain',
                                            border: '1px solid grey',

                                        },
                                    }}>
                                        {openModal.imageString.json_req != null && <img src={`data:image;base64,${openModal.imageString.json_req}`} />}
                                    </Box>
                                    <Box>

                                        {(openModal.imageString.trans_status == '403') ?
                                            <>
                                                <Button variant="contained" color={'primary'} disabled={openModal.imageString.trans_status == '00' || openModal.imageString.trans_status == '0'} sx={{ fontSize: '12px' }} onClick={() => approveReq(openModal.imageString, JSON.parse(openModal.imageString.userdata), 200)}>Approve</Button>
                                                <Button variant="contained" color={'warning'} disabled={openModal.imageString.trans_status == '00' || openModal.imageString.trans_status == '0'} sx={{ fontSize: '12px', marginLeft: '10px' }} onClick={() => approveReq(openModal.imageString, JSON.parse(openModal.imageString.userdata), 202)}>Reject</Button>
                                            </> : ''
                                        }
                                    </Box>
                                </CustomModal>
                            </>
                        ))}
                    </List>
                </Card>
            }
        </Container>
    )
}

const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    adminPGSetupSuccesss: state.authentication.adminPGSetupSuccesss,
    adminPGSetupFailed: state.authentication.adminPGSetupFailed,
    adminPaymentData: state.authentication.adminPaymentData,
    userPaymentUploadSuccess: state.authentication.userPaymentUploadSuccess,
    userPaymentUploadFailed: state.authentication.userPaymentUploadFailed,
    adminApprovalSuccess: state.authentication.adminApprovalSuccess,
    adminApprovalFailed: state.authentication.adminApprovalFailed,
    adminApprovalData: state.authentication.adminApprovalData,
    pageMessage: state.authentication.pageMessage,
})


export default connect(mapStateToProps)(ApprovePayments);