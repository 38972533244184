import axiosInstance from './axiosClient';

const StarlineGames = {
    getAllStarlineGame: async (payload: any) => {
        const url = 'api-starline-game';
        try {
            const response = await axiosInstance.post(url, payload);                      
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }         
        }
    },
    getStarLineGameRates: async (payload: any) => {
        const url = 'api-starline-game-rates';
        try {
            const response = await axiosInstance.post(url, payload);           
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }          
        }
    },
    getStarLineGameStatus: async (payload: any) => {
        const url = 'api-check-starline-game-status';
        try {
            const response = await axiosInstance.post(url, payload);            
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }          
        }
    },
    getStarLineGamesActiveInactive: async (payload: any) => {
        const url = 'api-check-starline-games-active-inactive';
        try {
            const response = await axiosInstance.post(url, payload);            
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }           
        }
    },
    submitStarLineBid: async (payload: any) => {
        const url = 'api-starline-submit-bid';
        try {
            const response = await axiosInstance.post(url, payload);           
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }          
        }
    },
    getStarLineBidHistoryData: async (payload: any) => {
        const url = 'api-starline-bid-history-data';
        try {
            const response = await axiosInstance.post(url, payload);          
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }           
        }
    },
    getStarLineWinningHistoryData: async (payload: any) => {
        const url = 'api-starline-wining-history-data';
        console.log('called')
        try {
            const response = await axiosInstance.post(url, payload);          
            return {
                status: 'success',
                res: response.data
            }
        } catch (error) {
            return {
                status: 'failed',
                res : []
            }           
        }
    },
}

export default StarlineGames;