import { useEffect, useState } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { alpha, Box, Button, Card, CircularProgress, Container, darken, Fab, Grid, IconButton, ImageList, ImageListItem, lighten, LinearProgress, Typography } from "@mui/material";
import { AddIcCallOutlined, Close, CloseOutlined, GraphicEq, PlayArrow, StarBorderOutlined } from '@mui/icons-material';
import { connect, useDispatch } from 'react-redux';
import { authActions } from '../../store/reducer/auth-reducer';
import { homePageActions } from '../../store/reducer/home';
import { useNavigate } from 'react-router-dom';
import AlertBar from '../../components/AlertBar';
import { Call, Telegram, WhatsApp } from "@mui/icons-material";
import { ReactComponent as StarGameIcon } from '../../assets/star-fall-svgrepo-com.svg';
import { ReactComponent as PlayIcon } from '../../assets/play-square-svgrepo-com.svg';
import { ReactComponent as TimerIcon } from '../../assets/timer-start-svgrepo-com.svg';
import { ReactComponent as ContactUs } from '../../assets/support-svgrepo-com.svg';
import { ReactComponent as AddFundIcon } from '../../assets/wallet-receive-svgrepo-com.svg';
import { ReactComponent as WithdrawFundIcon } from '../../assets/wallet-send-svgrepo-com.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-square-svgrepo-com.svg';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from '../../components/CustomModal';

import { ReactComponent as OpenIcon } from '../../assets/check-square-svgrepo-com.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-square-svgrepo-com.svg';
import { isEmpty } from 'lodash';
import DocumentTitle from '../../components/DocumentTitle';
import { useAddToHomescreenPrompt } from '../../components/useAddToHomescreenPrompt';



const Home = ({ homePageData, isHomeLoading, sliderData, isCheckGameStatusLoading, gameStatusData, isCheckGameStatusFailed, isCheckGameStatusSuccess, contactData }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)
    const [pageData, setPageData] = useState<any>([])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [openModal, setOpenModal] = useState<any>(false);

    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false);

    const hide = () => setVisibleState(false);

    useEffect(
        () => {
            if (prompt) {
                setVisibleState(true);
            }
        },
        [prompt]
    );

    useEffect(() => {
        const data: any = {
            app_key: "@34Y@#8deepmatka",
            user_id: 0,
            player_id: 0
        };
        dispatch(homePageActions.dashboardDataRequest(data))
        dispatch(homePageActions.sliderDataRequest(data))
        const datacon: any = { "app_key": "@34Y@#8deepmatka" }
        dispatch(homePageActions.contactDataRequest(datacon))
        document.title = 'Home Page';
        const pay: any = {
            menuname: 'home'
        }
        dispatch(homePageActions.selectedMenu(pay))

    }, [])

    useEffect(() => {
        if (Object.keys(homePageData).length) {
            setPageData(homePageData.result)
        }
    }, [homePageData, isHomeLoading])

    const handleCardClick = (event: any, item: any) => {
        setSelectedItem(item);

        if (item.msg_status === 1) {
            navigate('/play-game/' + item.game_id);
        } else {
            setErrorMessage({ status: true, message: "Game is closed for today.", severity: 'error', inputName: '' })
        }
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    const openPopup = () => {
        setOpenModal(true)
    }


    const closePopup = () => {
        setOpenModal(false)
    }

    const openLoginCheckPages = (type: any) => {
        if (isEmpty(finalData)) {
            setErrorMessage({ status: true, message: "Login required.", severity: "warning", inputName: '' })
        } else {
            if (type === 'addpoints') {
                navigate('/add-point')
            } else {
                navigate('/withdraw-points')
            }
        }

    }

    // const colorList: any = {
    //     1: '#ffce85', 2: '#fb9090', 3: '#dcdcf9', 4: '#69b9f9', 5: '#61cdc3', 6: '#ffdacc', 7: '#fbbfa8', 8: '#f9b69c', 9: '#d9efff', 10: '#cae7fb', 11: '#b9e2fd', 12: '#fbe5ff', 13: '#fae0ff', 14: '#f8d5ff', 15: '',
    // }

    const colorList: any = {
        1: '#ffce85', 2: '#fb9090', 3: '#dcdcf9', 4: '#69b9f9', 5: '#61cdc3', 6: '#ffdacc', 7: '#ffdacc', 8: '#ffdacc', 9: '#ffdacc', 10: '#ffdacc', 11: '#ffdacc', 12: '#ffdacc', 13: '#ffdacc', 14: '#ffdacc', 15: '',
    }
    const fabStyle = {
        position: 'absolute',
        bottom: 80,
        right: 27,
        // width: '115px',
        // height: '79px',
        // display: 'flex',
        // flexDirection: 'column',
        // fontSize: '11px',
        // padding: '15px'
      };
      

    return (
        <div>
            <Container sx={{ paddingLeft: '0px !important', paddingRight: '0px !important', marginTop: '0px !important', backgroundColor: '#f1f5ff' }}>
               
                
                <Grid container sx={{
                    position: 'fixed',
                    padding: '0px 0px 0px 30px', display: 'flex', flexDirection: 'column', marginLeft: {
                        // xs:0,
                        // lg:10,
                        // md:10,
                        // xl:10,
                        // sm:8
                    }
                }} gap={'10px'}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '15px' }}>
                        <IconButton
                            onClick={() => navigate('/starline')}
                            sx={{
                                borderRadius: '22px', height: '110px', width: '150px', backgroundColor: colorList[1],
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: '0px 0px 2px #adadad27'
                            }}>
                            <StarGameIcon style={{ height: '50px', width: '50px' }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '100px', paddingLeft: '10px' }}>
                                <Typography sx={{ color: '#1c274c', fontFamily: 'cursive', fontSize: '1.2rem', fontWeight: '800', lineHeight: 1 }}>Starline</Typography>
                                <Typography sx={{ fontFamily: 'cursive', color: '#4d62a5', fontSize: '1.2rem' }}>Games</Typography>
                            </Box>
                        </IconButton>
                        <IconButton sx={{
                            borderRadius: '22px', height: '110px', width: '90px', backgroundColor: colorList[3],
                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 2px #adadad27'
                        }} onClick={() => openLoginCheckPages('addpoints')}>
                            <AddFundIcon style={{ height: '24px', width: '24px' }} />
                            <Typography sx={{ marginTop: '5px', fontFamily: 'calibri', color: '#1c274c', fontSize: '1rem', fontWeight: 800 }}>Add Points</Typography>

                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            // flexDirection: 'column',
                            gap: '10px',
                        }}>



                            <IconButton sx={{
                                borderRadius: '16px', height: '80px', width: '80px', backgroundColor: colorList[2],
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 2px #adadad27'
                            }} onClick={openPopup}>
                                <ContactUs style={{ height: '24px', width: '24px' }} />
                                <Typography sx={{ marginTop: '5px', fontFamily: 'calibri', color: '#1c274c', fontSize: '1rem', fontWeight: 800 }}>Support</Typography>

                            </IconButton>
                            {/* </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}> */}
                            <IconButton sx={{
                                borderRadius: '16px', height: '80px', width: '80px', backgroundColor: colorList[4],
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 2px #adadad27'
                            }} onClick={() => navigate('/how-to-play')}>
                                <InfoIcon style={{ height: '24px', width: '24px' }} />
                                <Typography sx={{ marginTop: '5px', fontFamily: 'calibri', color: '#1c274c', fontSize: '1rem', fontWeight: 800 }}>How?</Typography>

                            </IconButton>
                            <IconButton sx={{
                                borderRadius: '16px', height: '80px', width: '80px', backgroundColor: colorList[5],
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 2px #adadad27'
                            }} onClick={() => openLoginCheckPages('withdraw')}>
                                <WithdrawFundIcon style={{ height: '24px', width: '24px' }} />
                                <Typography sx={{ marginTop: '5px', fontFamily: 'calibri', color: '#1c274c', fontSize: '1rem', fontWeight: 800 }}>Withdraw</Typography>

                            </IconButton>
                        </Box>
                    </Box>
                </Grid>



                {/* <ImageList
                    sx={{ width: '100%', height: 'auto' }}
                    variant="quilted"

                >
                    {sliderData.map((item: any) => (
                        <ImageListItem key={item.image_id} cols={item.cols || 12} rows={item.rows || 12}>
                            <img
                                src={item.slider_image}
                                alt={item.image_id}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList> */}
                {/* </Container> */}
                {isHomeLoading ? <div style={{ width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                    <Container sx={{ paddingLeft: '0px !important', paddingRight: '0px !important', paddingTop: '0px !important', position: 'absolute', marginTop: '200px', height: 'calc(100vh - 400px)', overflowY: 'scroll' }} >
                        <Grid container sx={{
                            display: 'flex', justifyContent: 'flex-start', padding: '24px', marginBottom: {
                                xs: '45px',
                                lg: 0,
                                md: 0,
                                xl: 0,
                                sm: '45px',
                            }
                        }}>
                            {(pageData.length) ? pageData.map((item: any, index: number) => (
                                <Grid xs={12} lg={3} md={4} sm={4} margin={1} >
                                    <Card variant="outlined"
                                        onClick={(event: any) => handleCardClick(event, item)}
                                        sx={{
                                            borderRadius: '22px', boxShadow: '0px 0px 2px #adadad27',
                                            border: '0px solid transparent', padding: '10px', backgroundColor: item.msg_status === 1 ? colorList[index + 6] : colorList[index + 6],

                                        }}>
                                        <Box sx={{ display: 'flex', color: (item.msg_status === 1) ? '#1c274c' : '#a2a3a5', padding: '10px 5px 10px 5px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <IconButton sx={{
                                                borderRadius: '18px', height: '40px', width: '40px', padding: 0,
                                                display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 2px #adadad27'
                                            }}>
                                                {item.msg_status == 1 ? <OpenIcon style={{ color: '#056309', height: '100%', width: '100%' }} /> : <CloseIcon style={{ height: '100%', width: '100%', color: '#d74a4a' }} />}
                                            </IconButton>

                                            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 8px 0px 8px', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ textAlign: 'center', color: 'inherit', fontFamily: 'calibri', fontSize: '1.2rem', fontWeight: '800', lineHeight: 1 }}>
                                                        {item.game_name}
                                                    </Typography>
                                                    <Typography sx={{ textAlign: 'center', color: 'inherit', fontFamily: 'calibri', fontSize: '0.8rem', fontWeight: '800', lineHeight: 1 }}>
                                                        {(item.open_result != '') ? item.open_result : '***-*'} {(item.close_result != '') ? item.close_result : '*-***'}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ color: 'inherit', fontFamily: 'calibri', fontSize: '0.8rem', fontWeight: '400', lineHeight: 1 }}>
                                                        <TimerIcon style={{ height: '12px', width: '12px' }} />  {item.open_time} -  <TimerIcon style={{ height: '12px', width: '12px' }} /> {item.close_time}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <IconButton sx={{
                                                borderRadius: '18px', height: '40px', width: '40px', padding: 0,
                                                display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'center', boxShadow: '0px 0px 2px #adadad27'
                                            }}>
                                                {(item.msg_status === 1) ? <Typography sx={{ fontSize: '8px', color: colorList[index + 6], filter: 'brightness(40%)', marginBottom: '2px' }}>Open</Typography> : <Typography sx={{ fontSize: '9px', color: '#a2a3a5', marginBottom: '2px' }}>Closed</Typography>}
                                                {item.msg_status == 1 ? <PlayIcon style={{ color: colorList[index + 6], filter: 'brightness(40%)', height: '100%', width: '100%' }} /> : <PlayIcon style={{ height: '100%', width: '100%', color: '#a2a3a5' }} />}
                                            </IconButton>

                                            {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box>
                                                <Typography>
                                                    {(item.msg_status === 1) ? 'Open' : 'Closed'}
                                                </Typography>
                                            </Box>

                                            <IconButton disabled={item.msg_status !== 1} onClick={(event: any) => handleCardClick(event, item)}>
                                                <PlayArrow />
                                            </IconButton>
                                        </Box> */}
                                        </Box>
                                    </Card>
                                </Grid>)) : isHomeLoading ? '' : "Data not available"}
                        </Grid>


                    </Container>}

                <CustomModal open={openModal} customstyle={{ width: '200px', backgroundColor: '#FFF', padding: '16px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '14px', color: '#1c274c', }}>Contact us</Typography>
                        <CloseOutlined onClick={closePopup} sx={{ cursor: 'pointer', textAlign: 'right', color: '#1c274c', height: '20px', width: '20px' }} />
                    </Box>

                    <Box sx={{
                        display: 'flex', marginTop: '10px', bottom: 0, justifyContent: 'center', alignItems: 'center', marginBottom: '20px', gap: 2,
                        backgroundColor: '#f1f5ff',
                        padding: '10px',
                        borderRadius: '20px',
                        color: '#1c274c',
                    }}>
                        {/* <a href={`https://api.whatsapp.com/send?phone='${contactData.whatsapp_no}'&text=Contact us`} title="Share on whatsapp">{contactData.whatsapp_no}</a> */}
                        {/* <WhatsApp sx={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone='+contactData.whatsapp_no+'&text=Contact us'} /> */}
                        {/* <Telegram sx={{ cursor: 'pointer' }} onClick={() => window.location.href = contactData.telegram} /> */}
                        {/* <Call sx={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone='+contactData.whatsapp_no+'&text=Contact us'} /> */}
                        <a style={{ textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone='${contactData.whatsapp_no}'&text=Contact us`}><WhatsApp sx={{ cursor: 'pointer' }} /></a>
                        <a href={contactData.whatsapp_no} style={{ textDecoration: 'none' }}><Telegram sx={{ cursor: 'pointer' }} /></a>
                        <a href={contactData.whatsapp_no} style={{ textDecoration: 'none' }}><Call sx={{ cursor: 'pointer' }} /></a>
                    </Box>
                </CustomModal>
                <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                
                {!isVisible ? '': <Fab sx={fabStyle} color="primary" aria-label="add" onClick={promptToInstall}>
                    <AddIcon  />                    
                </Fab>}
            </Container>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    homePageData: state.homepage.homePageData,
    isHomeLoading: state.homepage.isHomeLoading,
    isCheckGameStatusLoading: state.homepage.isCheckGameStatusLoading,
    gameStatusData: state.homepage.gameStatusData,
    isCheckGameStatusFailed: state.homepage.isCheckGameStatusFailed,
    isCheckGameStatusSuccess: state.homepage.isCheckGameStatusSuccess,
    sliderData: state.homepage.sliderData,
    contactData: state.homepage.contactData,
})


export default connect(mapStateToProps)(Home);