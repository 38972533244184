

import { useEffect, useState } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { Box, Button, Card, CircularProgress, Container, Divider, Grid, IconButton, Typography } from "@mui/material";
import { GraphicEq, PlayArrow } from '@mui/icons-material';
import { connect, useDispatch } from 'react-redux';
import { authActions } from '../../store/reducer/auth-reducer';
import { homePageActions } from '../../store/reducer/home';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { ReactComponent as DiceIcon } from '../../assets/onedice.svg';
import { ReactComponent as TwoDice } from '../../assets/twodice.svg';

import { ReactComponent as SinglePan } from '../../assets/singlepanna.svg';
import { ReactComponent as DoublePan } from '../../assets/doublepanna.svg';
import { ReactComponent as TripplePan } from '../../assets/tripplepanna.svg';
import { gameNumbers } from './gameConstants';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const styles: any = {
    iconStyle: {
        height: '32px',
        width: '32px'
    }
}

const incoList: any = {
    'single_digit': { component: <DiceIcon style={styles.iconStyle} />, name: 'Single Digit' },
    'jodi_digit': { component: <TwoDice style={styles.iconStyle} />, name: 'Jodi Digit' },
    'single_pana': { component: <SinglePan style={styles.iconStyle} />, name: 'Single Panna' },
    'double_pana': { component: <DoublePan style={styles.iconStyle} />, name: 'Double Panna' },
    'tripple_pana': { component: <TripplePan style={styles.iconStyle} />, name: 'Tripple Panna' },
    'half_sangam': { component: <div style={{ display: 'flex' }}><DiceIcon style={styles.iconStyle} /><SinglePan style={styles.iconStyle} /></div>, name: 'Half Sangam' },
    'full_sangam': { component: <div style={{ display: 'flex' }}><DiceIcon style={styles.iconStyle} /><TripplePan style={styles.iconStyle} /></div>, name: 'Full Sangam' }
}



const HowToPlay = ({ htpData, isGameLoading }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { game_id } = useParams();   
    const [content, setContent] = useState<any>([])
    useEffect(() => {
        const data: any = {
            app_key: "@34Y@#8deepmatka",            
        };
        dispatch(homePageActions.htpDataRequest(data))
        const pay: any = {
            menuname: 'howtoplay'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    useEffect(()=>{
        if(Object.keys(htpData).length){
            setContent(htpData.content)
        }        
    },[htpData])

    return (

        <Container>
            {isGameLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
            <Grid container gap={2}>
            <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '0px' }}>
                    <Card sx={{padding: '20px', gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                        {content.map((item:any)=>(
                            <>
                              <Typography>How to play video</Typography>
                              <a href={item.video_link} target='_blank'>Click here to see the video.</a>
                            </>
                          
                        ))}
                       
                    </Card>
                </Grid>

            </Grid>}

        </Container >

    )
}

const mapStateToProps = (state: any) => ({
    htpData: state.homepage.htpData,
    isGameLoading: state.homepage.isGameLoading
})


export default connect(mapStateToProps)(HowToPlay);