import {call, put, takeLeading} from 'redux-saga/effects'
import ReportService from '../api/reports';
import { reportsAction } from '../reducer/reports';



function* getWinningHistory({payload}:any):any {    
    const res = yield call(ReportService.getWinningHistory, payload);
    if(res.status==='success'){       
        yield put(reportsAction.winningHistoryRes(res))
    } else {
        yield put(reportsAction.winningHistoryFailed(res))
    }
}

function* getBidHistory({payload}:any):any {    
    const res = yield call(ReportService.getBidHistory, payload);
    if(res.status==='success'){       
        yield put(reportsAction.bidHistoryRes(res))
    } else {
        yield put(reportsAction.bidHistoryFailed(res))
    }
}


function* reportsSaga(){
    yield takeLeading('reports/winningHistoryReq', getWinningHistory);
    yield takeLeading('reports/bidHistoryReq', getBidHistory);
}

export default reportsSaga;