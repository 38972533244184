import { Alert, Box, Button, Card, CircularProgress, Container, Divider, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import { homePageActions } from "../../store/reducer/home";
const defLogin: any = { app_key: "@34Y@#8deepmatka", gpay: '', phonepe: '', paytm: '' }
const defBank: any = {
    "bank_name": "Bank Name",
    "branch_address": "Branch Address",
    "ac_holder_name": "Account Holder Name",
    "ac_number": "Account Number",
    "ifsc_code": "IFSC Code",
}

const BankDetails = ({ isLoginLoading, bankData, isGetBankFailed, pageMessage, isGetBankSuccess, isUpdateBankFailed, isUpdateBankSuccess }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [loginData, setLoginData] = useState<any>(defLogin)
    const [bankDataList, setBankDataList] = useState<any>({
        app_key: "@34Y@#8deepmatka",
        "user_id": "",
        "bank_name": "",
        "branch_address": "",
        "ac_holder_name": "",
        "ac_number": "",
        "ifsc_code": "",
    })
    useEffect(() => {
        if (bankData.length) {
            setBankDataList(bankData[0])
        }
    }, [bankData])

    useEffect(() => {
        const data: any = {
            user_id: finalData.user_id,
            app_key: "@34Y@#8deepmatka",
        }
        dispatch(authActions.getBankDetailsReq(data))
        const pay: any = {
            menuname: 'bankdetails'
        }
        dispatch(homePageActions.selectedMenu(pay))
    }, [])

    console.log(bankData)
    const handleOnChange = (event: any) => {
        setBankDataList((prev: any) => {
            return { ...prev, [event.target.name]: event.target.value }
        })
    }
    const updateUPIDetails = (event: any, reqType: number) => {
        event.stopPropagation();

        if (bankDataList.bank_name.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid bank name", severity: 'warning', inputName: '' })
        } else if (bankDataList.branch_address.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid branch address", severity: 'warning', inputName: '' })
        } else if (bankDataList.ac_holder_name.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid account holder name", severity: 'warning', inputName: '' })
        } else if (bankDataList.ac_number.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid account number", severity: 'warning', inputName: '' })
        } else if (bankDataList.ifsc_code.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid bank IFSC code", severity: 'warning', inputName: '' })
        } else {
            setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
            const payload = { ...bankDataList, user_id: finalData.user_id, app_key: "@34Y@#8deepmatka" }
            dispatch(authActions.updateBankReq(payload))
        }
    }


    useEffect(() => {

        if (isUpdateBankSuccess) {
            const data: any = {
                user_id: finalData.user_id,
                app_key: "@34Y@#8deepmatka",
            }
            dispatch(authActions.getBankDetailsReq(data))
            setErrorMessage({ status: true, message: pageMessage, severity: 'success', inputName: '' })
        }

        if (isGetBankFailed) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }

        if (isUpdateBankFailed) {
            setBankDataList({
                app_key: "@34Y@#8deepmatka",
                "user_id": "",
                "bank_name": "",
                "branch_address": "",
                "ac_holder_name": "",
                "ac_number": "",
                "ifsc_code": "",
            })
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }

        return (() => {
            dispatch(authActions.resetLoginState())
        })
    }, [isUpdateBankFailed, isUpdateBankSuccess, isGetBankFailed])



    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    return (
        <Container>
            {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <AlertBar handleClose={handleClose} errorMessage={errorMessage} />
                    <Card sx={{ display: 'flex', flexDirection: 'column',border:'none', boxShadow: '0px 0px 2px #adadad27', alignItems: "center", justifyContent: 'center', gap: 2, padding: '30px' }}>
                        {Object.keys(defBank).map((item: any) => (
                            <TextField sx={{ width: "100%",'.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }, textTransform: 'capitalize', '.MuiInputLabel-root':{color:'#1c274c'} }} label={defBank[item]} name={item} variant="outlined" value={bankDataList[item]} type="text" onChange={handleOnChange} />
                        ))}
                        <Button variant="contained" sx={{width:"100%",boxShadow: '0px 0px 2px #adadad27', backgroundColor:'#1c274c', ':hover':{backgroundColor:'#1c274c'}}} onClick={(event: any) => updateUPIDetails(event, 1)}>Update Bank Details</Button>
                    </Card>
                </Grid>}
        </Container>
    )
}



const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    isGetBankSuccess: state.authentication.isGetBankSuccess,
    isGetBankFailed: state.authentication.isGetBankFailed,
    pageMessage: state.authentication.pageMessage,
    bankData: state.authentication.bankData,
    isUpdateBankSuccess: state.authentication.isUpdateBankSuccess,
    isUpdateBankFailed: state.authentication.isUpdateBankFailed,

})


export default connect(mapStateToProps)(BankDetails);
