import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Box, Button } from '@mui/material';
import Home from './pages/home';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import RouterConfig from './routes';


function App() {

  return (<RouterProvider router={RouterConfig} />)
}

export default App;
