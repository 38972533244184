

import { Alert, Box, Button, Card, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import bankdetails from "../profile/bankdetails";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import dayjs, { Dayjs } from 'dayjs';
import { homePageActions } from "../../store/reducer/home";
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");

const defLogin: any = { app_key: "@34Y@#8deepmatka", gpay: '', phonepe: '', paytm: '' }
const defaultInputFields = {
    points: { label: 'Add Points', input: 'text', value: '', placeholder: 'Enter your points' },
    amount: { label: 'Add Points', input: 'text', value: [] }
}

const WithdrawPoints = ({ isLoginLoading, bankData, isGetBankFailed, pageMessage, isGetBankSuccess, isAddPopintsSuccess, isAddPopintsFailed, wdData, withdrawSuccess, withdrawFailed, }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)
    const outletData: any = useOutletContext();
    // const newDate = dayjs();
    // var today = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();
    // const todayDate = mm + '/' + dd + '/' + yyyy;
    // console.log(new Date(todayDate + newDate.format('hh:mm A')).getHours())

    dayjs.extend(isSameOrAfter)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [pointsData, setPointsData] = useState<any>({ point: '', payfrom: '' })
    const [bankDataList, setBankDataList] = useState<any>({
        "bank_detail_id": "",
        "user_id": "",
        "bank_name": "",
        "branch_address": "",
        "ac_holder_name": "",
        "ac_number": "",
        "ifsc_code": "",
        "paytm_number": "",
        "google_pay_number": "",
        "phone_pay_number": "",
        "insert_date": ""
    })

    const [withdrawData, setWDData] = useState<any>({
        "withdraw_open_time": "",
        "withdraw_close_time": "",
        "withdrawdata": [],
        "last_request_status": "",
        "msg": "",
        "status": true,
        "wallet_amt": ""
    })


    useEffect(() => {
        const data: any = {
            user_id: finalData.user_id,
            app_key: "@34Y@#8deepmatka",
        }
        dispatch(authActions.getBankDetailsReq(data))

        const pay: any = {
            menuname: 'withdraw'
        }
        dispatch(homePageActions.selectedMenu(pay))

    }, [])

    useEffect(() => {
        if (bankData.length) {
            setBankDataList(bankData[0])
        }

        const data: any = {
            user_id: finalData.user_id,
            app_key: "@34Y@#8deepmatka",
        }
        dispatch(authActions.getWDReq(data))

    }, [bankData])

    useEffect(() => {
        setWDData(wdData)
    }, [wdData])

    const handleOnChange = (event: any) => {
        setPointsData((prev: any) => {
            return { ...prev, [event.target.name]: event.target.value }
        })
    }

    const handleRadioChange = (event: any) => {
        setPointsData((prev: any) => {
            return { ...prev, payfrom: event.target.value }
        })
    }

    function checkTime() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todayDate = mm + '/' + dd + '/' + yyyy;

        const startTimeHours = new Date(todayDate + ' ' + wdData.withdraw_open_time).getHours();
        const startTimeMin = new Date(todayDate + ' ' + wdData.withdraw_open_time).getMinutes()

        const endTimeHours = new Date(todayDate + ' ' + wdData.withdraw_close_time).getHours();
        const endTimeMin = new Date(todayDate + ' ' + wdData.withdraw_close_time).getMinutes()

        var currentD = today;
        var startHappyHourD = new Date();
        startHappyHourD.setHours(startTimeHours, startTimeMin, 0); // 10:00 pm
        var endHappyHourD = new Date();
        endHappyHourD.setHours(endTimeHours, endTimeMin, 0); // 10.00 pm

        if (currentD >= startHappyHourD && currentD < endHappyHourD) {
            return true
        } else {
            return false
        }
    }

    const updatePoints = (event: any) => {
        event.stopPropagation();
        if (checkTime()) {

            const payload: any = {
                app_key: "@34Y@#8deepmatka",
                user_id: finalData.user_id,
                request_amount: pointsData.point,
                payment_method: (pointsData.payfrom === 'gpay') ? 3 : (pointsData.payfrom === 'phonepe') ? 4 : (pointsData.payfrom === 'paytm') ? 2 : 1
            }
            if (pointsData.point.trim().length > 0) {
                if (pointsData.payfrom === '') {
                    setErrorMessage({ status: true, message: "Please select payment method", severity: 'warning', inputName: '' })
                } else {
                    if (parseInt(pointsData.point) >= parseInt(outletData.walletBalance.min_withdrawal)) {
                        if (parseInt(pointsData.point) > parseInt(outletData.walletBalance.max_withdrawal)) {
                            setErrorMessage({ status: true, message: "Please enter valid limit.", severity: 'warning', inputName: 'point' })
                        } else {
                            setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
                            setPointsData({ point: '', payfrom: '' });
                            dispatch(authActions.withdrawReq(payload))
                        }
                    } else {
                        setErrorMessage({ status: true, message: "Please enter valid limit.", severity: 'warning', inputName: 'point' })
                    }

                }

            } else {
                setErrorMessage({ status: true, message: "Please enter valid points.", severity: 'warning', inputName: 'point' })
            }
        } else {
            setErrorMessage({ status: true, message: "Please check withdraw time.", severity: 'warning', inputName: 'point' })
        }


    }


    useEffect(() => {

        if (withdrawSuccess) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'success', inputName: '' })
            const data: any = { "app_key": "@34Y@#8deepmatka", "user_id": finalData.user_id }
            dispatch(authActions.getWalletBalanceReq(data))
            dispatch(authActions.getWDReq(data))
        }

        if (isGetBankFailed) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }

        if (withdrawFailed) {
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }

        return (() => {
            setPointsData({ point: '', payfrom: '' });
            dispatch(authActions.resetLoginState());
        })
    }, [withdrawSuccess, withdrawFailed, isGetBankFailed])



    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };
    console.log(bankDataList, 'bankDataList')
    return (
        <Container>
            <Grid container>
                <AlertBar handleClose={handleClose} errorMessage={errorMessage} />

                <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '0px' }}>
                    <Card sx={{ display: 'flex', border:'none', boxShadow: '0px 0px 2px #adadad27', flexDirection: 'column', alignItems: "center", justifyContent: 'center', gap: 2, padding: '20px 20px 20px 20px' }}>
                        <Box sx={{ width: '100%' }}>
                            <Typography>Withdraw Open Time : {wdData.withdraw_open_time}</Typography>
                            <Typography>Withdraw Close Time : {wdData.withdraw_close_time}</Typography>
                        </Box>



                        <FormControl sx={{ display: 'flex', width: '100%', fontSize:'0.9rem' }}>
                            <FormLabel sx={{ marginBottom: '5px',color:'#1c274c' }}>Points</FormLabel>
                            <TextField sx={{ width: "100%" ,'.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }}} placeholder="Enter points to withdraw" name="point" variant="outlined" value={pointsData.point} type="text"
                                error={(errorMessage.inputName === 'point') ? errorMessage.status ? errorMessage.message : '' : ''} helperText={(errorMessage.inputName === 'point') ? errorMessage.status ? errorMessage.message : '' : ''}
                                onChange={handleOnChange} />
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                {bankDataList.google_pay_number && <FormControlLabel sx={{color:'#1c274c', marginRight:'8px'}} value={'gpay'} control={<Radio checked={pointsData.payfrom === 'gpay'} onChange={handleRadioChange} />} label={'GPay'} />}
                                {bankDataList.phone_pay_number && <FormControlLabel sx={{color:'#1c274c', marginRight:'8px'}}  value={'phonepe'} control={<Radio checked={pointsData.payfrom === 'phonepe'} onChange={handleRadioChange} />} label={'PhonePe'} />}
                                {bankDataList.ac_number && <FormControlLabel sx={{color:'#1c274c', marginRight:'8px'}}  value={'bankaccount'} control={<Radio checked={pointsData.payfrom === 'bankaccount'} onChange={handleRadioChange} />} label={'Bank Account'} />}

                            </RadioGroup>

                        </FormControl>





                        <Button variant="contained" sx={{width:"100%",boxShadow: '0px 0px 2px #adadad27', backgroundColor:'#1c274c', ':hover':{backgroundColor:'#1c274c'}}}  color="primary" onClick={(event: any) => updatePoints(event)}>Submit</Button>
                        {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                            withdrawData.withdrawdata ?
                                <>
                                    <List sx={{ width: '100%',height:'calc(100vh - 500px)', overflowY: 'scroll', overflowX: 'none' }}>
                                        {withdrawData.withdrawdata.map((item: any, index: number) => (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton sx={{ padding: 0 }}>
                                                        <ListItemText sx={{ padding: 0 }}>
                                                            <Grid container>
                                                                <Grid lg={10} sx={{ textTransform: 'capitalize' }}>
                                                                    <Typography sx={{ fontSize: '0.8rem' }}><b>Request Amount : </b>{item.request_amount}</Typography>
                                                                    <Typography sx={{ fontSize: '0.8rem' }}><b>Request Number  : </b>{item.request_number}</Typography>
                                                                    <Typography sx={{ fontSize: '0.8rem' }}><b>Transferred On </b>{item.payment_method === "3" ? 'Google Pay : ' + item.google_pay_number : item.payment_method === "4" ? 'Phone Pe : ' + item.phone_pay_number : 'Bank Account : ' + item.ac_number}</Typography>
                                                                    {item.request_status == '2' ? <Typography sx={{ fontSize: '0.8rem' }}><b>Withdraw Success :</b> <a href={item.payment_receipt} target='_blank'><b>Check Reciept</b></a></Typography> : <Typography sx={{ fontSize: '0.8rem' }}><b>Withdraw Pending</b> </Typography>}
                                                                    {item.request_status == '2' && <Typography sx={{ fontSize: '0.8rem' }}><b>Remark  :</b> {item.remark}</Typography>}
                                                                </Grid>

                                                            </Grid>
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        ))}
                                    </List>

                                </> : ''}
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}



const mapStateToProps = (state: any) => ({
    isLoginLoading: state.authentication.isLoginLoading,
    isGetBankSuccess: state.authentication.isGetBankSuccess,
    isGetBankFailed: state.authentication.isGetBankFailed,
    pageMessage: state.authentication.pageMessage,
    bankData: state.authentication.bankData,
    isAddPopintsSuccess: state.authentication.isAddPopintsSuccess,
    isAddPopintsFailed: state.authentication.isAddPopintsFailed,
    wdData: state.authentication.wdData,
    withdrawSuccess: state.authentication.withdrawSuccess,
    withdrawFailed: state.authentication.withdrawFailed,


})


export default connect(mapStateToProps)(WithdrawPoints);
