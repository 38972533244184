import { Alert, Box, Button, Card, CircularProgress, Container, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { authActions } from "../../store/reducer/auth-reducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import AlertBar from "../../components/AlertBar";
import { homePageActions } from "../../store/reducer/home";

const defLogin: any = { app_key: "@34Y@#8deepmatka", old_pass: '', new_pass:'', conf_pass : '' }
const ChangePassword = ({ isLoginLoading, userData, isChangePassFailed, pageMessage, isChangePassSuccess }: any) => {
    const userDataSession: any = sessionStorage.getItem('userData');
    const finalData = JSON.parse(userDataSession)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>({ status: false, message: '', severity: '', inputName: '' })
    const [loginData, setLoginData] = useState<any>(defLogin)

    useEffect(()=>{
        const pay: any = {
            menuname: 'changepassword'
        }
        dispatch(homePageActions.selectedMenu(pay))
    },[])
    const handleOnChange = (event: any) => {
        setLoginData((prev: any) => {
            return { ...prev, [event.target.name]: event.target.value }
        })
    }
    const handleLogin = (event: any) => {
        event.stopPropagation();
        if (loginData.old_pass.trim().length === 0) {
            setErrorMessage({ status: true, message: "Old password is required", severity: 'warning', inputName: 'old_pass' })
        } else if (loginData.new_pass.trim().length === 0) {
            setErrorMessage({ status: true, message: "Please enter valid new password", severity: 'warning', inputName: 'new_pass' })
        } else if (loginData.new_pass.trim() != loginData.conf_pass.trim()) {
            setErrorMessage({ status: true, message: "New Password and confirm password did not match.", severity: 'warning', inputName: '' })
        } else {
            setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
            const payload:any = {app_key : loginData.app_key, old_pass:loginData.old_pass, new_pass:loginData.new_pass, user_id : finalData.user_id}
            dispatch(authActions.changePasswordReq(payload))
        }
    }


    useEffect(() => {
       
        if (isChangePassSuccess) {
            setLoginData(defLogin)
            setErrorMessage({ status: true, message: pageMessage, severity: 'success', inputName: '' })
        }

        if (isChangePassFailed) {
            setLoginData(defLogin)
            setErrorMessage({ status: true, message: pageMessage, severity: 'warning', inputName: '' })
        }
       
    }, [isChangePassSuccess, isChangePassFailed])

    

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage({ status: false, message: "", severity: "", inputName: '' })
    };

    return (
        <Container>
            
            {isLoginLoading ? <div style={{ width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}> <CircularProgress /> </div> :
                <Grid xs={12} lg={12} md={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <AlertBar handleClose={handleClose} errorMessage={errorMessage} />               
                    <Card sx={{border:'none', boxShadow: '0px 0px 2px #adadad27', display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: 'center', gap: 2, padding: '30px' }}>
                    <TextField sx={{ width: "100%" }} label="Old Password" name="old_pass" variant="outlined" value={loginData.old_pass} type="password" error={(errorMessage.inputName === 'old_pass') ? errorMessage.status ? errorMessage.message : '' : ''} helperText={(errorMessage.inputName === 'old_pass') ? errorMessage.status ? errorMessage.message : '' : ''} onChange={handleOnChange} />
                        <TextField sx={{ width: "100%" ,'.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' }}} label="New Password" name="new_pass" variant="outlined" value={loginData.new_pass} type="password" error={(errorMessage.inputName === 'new_pass') ? errorMessage.status ? errorMessage.message : '' : ''} helperText={(errorMessage.inputName === 'new_pass') ? errorMessage.status ? errorMessage.message : '' : ''} onChange={handleOnChange} />
                        <TextField sx={{ width: "100%",'.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: '1px solid #1c274c' } }} label="Confirm Password" name="conf_pass" variant="outlined" value={loginData.conf_pass} type="password" error={(errorMessage.inputName === 'conf_pass') ? errorMessage.status ? errorMessage.message : '' : ''} helperText={(errorMessage.inputName === 'conf_pass') ? errorMessage.status ? errorMessage.message : '' : ''} onChange={handleOnChange} />
                        <Button variant="contained" sx={{ width: "100%" }} color="primary" onClick={handleLogin}>Change Password</Button>                       
                    </Card>                
            </Grid>}
        </Container>
    )
}



const mapStateToProps = (state: any) => ({    
    isLoginLoading: state.authentication.isLoginLoading,
    userData: state.authentication.userData,
    isChangePassSuccess: state.authentication.isChangePassSuccess,
    pageMessage: state.authentication.pageMessage,
    isChangePassFailed: state.authentication.isChangePassFailed,
})


export default connect(mapStateToProps)(ChangePassword);
