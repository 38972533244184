import {combineReducers, configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga/root';
import authReducer from './reducer/auth-reducer';
import homeReducer from './reducer/home';
import reportsReducer from './reducer/reports';
import starlineReducer from './reducer/starline';

const rootReducer = combineReducers({
    authentication : authReducer.reducer,
    homepage:homeReducer.reducer,
    reports: reportsReducer.reducer,
    starline:starlineReducer.reducer
})

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore(({
    reducer : rootReducer,
    middleware:(getDefaultMiddleware)=>getDefaultMiddleware({serializableCheck:false}).concat(sagaMiddleware)
}))

sagaMiddleware.run(rootSaga)